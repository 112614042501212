.vsp-u-gap--bottom-xs {
  margin-bottom: 0.5rem;
}

.vsp-u-gap--bottom {
  margin-bottom: 1rem;
}

.vsp-u-gap--bottom-md {
  margin-bottom: 1.5rem;
}

.vsp-u-gap--bottom-lg {
  margin-bottom: 2rem !important;
}

.vsp-u-gap--bottom-xl {
  margin-bottom: 2.5rem !important;
}

.vsp-u-gap--left {
  margin-left: 1rem;
}

.vsp-u-gap--left-xxl {
  margin-left: 3rem;
}

.vsp-u-gap--right {
  margin-right: 1rem;
}

.vsp-u-gap--right-sm {
  margin-right: 0.5rem;
}

.vsp-u-gap--right-xxl {
  margin-right: 3rem;
}

.vsp-u-gap--bottom-md {
  margin-bottom: 1.5rem !important;
}

.vsp-u-gap--bottom-xxl {
  margin-bottom: 3rem !important;
}

.vsp-u-gap--bottom-xxxl {
  margin-bottom: 8rem;
}

.vsp-u-gap--left-xs {
  margin-left: 0.5rem;
}

.vsp-u-gap--left {
  margin-left: 1rem;
}

.vsp-u-gap--right-lg {
  margin-right: 2rem;
}

.vsp-u-gap--top-sm {
  margin-top: 0.5rem;
}

.vsp-u-gap--top-md {
  margin-top: 1.5rem;
}

.vsp-c-gap--top-lg {
  margin-top: 2rem;
}

.vsp-u-gap--top-xxl {
  margin-top: 3rem;
}

.vsp-u-gap--top-5 {
  margin-top: 5rem;
}

.vsp-u-text-uppercase {
  text-transform: uppercase;
}

.vsp-u-text-normal {
  text-transform: inherit !important;
}

.vsp-u-bg-color--primary {
  background-color: $vsp-color-primary;
}

.vsp-u-bg-color--primary-dark {
  background-color: $vsp-color-primary-dark;
}

.vsp-u-text--center {
  text-align: center;
}

.vsp-u-color--primary {
  color: $vsp-color-primary;
}

.vsp-u-color--secondary {
  color: $vsp-color-red;
}

.vsp-u-color--primary-dark {
  color: $vsp-color-primary-dark;
}

.vsp-u-color--fb {
  color: $vsp-fb-color;
}

.vsp-u-color--in {
  color: $vsp-in-color;
}

.vsp-u-color--tw {
  color: $vsp-tw-color;
}

.vsp-u-overflow--hidden {
  overflow: hidden;
}

.vsp-u-pull--right-flex {
  justify-content: flex-end !important;
}

.vsp-u-justify-center {
  justify-content: center;
}

.vsp-u-justify-space-between {
  justify-content: space-between;
}

.vsp-u-justify-space-evenly {
  justify-content: space-evenly;
}

.vsp-u-align-center {
  align-items: center;
}

.vsp-u-display-flex {
  display: flex;
}

.vsp-u-flex-row {
  flex-direction: row;
}

.vsp-u-flex-column {
  flex-direction: column;
}

.vsp-u-cursor-pointer {
  cursor: pointer;
}

.vsp-u-font-normal {
  font-weight: normal !important;
}

.vsp-u-font-semi-bold {
  font-weight: 600 !important;
}

.vsp-u-display--none {
  display: none !important;
}

.vsp-u-lock-scroll {
  height: 100vh;
  overflow-y: hidden;
}

.vsp-c-contact {
    .vsp-c-hero {
        background-image: url("/img/hero-bg/contact-us.webp");
    }

    .vsp-c-contact__content {
        display: flex;
        justify-content: space-between;

        @media (max-width: $screen-sm - 1) {
            flex-direction: column;
        }

        p {
            font-weight: 600;
            font-size: 1.25rem;
            margin-bottom: 2rem;

            small {
                font-weight: 300;
                font-size: 1rem;
                line-height: 1;
            }
        }
    }

    .vsp-c-contact__text,
    .vsp-c-contact__form {
        width: 45%;

        @media (max-width: $screen-sm - 1) {
            width: 100%;
        }
    }

    .vsp-c-contact__form {
        input,
        textarea {
            width: 100%;
            border: none;
            outline: none;
            background-color: $vsp-color-gray-lighter;
            border-radius: .5rem;
            padding: 1rem;
            color: $vsp-color-primary-dark;
            margin-bottom: 1.5rem;
            font-size: 1rem;
            font-family: $vsp-font-primary;

            &::placeholder {
                color: rgba($vsp-color-primary-dark, .5);
                font-family: $vsp-font-primary;
                font-size: 1rem;
                line-height: 1.4rem;
            }
        }

        .vsp-c-btn {
            margin-top: 1.5rem;
            border: none;
        }
    }
}
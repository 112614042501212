.vsp-c-footer {
    color: $vsp-color-white;
    font-weight: 300;

    .vsp-c-footer__main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 3rem 2.5rem 0;
        background-color: $vsp-color-primary-dark;

        @media (max-width: $screen-sm) {
            flex-direction: column;
            text-align: center;
            align-items: center;
        }

        .vsp-c-footer__col {
            width: 20%;
            padding: 0 0 2.5rem 2rem;
        }

        .vsp-c-footer__col-wide {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            width: 20%;
            margin-bottom: 2rem;
        }

        @media (max-width: $screen-sm) {
            .vsp-c-footer__col {
                width: 100%;
                margin: 0 0 2rem 0;
                padding: 0;
            }

            .vsp-c-footer__col-wide {
                align-items: center;
                margin: 2rem 0;
            }
        }

        h3 {
            margin: 0 0 1.5rem;
            font-weight: 300;
            font-size: 1.6rem;
        }

        a {
            color: $vsp-color-white;
            text-transform: uppercase;
            display: block;

            &[href*="mailto"] {
                text-transform: lowercase;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        a,
        p {
            font-size: .85rem;
            margin-bottom: 1rem;
            line-height: 1.3;
        }

        .vsp-c-social-nav {
            display: flex;

            a {
                margin-bottom: 0;
            }

            .vsp-c-icon {
                fill: $vsp-color-white;
            }
        }
    }

    .vsp-c-footer__copyright {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 2.5rem;
        background-color: darken($vsp-color-primary-dark, 5);
    }
}
.vsp-c-how-it-works {
    .vsp-c-how-it-works__intro {
        text-align: center;

        h1 {
            font-weight: 300;
        }

        .vsp-c-search {
            display: block;
        }

        .input-group {
            width: 50%;

            @media (max-width: $screen-sm - 1) {
                width: 100%;
            }

            input {
                width: 100%;
                -webkit-appearance: caret;
                -moz-appearance: caret;
            }
        }

        .vsp-c-search__results {
            width: 50%;
            margin: 0 auto;
            right: 0;
            left: 0;

            @media (max-width: $screen-sm - 1) {
                width: 100%;
            }
        }
    }

    .vsp-c-how-it-works__list {
        padding: 4rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .vsp-c-how-it-works__item,
    .vsp-c-how-it-works__results,
    .vsp-c-how-it-works__single {
        h2 {
            font-size: 1.5rem;
            text-transform: uppercase;
            margin-bottom: 2rem;
            transition: all .1s ease-out;

            a {
                color: $vsp-color-red;

                &:hover {
                    color: lighten($vsp-color-red, 15);
                }
            }
        }
    }

    .vsp-c-how-it-works__item {
        width: 30%;
        height: 470px;

        @media (max-width: $screen-md) {
            width: 48%;
        }

        @media (max-width: $screen-sm - 1) {
            width: 100%;
            height: auto;
        }

        .vsp-c-how-it-works__img-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;

            img {
                width: 10rem;
                height: auto;
            }
        }

        h2 {
            text-align: center;
            height: 3rem;
        }

        p {
            @media (max-width: $screen-md) {
                text-align: center;
            }
        }
    }

    .vsp-c-how-it-works__results {
        margin-bottom: 2rem;

        h2 {
            color: $vsp-color-red;
        }
    }

    .vsp-c-how-it-works__no-results {
        font-size: 2rem;
        text-align: center;
        color: $vsp-color-gray;
        margin-bottom: 5rem;
    }

    .vsp-c-how-it-works__no-results-image {
        width: 20%;
        min-width: 120px;
        display: block;
        margin: 6rem auto;
    }

    .vsp-c-how-it-works__category-item {
        width: 100%;
        padding: 2rem;
        box-shadow: 0 0 10px 0 rgba($vsp-color-black, .25);
        transition: all .1s ease-out;

        &:not(:last-of-type) {
            margin-bottom: 2rem;
        }

        &:hover {
            box-shadow: 0 0 10px 0 rgba($vsp-color-black, .5);
        }

        h3,
        p {
            margin-bottom: 2rem;
        }

        h3 {
            color: $vsp-color-primary-dark;
            font-size: 1.25rem;
        }

        > div {
            display: flex;
            justify-content: flex-end;

            a {
                font-size: 1.1rem;
                color: $vsp-color-primary;
                font-weight: 600;
                text-transform: uppercase;
                transition: all .1s ease-out;

                &:hover {
                    color: $vsp-color-primary-dark;
                }
            }
        }
    }

    .vsp-c-how-it-works__single-content {
        h1 {
            margin-bottom: 2rem;
            font-size: 2rem;
            color: $vsp-color-base;
        }

        .vsp-c-single__content ol li{
            font-size: 18px;
        }
    }

    .vsp-c-how-it-works__prev-next {
        display: flex;
        justify-content: space-between;
        margin: 5rem 0 2rem 0;
    }

    .vsp-c-how-it-works__previous,
    .vsp-c-how-it-works__next {
        display: flex;
        justify-content: center;
        height: 4rem;

        svg, h3 {
            color: $vsp-color-gray-light;
        }

        svg {
            height: 85%;
            align-self: center;
        }

        h3 {
            text-transform: uppercase;
            font-weight: 200;
            font-size: 1.5rem;
        }

        p {
            font-weight: 600;
            font-size: 1rem;
            color: $vsp-color-primary-dark;
        }

        div {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin: 0 1rem;
        }
    }

    .vsp-c-how-it-works__previous {
        svg {
            transform: rotate(180deg);
        }
    }

    .vsp-c-how-it-works__next {
        div {
            text-align: right;
        }
    }

    .vsp-c-main__container {
        min-height: 61vh;
    }
}
// @import "compass/utilities/sprites"; // Include compass sprite helpers
// @import "compass/css3/background-size"; // Include helper to calc background size

/* @mixin retina-sprite($name, $hover: false, $active: false) {
  @include _retina-sprite($name, $sprites, $sprites2x, $hover, $active);
} */

// The general purpose retina sprite mixin.
  //
  // @include retina-sprite(name, $spritemap1, $spritemap2)
  // @include retina-sprite(name, $spritemap1, $spritemap2[, $dimensions: true, $pad: 0])
  //
  // If `dimensions` is true, then width/height will also be set.
  //
  // if `pad` is non-zero, then that's how much padding the element will have (requires
  // $spacing on the sprite maps). Great for iPhone interfaces to make hit areas bigger.
  //
/* @mixin _retina-sprite($name, $sprites, $sprites2x, $hover, $active, $dimensions: true, $pad: 0) {
  @if $dimensions == true {
    @include sprite-dimensions($sprites, $name);
  }
  background-image: sprite-url($sprites);
  background-position: sprite-position($sprites, $name, -$pad, -$pad);
  background-repeat: no-repeat;

  @if $hover == true {
    $name_hover: $name + _hover;
    &:hover {
      background-position: sprite-position($sprites, $name_hover, -$pad, -$pad);
    }
  }
  @if $active == true {
    $name_active: $name + _active;
    &:active {
      background-position: sprite-position($sprites, $name_active, -$pad, -$pad);
    }
  }

  @if $pad > 0 {
    padding: $pad;
  }

  @media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    & {
      $pos: sprite-position($sprites2x, $name, -$pad * 2, -$pad * 2);
      background-image: sprite-url($sprites2x);
      background-position: nth($pos, 1) / 2 nth($pos, 2) / 2;
      background-size: (ceil(image-width(sprite-path($sprites2x)) / 2) auto);
      // sprite-path() returns the path of the generated sprite sheet, which
      // image-width() calculates the width of. the ceil() is in place in case
      // you have sprites that have an odd-number of pixels in width

      @if $hover == true {
        $name_hover: $name + _hover; // create myButton_hover and assign it
        &:hover{
          $pos: sprite-position($sprites2x, $name_hover, -$pad * 2, -$pad * 2);
          background-position: nth($pos, 1) / 2 nth($pos, 2) / 2;
        }
      }
      @if $active == true {
        $name_active: $name + _active; // create myButton_active and assign it
        &:active{
          $pos: sprite-position($sprites2x, $name_active, -$pad * 2, -$pad * 2);
          background-position: nth($pos, 1) / 2 nth($pos, 2) / 2;
        }
      }
    }
  }
} */

/* $sprites: sprite-map("retina-icons/default/*.png");   // import 1x sprites
$sprites2x: sprite-map("retina-icons/retina/*.png");   // import 2x sprites
 */


/* For styling input placeholders */
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}
@mixin scrollbar($vertical-size, $horizontal-size, $primary, $secondary: lighten($primary, 25%)) {
    ::-webkit-scrollbar:vertical {
        border-radius: $vertical-size/2;
        width:  $vertical-size;
        height: $vertical-size;
    }


    ::-webkit-scrollbar {
        border-radius: $horizontal-size/2;
        width:  $horizontal-size;
        height: $horizontal-size;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: $vertical-size/2;
        background: $primary;
    }

    ::-webkit-scrollbar-track {
        background: $secondary;
    }




  // For Internet Explorer
  body {
    scrollbar-face-color: $primary;
    scrollbar-track-color: $secondary;
  }
}

/* For font clearity on OSX
** http://maximilianhoffmann.com/posts/better-font-rendering-on-osx */

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin ellipsis ($max-width){
    display: inline-block;
    max-width: $max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
}

// mixins for task view

@mixin task_header (){
  font-weight: bold;
  font-size: 16px;
  width: 80%;
}

@mixin task_properties_header() {
  margin-top: 4px;
  text-transform: uppercase;
  font-size: 14px;
}

@mixin comment-box() {
  background: #fff;
  border: 1px solid darken($modal-header-border-color, 10%);
  padding: 10px;
}

@mixin markdown() {
  @include inline-block;
  vertical-align: top;
  padding: 0 2px;
  color: $full-task-activities-bg;
  background: $task-font-color;
  @include box-shadow(1px 1px 2px 0px rgba(50, 50, 50, 0.75));
}

//IE10+ only targeting mixin

@mixin ie10plus() {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}

/* @mixin sprite-dimensions($map, $sprite) {
  height: image-height(sprite-file($map, $sprite));
  width: image-width(sprite-file($map, $sprite));
} */

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

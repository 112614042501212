.vsp-c-scrum-jira__section {
  padding: 6rem 8rem;

  @media(max-width: $screen-md) {
    padding: 6rem 2rem;
  }
}

.vsp-c-scrum-jira__section--dark {
  background-color: $vsp-color-white-dark;
}

.vsp-c-scrum-jira__section-title {
  font-size: 3rem;
  color: $vsp-color-primary;
  font-weight: 300
}

.vsp-c-scrum-jira__section-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media(max-width: $screen-md) {
    flex-direction: column;
  }
}

.vsp-c-scrum-jira__section-container--half {
  width: 40%;

  @media(max-width: $screen-md) {
    width: 100%;
  }
}

.vsp-c-scrum-jira__section-box {
  border: 1px solid $vsp-color-primary;
  padding: 2.5rem 2rem;
  border-radius: .5rem;
}

.vsp-c-scrum-jira-section__list-item  {
  list-style: none;
  display: flex;
  align-items: center;
  color: $vsp-color-primary;

  &:before{
      content: '';
      display: inline-block;
      height: 1.5rem;
      width: 1.5rem;
      background-image: url("/img/logo.png");
      background-repeat: no-repeat;
      background-size: 1.5rem;
      margin-right: .5rem;
   }
}

.vsp-c-scrum-jira-table__header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;

  @media(max-width: $screen-sm + 100) {
    margin-bottom: 1rem;
  }

  @media(max-width: $screen-xs) {
    justify-content: space-around;
  }
}

.vsp-c-scrum-jira-table__header-item {
  width: 30%;
  height: 4rem;
  display: flex;
  justify-content: center;
}

.vsp-c-scrum-jira-table__header-item--jira {
  display: flex;
  align-items: flex-end;
  padding-bottom: 7px;

  @media(max-width: $screen-md + 150) {
    padding-bottom: 11px
  }

  @media(max-width: $screen-sm + 100) {
    padding-bottom: 5px;
  }
}

.vsp-c-scrum-jira-table__header-item-vs,
.vsp-c-scrum-jira-table__header-item-jira {
  background-repeat: no-repeat;
  background-size: contain;
  width: 70%;

  @media(max-width: $screen-sm + 200) {
    width: 60%;
  }
}

.vsp-c-scrum-jira-table__header-item-vs {
  background-image: url("/img/full-logo.png");
}

.vsp-c-scrum-jira-table__header-item-jira {
  background-image: url("/img/jira-logo.png");
  height: 2.2rem;
}

@media(max-width: $screen-sm + 100) {
  .vsp-c-scrum-jira-table__header-item-vs {
    background-image: url("/img/logo.png");
    width: 3rem;
  }

  .vsp-c-scrum-jira-table__header-item-jira {
    background-image: url("/img/jira.png");
    height: 3.2rem;
    width: 2.8rem;
  }

  .vsp-c-scrum-jira-table__header-item-vs,
  .vsp-c-scrum-jira-table__header-item-jira {
    background-position: center;
  }
}

.vsp-c-scrum-jira-table__img {
  width: 15rem;
}

.vsp-c-scrum-jira-table__img--bottom-gap {
  margin-bottom: .65rem;
}

.vsp-c-scrum-jira-table__row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba($vsp-color-black, 0.1);
  margin-bottom: 1rem;

  @media(max-width: $screen-xs) {
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 0;

    .vsp-c-scrum-jira-table__row-item-main,
    .vsp-c-scrum-jira-table__row-item-wrapper {
      width: 100%;
    }

    .vsp-c-scrum-jira-table__row-item-main {
      justify-content: center;
    }
  }
}

.vsp-c-scrum-jira-table__row-item-main,
.vsp-c-scrum-jira-table__row-item-sec,
.vsp-c-scrum-jira-table__row-item-third {
  padding: 1rem 2rem;
  align-items: center;
  display: flex;

  > p {
    @media(max-width: $screen-sm - 200) {
      font-size: 1rem;
    }
  }
}

.vsp-c-scrum-jira-table__row-item-main {
  width: 40%;
  background-color: $vsp-color-primary-dark;
  color: $vsp-color-white;
}

.vsp-c-scrum-jira-table__row-item-wrapper {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vsp-c-scrum-jira-table__row-item-sec,
.vsp-c-scrum-jira-table__row-item-third {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  > p {
      font-weight: bold;
      text-transform: uppercase;
  }
}

.vsp-c-scrum-jira-table__row-item-sec {
  color: $vsp-color-primary-dark;
}

.vsp-c-scrum-jira-table__row-item-third {
  color: $vsp-color-red;
}

.vsp-c-scrum-jira-table__line-wrapper {
  @media(max-width: $screen-xs) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .vsp-c-scrum-jira-table__line {
      height: 2.25rem;
      width: 1px;
      background-color: $vsp-color-primary-dark;
    }
  }
}

.vsp-c-scrum-jira-features {
  .vsp-c-features__item:nth-of-type(odd) {
    flex-direction: row-reverse;
  }

  .vsp-c-features__item:nth-of-type(even) {
    flex-direction: row;
  }

  @media(max-width: $screen-sm) {
    .vsp-c-features__item:nth-of-type(odd),
    .vsp-c-features__item:nth-of-type(even) {
      flex-direction: column-reverse;
    }
  }

  .vsp-c-features__item {
    align-items: center;
  }

  .vsp-c-scrum-jira-features__heading {
    font-size: 2.5rem;
    font-weight: bold;
  }
}

.vsp-c-scrum-jira-features-container {
  padding-top: 8rem !important;
}

.vsp-c-features {
  .vsp-c-scrum-jira-features__item {
    margin-bottom: 8rem !important;
  }
}

.vsp-c-scrum-jira-features__img-calendar {
  width: 28rem;
}

.vsp-c-scrum-jira-features__img-circle {
  width: 22rem;
  margin-left: 3rem;
}

.vsp-c-scrum-jira-features__img-custom {
  width: 19rem;
}

.vsp-c-scrum-jira-features__img-calendar,
.vsp-c-scrum-jira-features__img-custom {
  margin-right: 3rem;
}

@media(max-width: $screen-sm) {
  .vsp-c-scrum-jira-features__img-calendar,
  .vsp-c-scrum-jira-features__img-circle,
  .vsp-c-scrum-jira-features__img-custom {
    margin: 0;
    max-width: 90%;
  }
}

.vsp-c-scrum-jira-conclusion__heading {
  font-size: 3rem;
  color: $vsp-color-primary;
}

.vsp-c-scrum-jira-conclusion__text {
  color: $vsp-color-gray-dark;
}

.vsp-c-scrum-jira-table__paragraph {
  font-size: 1.5rem;
  color: $vsp-color-primary-dark;
  text-align: center;
  font-weight: bold;
}

.vsp-c-scrum-jira-features__paragraph-strong {
    color: $vsp-color-red;
    font-weight: bold;
}
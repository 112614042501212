.vsp-c-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    background-color: $vsp-color-white;
    z-index: 1999;
    padding: 0 1rem;
    box-shadow: 0 0 30px 0 rgba($vsp-color-black, .35);

    .vsp-c-navigation {
        display: flex;
        align-items: center;

        a {
            margin-left: 3rem;

            &:not(.vsp-c-btn) {
                color: $vsp-color-base;
                font-weight: 300;
                padding: 1.8rem 0 1.65rem;
                border-bottom: .2rem solid transparent;

                &.active {
                    border-bottom: .2rem solid $vsp-color-primary;
                }

                &:hover {
                    color: lighten($vsp-color-base, 50);
                }

                @media (max-width: $screen-lg - 1) {
                    padding: 1.2rem 0;
                }
            }

            &.vsp-c-btn {
                @media (max-width: $screen-lg - 1) {
                    margin: .5rem 0 .5rem 1rem;
                }
            }

            &:last-of-type {
                margin-left: 2rem;
            }

            @media (max-width: $screen-xl) {
                margin-left: 1.5rem;

                &:last-of-type {
                    margin-left: 1rem;
                }
            }
        }

        @media (max-width: $screen-lg - 1) {
            font-size: .85rem;
        }

        @media (max-width: $screen-md) {
            position: absolute;
            flex-direction: column;
            align-items: flex-start;
            width: 22rem;
            height: 100vh;
            top: 0;
            left: -22rem;
            background: $vsp-color-white;
            transition: all .2s ease-out;
            font-size: 1.5rem;

            &.active {
                left: 0;
                box-shadow: 0 0 30px 0 rgba($vsp-color-black, .35);
                overflow: auto;
            }
        }
    }

    .vsp-c-navigation__hamburger-btn {
        display: none;

        @media (max-width: $screen-md) {
            padding: 1.5rem 0;
            display: inline-block;
            color: $vsp-color-base;
        }
    }
}
.vsp-c-about {
    .vsp-c-hero {
        background-image: url("/img/hero-bg/about.webp");

        @media (max-width: $screen-md) {
            background-position: center;
        }
    }

    h2 {
        font-weight: 300;
        font-size: 3rem;
        margin-bottom: 1.5rem;
    }

    .vsp-c-about__content-item {
        display: flex;
        flex-direction: row;
        margin-bottom: 5rem;
        align-items: center;

        @media (max-width: $screen-md) {
            justify-content: center;
            flex-direction: column;
        }
    }

    .vsp-c-about__content-item-image {
        padding: 2rem;
        margin: 2rem;

        @media (max-width: $screen-md) {
            width: 60vw;
            padding: 0;
        }
    }

    .vsp-c-about__content-item-text {
        width: 60%;

        @media (max-width: $screen-md) {
            width: 100%;
        }
    }

    .vsp-c-about__content-product-timeline {
        h2 {
            color: $vsp-color-primary;
        }

        img {
            width: 100%;
            padding: 2rem;

            @media (max-width: $screen-md) {
                padding: 0;
            }
        }
    }
}
.vsp-c-features {
  .vsp-c-hero {
    background-image: url("/img/hero-bg/features.webp");
  }

  .vsp-c-features__item {
    display: flex;
    justify-content: space-between;

    @media (max-width: $screen-sm - 1) {
      flex-direction: column-reverse;
    }

    &:not(:last-of-type) {
      margin-bottom: 10rem;
    }

    &:nth-of-type(even) {
      flex-direction: row-reverse;

      @media (max-width: $screen-sm - 1) {
        flex-direction: column-reverse;
      }
    }

    .vsp-c-search__item-body {
      width: 70%;
    }

    @media (max-width: $screen-sm - 1) {
      .vsp-c-search__item-body {
        width: 100%;
      }

      .vsp-c-search__item-media {
        margin: 0 auto 4rem;
      }
    }

    a > h2 {
      text-transform: none;
    }

    h2 {
      font-size: 3rem;
      color: $vsp-color-primary-dark;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 2rem;
    }

    a {
      text-transform: uppercase;
      color: $vsp-color-red;
      transition: all 0.1s ease-out;
      font-size: 1.25rem;
      font-weight: 600;

      &:hover {
        color: darken($vsp-color-red, 15);
      }
    }
  }

  .vsp-c-content-gap {
    &.vsp-c-content-gap--scrollable-hero {

      @media (max-width: $screen-md) {
        padding-top: calc(80px + 9rem);
      }
    }
  }
}

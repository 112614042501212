.vsp-c-alert {
    display: block;
    font-weight: 600 !important;
    font-size: 1rem !important;
    padding: 1rem;
    color: $vsp-color-base;
    margin: 1rem 0;
    border-radius: .5rem;
    box-shadow: 0 0 5px 0 $vsp-color-gray-light;
}

.vsp-c-alert--success {
    border: 2px solid $vsp-color-primary;
}

.vsp-c-alert--danger {
    border: 2px solid $vsp-color-red;
}
.vsp-l-content {
    padding-top: 70px;
    overflow-x: hidden;
    width: 100%;

    @media (max-width: $screen-lg - 1) {
        padding-top: 53px;
    }
}

.vsp-l-container {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}

.vsp-l-container--narrow {
    max-width: 1000px;
}

.vsp-l-container--wide {
    max-width: 1200px;
}

.vsp-l-sidebar-container {
    width: 100%;
    padding: 0;
    display: flex;

    aside {
        width: 320px;
    }

    main {
        width: calc(100% - 320px);
    }

    @media (max-width: $screen-lg - 1) {
        aside {
            width: 300px;
        }

        main {
            width: calc(100% - 245px);
        }
    }

    @media (max-width: $screen-sm - 1) {
        main {
            width: 100%;
        }
    }
}

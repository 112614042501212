* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 14px;
    scroll-behavior: smooth;

    @media (max-width: $screen-md) {
        font-size: 12px;
    }
}

body {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 100%;
    counter-reset: item;

    &.colorbox-active {
        overflow: hidden;
    }
}

::selection,
::-moz-selection {
    color: white;
    background: #5dc1a6;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1 {
    font-size: 60px;
}

h2 {
    font-size: 4.3rem;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.5rem;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

p {
    font-size: 16px;
    margin: 0;
    line-height: 1.5;
    font-weight: 300;
}

a {
    transition: all .1s ease-out;

    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

small {
    font-size: .8rem;
}

object {
    pointer-events: none;
}
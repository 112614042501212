.vsp-c-download {

    @media (max-width: $screen-md) {
        margin: 0 2rem;
    }

    h1, h2, h3 {
        font-weight: 300;
        color: $vsp-color-primary;
    }

    h1, h2 {
        margin-bottom: 1.5rem;
    }

    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .vsp-c-download__content--title {
        font-size: 3rem;
    }

    .vsp-c-download__content,
    .vsp-c-download__platform-card,
    .vsp-c-download__platform-card--selected,
    .vsp-c-download__platform-cards {
        display: flex;
        justify-content: center;
    }

    .vsp-c-download__platform-cards-img {
        height: 100px;
    }

    .vsp-c-download__content,
    .vsp-c-download__platform-card,
    .vsp-c-download__platform-card--selected {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .vsp-c-download__platform-card,
    .vsp-c-download__platform-card--selected {
        border-radius: 2rem;
        transition: all .1s ease-out;
        margin: 4rem;
        padding: 2rem 0;
    }

    .vsp-c-download__content-timetracker-logo {
        margin-bottom: 2rem;
    }

    .vsp-c-download__platform-card {
        cursor: pointer;
        width: 15rem;

        .vsp-c-download__platform-card--chrome {
            width: 8.5rem;
        }

        @media (max-width: $screen-md) {
            width: 100%;
            max-height: 30vh;
            margin: 3rem;
        }

        .vsp-c-btn {
            width: 10rem;
            font-size: .8rem;
        }

        @media (min-width: $screen-md + 1)  {
            &:hover {
                box-shadow: 0 0 20px 0 rgba($vsp-color-black, .4);
            }
        }
    }

    .vsp-c-download__platform-card--selected {
        width: 18rem;

        .vsp-c-btn {
            width: 11rem;
            font-size: 1rem;
        }

        img {
            width: 80%;
            max-height: 20rem;
        }

        @media (max-width: $screen-md) {
            width: 100%;
            max-height: 40vh;
        }
    }

    .vsp-c-download__platform-cards {
        flex-direction: row;

        @media (max-width: $screen-md - 1) {
            flex-direction: column;
            align-items: center;
        }
    }
}

.vsp-c-site-logo {
    .vsp-c-site-logo__img {
        display: block;
    }
}

.vsp-c-price {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;

    p {
        font-size: 3rem;
    }

    .vsp-c-price__period {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        span {
            font-size: 1.1rem;
            text-transform: uppercase;
        }
    }
}

.vsp-c-hero {
    color: $vsp-color-white;
    position: fixed;
    height: 270px;
    top: 70px;
    left: 0;
    right: 0;
    width: 100%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    transition: all .2s ease-out;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $screen-lg - 1) {
        top: 53px;
    }
    @media (min-width: $screen-lg) {
        height: 350px;
    }

    @media (max-width: $screen-md) {
        height: 125px;
        background-size: cover;

        p {
            display: none;
        }
    }
    @media (min-width: $screen-md) {
        padding-top: calc((125px - 3rem) / 2);
    }

    &.vsp-c-hero--scrollable {
        position: static;

        @media (max-width: $screen-md) {
            position: fixed;
        }
    }

    h1 {
        font-size: 3rem;
        line-height: 1;
        margin-bottom: 3rem;

        @media (max-width: $screen-md - 1) {
            margin-bottom: 0;
        }
    }

    p {
        width: 50%;
        font-size: 1.3rem;

        @media (max-width: $screen-md - 1) {
            display: none;
        }
    }

    &.vsp-c-is-scrolled {
        height: 100px;
        padding-top: calc((100px - 3rem) / 2);

        @media (max-width: $screen-md) {
            height: 80px;
            padding-top: calc((80px - 3rem) / 2);
        }

        h1 {
            margin-bottom: 0;
        }

        p {
            display: none;
        }
    }
}

.vsp-c-content-gap {
    padding: calc(300px + 8rem) 1rem 8rem;
    transition: all .2s ease-out;

    @media (max-width: $screen-md) {
        padding: calc(80px + 4rem) 1rem 4rem;
    }

    &.vsp-c-content-gap--scrollable-hero {
        padding-top: 8rem;

        @media (max-width: $screen-md) {
            padding-top: calc(80px + 4rem);
        }
    }

    &.vsp-c-is-scrolled {
        padding-top: calc(100px + 8rem);

        @media (max-width: $screen-md) {
            padding-top: calc(80px + 4rem);
        }
    }
}

.vsp-c-content-gap--lg {
    padding: calc(363px + 8rem) 1rem 8rem;
    transition: all .2s ease-out;

    @media (max-width: $screen-md) {
        padding: calc(143px + 4rem) 1rem 4rem;
    }

    &.vsp-c-is-scrolled {
        padding-top: calc(163px + 8rem);

        @media (max-width: $screen-md) {
            padding-top: calc(143px + 4rem);
        }
    }
}

.vsp-c-search {
    position: relative;
    display: flex;
    justify-content: center;

    @media (max-width: $screen-sm) {
        margin: 1rem 0;
    }

    .input-group {
        position: relative;
        display: inline-block;

        &:before {
            content: url(/img/icons/search.png);
            position: absolute;
            top: 1rem;
            left: 1rem;
            width: 1rem;
            height: 1rem;
        }
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 1.1rem;
        border: 1px solid $vsp-color-gray-lighter;
        outline: none;
        padding: 1rem 2rem 1rem 4rem;
        border-radius: 2rem;
        color: $vsp-color-base;
        background-color: $vsp-color-white;

        &::placeholder {
            color: $vsp-color-gray;
            font-weight: 300;
        }
    }

    .vsp-c-search__results {
        position: absolute;
        top: 3.4rem;
        left: 5%;
        background-color: $vsp-color-white;
        border: 1px solid $vsp-color-gray-lighter;
        width: 90%;
        font-weight: 300;
        list-style-type: none;
        box-shadow: 0 10px 20px 0 rgba($vsp-color-black, .35);

        a {
            display: block;
            padding: 1rem;
            width: 100%;
            border: none;
            color: $vsp-color-base;

            &:hover {
                border: none;
            }
        }

        br {
            display: none;
        }
    }
}

.vsp-c-cookies-popup {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
    padding: 1rem;
    font-size: 1rem;
    z-index: 2147483647;

    @media (max-width: $screen-lg) {
        width: 70%;
    }

    &.hidden {
        display: none;
    }

    div {
        display: flex;
        flex-direction: column;
    }

    img {
        margin-right: 1rem;
        position: relative;
        top: .5rem;
    }

    p {
        color: #fff;
        font-weight: 300;

        a {
            padding: 0;
            color: #5dc1a6;
        }
    }

    a {
        font-size: 1rem;
        text-transform: uppercase;
        padding: 5px 10px;
        align-self: flex-end;
    }
}

.vsp-c-sidebar,
.vsp-c-main {
    overflow-y: auto;
    height: calc(100vh - 70px);

    @media (max-width: $screen-lg - 1) {
        height: calc(100vh - 55px);
    }
}

.vsp-c-sidebar {
    padding: 4rem 0;
    background-color: $vsp-color-sidebar-bg;
    position: relative;

    @media (max-width: $screen-sm - 1) {
        position: fixed;
        left: -300px;
        z-index: 100;
        transition: all .2s ease-out;

        &.active {
            left: 0;
        }
    }

    a {
        display: block;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 1rem;

        &.active,
        &:hover {
            &:not(.vsp-c-sidebar__expand-btn) {
                border-right: 2px solid $vsp-color-primary;
                background-color: rgba($vsp-color-primary, .1);
            }
        }
    }

    .vsp-c-sidebar__item {
        > a {
            padding-left: 1.5rem;
            text-transform: uppercase;
            color: $vsp-color-primary-dark;
            font-weight: 600;
        }
    }

    .vsp-c-sidebar__subitem {
        > a {
            padding-left: 3rem;
            color: $vsp-color-base;
        }
    }

    .vsp-c-search {
        padding: 0 1rem;
        margin-bottom: 1rem;

        .input-group,
        input {
            width: 100%;
        }
    }

    .vsp-c-search__no-results {
        padding: 0 2rem;
        text-align: center;
        font-size: 1.3rem;
        color: $task-font-color;

        @media (max-width: $screen-lg) {
            font-size: 1.2rem;
        }
    }

    .vsp-c-sidebar__expand-btn {
        display: none;

        @media(max-width: $screen-xs) {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: $vsp-color-sidebar-bg;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            color: $vsp-color-base;
            position: fixed;
            left: 0px;
            top: 6rem;
            border: 1px solid rgba($vsp-color-black, .1);
            border-left: none;
            box-shadow: 0 -5px 5px -5px rgba($vsp-color-black, .25),
                        5px 0 5px -5px rgba($vsp-color-black, .25),
                        0 5px 7px -5px rgba($vsp-color-black, .25);
            transition: all .2s ease-out;
        }
    }

    &.active {
        box-shadow: 0 0 10px 0 rgba($vsp-color-black, .25);

        .vsp-c-sidebar__expand-btn {
            @media(max-width: $screen-xs) {
                left: 298px;

                .vsp-c-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.vsp-c-main {
    background-color: $vsp-color-white-dark;

    .vsp-c-main__container {
        padding: 4rem;
    }

    .vsp-c-footer {
        .vsp-c-footer__main {
            @media (max-width: $screen-md) {
                flex-direction: column;
                align-items: center;
            }

            .vsp-c-footer__col,
            .vsp-c-footer__col-wide {
                width: 25%;

                @media (max-width: $screen-md) {
                    width: 100%;
                    text-align: center;
                }
            }

            .vsp-c-footer__col-wide {
                @media (max-width: $screen-md) {
                    align-items: center;
                }
            }
        }
    }
}

.vsp-c-single__content {
    h2, h3, h4 {
        color: $vsp-color-primary-dark;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.25rem;
    }

    ol, ul, p, blockquote {
        margin-bottom: 2rem;
    }

    ol, ul {
        padding-left: 4rem;
        list-style-type: none;

        li {
            margin-bottom: 1rem;
            font-size: 1.1rem;

            &:before {
                margin-right: 1rem;
                color: $vsp-color-primary-dark;
            }
        }
    }

    ul {
        li {
            &:before {
                content: '\25CF';
            }
        }
    }

    ol>li {
        ol>li {
            counter-reset: item;
            &:before {
                content: none;
            }
        }
        counter-increment: item;
        &:before {
            content: counter(item, decimal);
        }
    }

    ol {
        counter-reset: item;
    }

    a {
        color: $vsp-color-primary;
        transition: all .1s ease-out;

        &:hover {
            text-decoration: underline;
        }
    }

    strong, b {
        font-weight: 600;
    }

    i {
        font-style: italic;
    }

    blockquote {
        position: relative;
        padding-left: 5rem;
        font-size: 1.1rem;
        font-style: italic;
        line-height: 1.5;
        font-weight: 300;
        color: $vsp-color-base;

        &:before {
            content: url(/img/icons/quote.svg);
            position: absolute;
            top: .5rem;
            left: 0;
            width: 3rem;
            height: 3rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

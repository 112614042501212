.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;

    li {
        a, span {
            display: block;
            padding: .5rem 1rem;
        }

        &:not(.pagination-arrow) {
            font-size: 1.2rem;

            a {
                color: $vsp-color-primary;
                border-bottom: 2px solid transparent;

                &:hover {
                    background-color: $vsp-color-gray-lighter;
                    border-bottom: 2px solid $vsp-color-primary;
                }
            }
        }

        &.active {
            span {
                color: $vsp-color-primary-dark;
                background-color: $vsp-color-gray-lighter;
                border-bottom: 2px solid $vsp-color-primary;
            }
        }

        &.pagination-arrow {
            a {
                color: $vsp-color-gray;

                &:hover {
                    color: $vsp-color-primary;
                }
            }

            &.disabled {
                span {
                    color: $vsp-color-gray;
                    opacity: .3;
                }
            }

            &.prev {
                .vsp-c-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }
}


.vsp-c-btn {
  display: inline-block;
  border-radius: 3rem;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
  color: $vsp-color-white;
  line-height: 1;
  cursor: pointer;
  outline: none;
}

.vsp-c-btn--primary {
  background-color: $vsp-color-primary;

  &:hover {
    color: $vsp-color-white;
    background-color: darken($vsp-color-primary, 10);
  }
}

.vsp-c-btn--primary-dark {
  background-color: $vsp-color-primary-dark;

  &:hover {
    color: $vsp-color-white;
    background-color: darken($vsp-color-primary-dark, 10);
  }
}

.vsp-c-btn--secondary {
  background-color: $vsp-color-red;

  &:hover {
    color: $vsp-color-white;
    background-color: darken($vsp-color-red, 10);
  }
}

.vsp-c-btn--ghost {
  color: $vsp-color-black;
  background-color: $vsp-color-white;
  border: 1px solid transparent;

  &.vsp-c-btn--secondary {
    border-color: $vsp-color-red;
  }
}

.vsp-c-btn--white {
  background-color: $vsp-color-white;
  color: darken($vsp-color-primary, 25);

  &:hover {
    background-color: darken($vsp-color-white, 5);
    color: darken($vsp-color-primary, 25);
  }
}

.vsp-c-btn--block {
  display: block;
  text-align: center;
}

.vsp-c-btn--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.vsp-c-btn--price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
}

.vsp-c-btn--nowrap {
  white-space: nowrap;
}

.vsp-c-insights {
    background-color: $vsp-color-white-dark;

    .vsp-c-hero {
        background-image: url("/img/hero-bg/insights.webp");
        position: static;

        p {
            width: 80%;
        }
    }

    .vsp-c-content-gap--scrollable-hero {
        padding-top: 4.6rem;

        @media (max-width: $screen-md - 1) and (orientation: portrait) {
            padding-top: calc(70px + 4rem);
        }

        @media (max-width: 800px) and (max-height: $screen-xs) and (orientation: landscape) {
            padding-top: 5.3rem;
        }
    }

    .vsp-c-insights__tabs {
        background-color: $vsp-color-white-dark;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        transition: all .2s ease-out;
        z-index: 1;

        &,
        nav {
            display: flex;
        }

        @media (max-width: $screen-md) {
            top: 54px;
        }

        @media (max-width: $screen-sm) {
            display: block;
        }

        @media (max-height: $screen-sm) and (orientation: landscape) {
            flex-direction: row;
            justify-content: space-between;
        }

        nav {
            justify-content: center;

            a {
                color: $vsp-color-base;
                padding: 1.5rem .35rem;
                margin: 0 1.5rem;
                border-bottom: 2px solid transparent;
                font-weight: 600;
                transition: all .1s ease-out;

                &:hover,
                &.active {
                    border-bottom: 2px solid $vsp-color-primary;
                }

                &.active {
                    color: $vsp-color-primary-dark;
                }

                @media (max-width: $screen-sm) {
                    margin: 0 .5rem;
                    font-size: .9rem;
                }
            }
        }

        &.vsp-c-is-scrolled {
            position: fixed;

            @media (min-width: $screen-lg) {
                top: 70px;
            }

            @media (max-width: $screen-lg - 1) {
                top: 53px;
            }
        }
    }

    .vsp-c-insights__content {
        padding: 5rem 2rem;

        h2 {
            color: $vsp-color-red;
            font-size: 2rem;
            font-weight: 600;
        }

        @media (max-width: $screen-sm) {
            padding-top: 1rem;
        }
    }

    .vsp-c-insights__post-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .vsp-c-insights__post-item {
        width: 48%;
        height: 335px;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all .1s ease-out;

        @media (max-width: $screen-md) {
            width: 100%;
        }

        &:hover {
            box-shadow: 0 0 10px 0 rgba($vsp-color-black, .2);
        }

        &:not(:last-of-type) {
            margin-bottom: 2rem;
        }

        &:nth-of-type(3n + 1) {
            width: 100%;
            height: 415px;

            @media (max-width: $screen-md) {
                height: 335px;
            }
        }

        a {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            color: $vsp-color-white;
            padding: 3rem;
            background-color: rgba($vsp-color-black, .25);
        }

        h3 {
            margin-bottom: 2rem;
            font-weight: 600;
        }

        p {
            line-height: 1;
            word-break: break-word;
        }
    }
}

.vsp-c-single {
    .vsp-c-single__featured-image {
        height: 40vh;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .vsp-c-single__container {
        display: flex;
        justify-content: space-between;
        padding: 6rem 0 8rem;
        position: relative;

        main {
            width: 70%;
        }

        aside {
            width: 25%;

            .vsp-c-single__header {
                padding-bottom: 1.5rem;
                border-bottom: 1px solid $vsp-color-primary;
                margin-bottom: 1.5rem;

                @media (max-width: $screen-xl) {
                    padding-bottom: 1rem;
                    margin-bottom: 1rem;
                }
            }
        }

        @media (max-width: $screen-xl) {
            padding: 3rem 0 4rem;
        }

        @media (max-width: $screen-md - 1) {
            flex-direction: column;

            main,
            aside {
                width: 100%;
            }

            main {
                margin-bottom: 2rem;
            }
        }
    }

    .vsp-c-single__header {
        font-size: 2.5rem;
        font-weight: 700;

        @media (max-width: $screen-xl) {
            font-size: 2rem;
        }
    }

    .vsp-c-single__back {
        color: $vsp-color-primary-dark;
        font-weight: 600;
    }

    .vsp-c-single__meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        border-bottom: 1px solid $vsp-color-primary;

        p {
            color: $vsp-color-primary-dark;
            font-weight: 600;
        }

        nav {
            display: flex;
            align-items: center;
        }
    }

    .vsp-c-single__sidebar {
        @media (min-width: $screen-lg) {
            &.vsp-c-is-scrolled {
                position: fixed;
                right: 0;
                left: 0;
                top: 0;
                margin: 0 auto;
                width: 1200px;

                .vsp-c-single__sidebar-container {
                    position: absolute;
                    right: 1rem;
                    width: 24.5%;
                    top: calc(3rem + 70px);
                }
            }
        }

        @media (min-width: $screen-xl) {
            &.vsp-c-is-scrolled {
                .vsp-c-single__sidebar-container {
                    top: calc(6rem + 70px);
                }
            }
        }
    }

    .vsp-c-single__sidebar-item {
        margin-bottom: 1.5rem;

        @media (max-width: $screen-xl) {
            margin-bottom: 1rem;
        }

        ul {
            li {
                margin-bottom: 1rem;

                @media (max-width: $screen-xl) {
                    margin-bottom: .5rem;
                }

                @media (max-height: $screen-sm) {
                    &:not(:first-of-type):not(:nth-of-type(2)) {
                        display: none;
                    }

                    &:nth-of-type(2) {
                        margin-bottom: 0;
                    }
                }

                @media (max-width: $screen-lg - 1) and (max-height: $screen-sm) {
                    &:not(:first-of-type):not(:nth-of-type(2)) {
                        display: initial;
                    }
                }

                a {
                    color: $vsp-color-primary-dark;
                    font-size: 1.1rem;
                    font-weight: 600;
                    @include ellipsis(100%);
                    transition: all .1s ease-out;

                    &:focus {
                        outline: none;
                    }

                    &:hover {
                        color: $vsp-color-primary;
                    }
                }
            }
        }
    }

    .vsp-c-single__content {
        padding-top: 4rem;
    }
}

.vsp-c-insights__group-previews,
.vsp-c-single {
    .vsp-c-single__preview-card {
        cursor: pointer;
        border-radius: 1rem;
        transition: all .1s ease-out;

        @media (max-width: $screen-md - 1) {
            width: 280px;
        }

        &:hover {
            box-shadow: 0 0 10px 0 rgba($vsp-color-black, .3);
        }

        a {
            cursor: pointer;
            color: $vsp-color-black;
            a:active {
                color: $vsp-color-base;
            }
        }

        .vsp-c-single__preview-card-img-container {
            width: 100%;
            height: 200px;

            @media (max-height: $screen-sm) {
                height: 140px;
            }

            @media (max-width: $screen-lg - 1) and (max-height: $screen-sm) {
                height: 200px;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }
        }

        .vsp-c-single__preview-card-info {
            height: 200px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border: 1px solid $vsp-color-gray-lighter;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            background-color: $vsp-color-white;

            @media (max-height: $screen-sm) {
                height: 140px;
            }

            @media (max-width: $screen-lg - 1) and (max-height: $screen-sm) {
                height: 200px;
            }

            h3 {
                font-size: 1.75rem;
                font-weight: 300;
                overflow: hidden;
                max-height: 5rem;
                position: relative;
                height: 3.6em;
                cursor: pointer;

                &:after {
                    content: "";
                    text-align: right;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 30%;
                    height: 1.2em;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
                }

                a {
                    color: $vsp-color-base;
                }
            }
        }

        .vsp-c-single__preview-card-meta {
            display: flex;
            align-items: center;
            justify-content: space-between;

            a,
            p {
                font-weight: 600;
                font-size: 1rem;
            }

            p {
                cursor: default;
            }

            a,
            p {
                color: $vsp-color-primary-dark;

                &:hover {
                    color: $vsp-color-primary;
                }
            }
        }
    }
}

.vsp-c-insights__group-previews-heading {
    display: block;
    width: fit-content;
}

.vsp-c-insights__group-previews {
    margin-bottom: 5rem;

    @media (max-width: $screen-md) {
        padding: 0 1rem;
    }

    h2 {
        @media (max-width: $screen-sm) {
            text-align: center;
            margin-top: 2rem;
        }
    }

    ul {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        @media (max-width: $screen-xs + 120) {
            flex-direction: column;
            align-items: center;
        }

        @media (max-width: $screen-md - 100){
            justify-content: space-around;

            li:last-child {
                display: none;
            }
        }
    }

    h3 {
        cursor: default;
    }

    .vsp-c-single__preview-card {
        width: 21.5rem;

        @media (max-width: $screen-sm) {
            margin-bottom: 2rem;
            min-width: 45%;
        }
    }
}

.vs-c-blog__text {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 25px;
}

.vs-c-blog__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
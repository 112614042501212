.vsp-c-white-label {
  font-family: $vsp-font-primary;
}

.vsp-c-white-label__section-wrapper {

  section:first-child {
    max-width: 91.5rem;
    margin-right: auto;
    margin-left: auto;
  }
}

.vsp-c-white-label__section-wrapper--hero {
  position: relative;
  padding-top: 4.7rem;
  padding-bottom: 5.6rem;
  background-color: $vsp-color-white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.vsp-c-white-label__section-wrapper--features {
  padding-top: 4rem;
  padding-bottom: 5.7rem;
  background-color: $vsp-color-white-dark;
}

.vsp-c-white-label__section-wrapper--faq {
  padding-top: 4rem;
  padding-bottom: 5.7rem;
  background-color: $vsp-color-white;
}


.vsp-c-white-label__section-wrapper-partner {
  padding-top: 2.8rem;
  padding-bottom: 5.1rem;
  background-color: $vsp-color-white-dark;

  section:first-child {
    max-width: 84rem;
    margin-right: auto;
    margin-left: auto;
  }
}

.vsp-c-white-label__heading {
  color: $green-01;
  font-weight: 700;
  font-size: 2rem;

  @media (min-width: $screen-md) {
    font-size: $font-size-40;
  }
}

.vsp-c-white-label__heading--left {
  text-align: left;
}

.vsp-c-white-label__heading--center {
  text-align: center;
}

.vsp-c-white-label__text {
  font-size: $font-size-16;
  color: $green-02;
  margin-bottom: 1.8rem;
}

.vsp-c-white-label__text--bold {
  font-weight: 700;
}

.vsp-c-white-label__text--center {
  text-align: center;
}

.vsp-c-white-label__button {
  padding: 1.45rem 2.7rem 1.35rem;
  background-color: $red-01;
  color: $vsp-color-white;
  font-size: $font-size-18;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 3rem;
  outline: none;
  transform: scale(1);
  transition: background-color 100ms ease-in;
  cursor: pointer;

  &:hover {
    background-color: $red-02;
  }
}

/* Hero section START ---------------------------------------- */
.vsp-c-white-label-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  @media (min-width: $screen-md) {
    flex-direction: row;
    align-items: initial;
    padding: 0;
  }
}

.vsp-c-white-label-hero__content {
  display: flex;
  flex-direction: column;
  max-width: 40rem;

  @media (min-width: $screen-md) {
    max-width: 34rem;
    margin-top: 1.3rem;
    margin-right: 1.1rem;
    margin-left: 10rem;
    flex: 2 1 auto;
  }

  button:last-child {
    align-self: center;

    @media (min-width: $screen-md) {
      align-self: flex-start;
    }
  }
}

.vsp-c-white-label-hero__text {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.vsp-c-white-label-hero__animation {
  max-width: 42rem;
  max-height: 42rem;
  order: -1;

  @media (min-width: $screen-md) {
    margin-right: 3.4rem;
    margin-bottom: 7.6rem;
    flex: 2 1 auto;
    order: initial;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
/* Hero section END ---------------------------------------- */

/* Features section START ---------------------------------------- */
.vsp-c-white-label-features {
  padding: 0 2rem;

  @media (min-width: $screen-md) {
    padding: 0;
  }
}

.vsp-c-white-label-features__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2.8rem;
  margin-bottom: 4rem;
}

.vsp-c-white-label-features__card {
  display: flex;
  flex-direction: column;
  max-width: 15rem;
  margin: 2.8rem 2.6rem;

  svg, img {
    width: 5.6rem;
    height: 5.6rem;
    margin-bottom: 3.4rem;
    align-self: center;
    color: $red-03;
  }

  h3 {
    height: 4.8rem;
    font-size: $font-size-24;
    line-height: 2.4rem;
    font-weight: 400;
    color: $green-01;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: $vsp-color-black;
  }
}

.vsp-c-white-label-features__subtitle {
  max-width: 57rem;
  margin: 1.7rem auto 7.4rem;
}

.vsp-c-white-label-features__collage {
  max-width: 74.3rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  row-gap: 5.7rem;
  column-gap: 1rem;
  grid-template-areas:
  'image-1'
  'text-1'
  'image-2'
  'text-2'
  'image-3'
  'text-3';

  @media (min-width: $screen-sm) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    'text-1 image-1'
    'image-2 text-2'
    'text-3 image-3';
  }

  article {
    max-width: 36rem;

    @media (min-width: $screen-sm) {
      max-width: 28.6rem;
    }
  }

  img {
    width: 100%;
    max-width: 40rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  article:nth-of-type(1) {
    grid-area: text-1;
  }

  article:nth-of-type(2) {
    grid-area: text-2;
  }

  article:nth-of-type(3) {
    grid-area: text-3;
  }

  img:nth-of-type(1) {
    grid-area: image-1;
  }

  img:nth-of-type(2) {
    grid-area: image-2;
  }

  img:nth-of-type(3) {
    grid-area: image-3;
  }
}
/* Features section END ---------------------------------------- */

/* FAQ section START ---------------------------------------- */
.vsp-c-white-label-faq {
  padding: 0 2rem;

  @media (min-width: $screen-md) {
    padding: 0;
  }
}

.vsp-c-white-label-faq__heading {
  font-size: 2rem;
  color: $green-03;
  text-align: center;
  font-weight: 400;
  margin-bottom: 4.7rem;

  @media (min-width: $screen-md) {
    font-size: $font-size-36;
  }
}

.vsp-c-white-label-faq__list {
  max-width: 70.8rem;
  margin: auto;
}

.vsp-c-white-label-faq__list-item {
  margin-bottom: 2.3rem;

  summary {
    position: relative;
    display: block;
    font-size: $font-size-18;
    font-weight: 700;
    color: $green-03;
    border-bottom: 1px solid rgba($vsp-color-black, 0.1);
    margin-bottom: 1.7rem;
    padding-bottom: 0.5rem;
    padding-right: 1.6rem;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &::after {
      position: absolute;
      top: 9px;
      right: 0;
      content: '';
      border-width: 7px 8px;
      border-style: solid;
      border-color: $green-03 transparent transparent transparent;
    }
  }

  &[open] {
    summary::after {
      border-color: transparent transparent $green-03 transparent;
      top: 2px;
    }
  }

  p {
    font-size: $font-size-16;
    color: rgba($vsp-color-black, 0.9);
  }
}
/* FAQ section END ---------------------------------------- */

/* Partner section START ---------------------------------------- */
.vsp-c-white-label-partner {
  padding: 0 2rem;
}

.vsp-c-white-label-partner__title {
  font-weight: 300;
  font-size: 2rem;
  color: $green-04;
  margin-bottom: 2.8rem;
  text-align: left;

  @media (min-width: $screen-md) {
    font-size: $font-size-48;
  }
}

.vsp-c-white-label-partner__content {
  max-width: 80rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 4.5rem;

  @media (min-width: $screen-md) {
    grid-template-columns: minmax(auto, 46.7rem) 1fr;
    justify-items: stretch;
  }
}

.vsp-c-white-label-partner__animation {
  display: none;
  align-items: flex-end;
  margin-bottom: 4rem;

  @media (min-width: $screen-md) {
    display: flex;
  }

  div:first-child {
    margin-right: 2rem;
  }
}

.vsp-c-white-label-partner__form {
  display: flex;
  flex-direction: column;
  max-width: 45.7rem;
  width: 100%;
  background-color: $vsp-color-white;
  padding: 2.5rem 2.8rem 2rem;
  border-radius: 16px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.vsp-c-white-label-partner__form-body {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.1rem;
  margin-bottom: 2.8rem;

  @media (min-width: $screen-sm) {
    grid-template-columns: 1fr 1fr;
    column-gap: 3.4rem;
  }
}

.vsp-c-white-label-partner__form-title {
  font-weight: 400;
  font-size: 1.8rem;
  color: $green-03;
  margin-bottom: 2.4rem;

  @media (min-width: $screen-sm) {
    font-size: $font-size-32;
  }
}

.vsp-c-white-label-partner__input-group {

  label {
    display: block;
    font-weight: 400;
    font-size: 1rem;
    color: $grey-01;
    margin-bottom: 0.6rem;
  }

  input, select, textarea {
    background-color: $grey-02;
    padding: 0.6rem 0.8rem;
    border: none;
    font-family: $vsp-font-primary;
    font-size: 1rem;
    font-weight: 400;
    color: $green-03;
    border-radius: 4px;
    width: 100%;
  
    &:focus {
      outline: none;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(/img/svg/down-arrow-simple.svg);
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
  }

  &:last-child {

    @media (min-width: $screen-sm) {
      grid-column: 1 / span 2;
    }
  }
}

.vsp-c-white-label-partner__button {
  padding: 0.7rem 3.1rem;
  background-color: $green-04;
  color: $vsp-color-white;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 21px;
  border: none;
  outline: none;
  cursor: pointer;
  align-self: center;
  transition: background-color 100ms ease-in;

  &:hover {
    background-color: $green-03;
  }

  @media (min-width: $screen-sm) {
    align-self: flex-end;
  }
}
/* Partner section END ---------------------------------------- */

/* Confirmation modal section START ---------------------------------------- */
.vsp-c-white-label__modal--disabled {
  display: none !important;
}

.vsp-c-white-label__modal-text {
  font-size: 1.7143rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: $green-01;
  max-width: 31rem;
  text-align: center;
  margin-top: 2rem;
}
/* Confirmation modal section END ---------------------------------------- */


.vsp-c-sitemap {
  .vsp-c-sitemap__container {
    padding: 4rem;

    h1 {
      font-size: 2rem;
      color: $vsp-color-primary-dark;
      margin-bottom: 3rem;
      font-weight: 600;
    }

    div {
      margin-bottom: 3rem;
    }

    .vsp-c-sitemap__section-title {
      color: $vsp-color-primary-dark;
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 1rem;
    }

    .vsp-c-sitemap__feature-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .vsp-c-sitemap__section {
      display: flex;
      flex-direction: column;
      width: 20%;
      margin: 1rem 0;


      p {
        color: $vsp-color-primary;
        margin-bottom: 1rem;
      }

      a {
        color: $vsp-color-base;
        margin-bottom: 0.5rem;

        &,
        &:visited {
          color: $vsp-color-base;
        }
      }
    }
  }
}
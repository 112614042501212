.vsp-c-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba($vsp-color-black, .6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  box-shadow: 0 1px 10px 0 $vsp-color-black;
}

.vsp-c-modal-container {
  max-width: 90vw;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  background-color: $vsp-color-white;
  border-radius: .75rem;
  position: relative;
}

.vsp-c-modal-container-appsumo {
  max-width: 90vw;
  max-height: fit-content;
  position: relative;
  
  .appsumo-poster {
    box-shadow: $shadow-01;
    width: 100%;
  }
}

.vsp-c-modal__section {
  width: 100%;
  height: 100%;
}

.vsp-c-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  margin: .75rem;
  cursor: pointer;
}

.vsp-c-modal__close-img {
  width: 100%;
  height: 100%;
}

.vsp-u-flex-column {
  flex-direction: column;
}

.vsp-c-modal-btn {
  align-self: center;
  border: none;
  font-size: $font-size-base;
}
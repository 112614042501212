/* @mixin retina-sprite($name, $hover: false, $active: false) {
  @include _retina-sprite($name, $sprites, $sprites2x, $hover, $active);
} */
/* @mixin _retina-sprite($name, $sprites, $sprites2x, $hover, $active, $dimensions: true, $pad: 0) {
  @if $dimensions == true {
    @include sprite-dimensions($sprites, $name);
  }
  background-image: sprite-url($sprites);
  background-position: sprite-position($sprites, $name, -$pad, -$pad);
  background-repeat: no-repeat;

  @if $hover == true {
    $name_hover: $name + _hover;
    &:hover {
      background-position: sprite-position($sprites, $name_hover, -$pad, -$pad);
    }
  }
  @if $active == true {
    $name_active: $name + _active;
    &:active {
      background-position: sprite-position($sprites, $name_active, -$pad, -$pad);
    }
  }

  @if $pad > 0 {
    padding: $pad;
  }

  @media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    & {
      $pos: sprite-position($sprites2x, $name, -$pad * 2, -$pad * 2);
      background-image: sprite-url($sprites2x);
      background-position: nth($pos, 1) / 2 nth($pos, 2) / 2;
      background-size: (ceil(image-width(sprite-path($sprites2x)) / 2) auto);
      // sprite-path() returns the path of the generated sprite sheet, which
      // image-width() calculates the width of. the ceil() is in place in case
      // you have sprites that have an odd-number of pixels in width

      @if $hover == true {
        $name_hover: $name + _hover; // create myButton_hover and assign it
        &:hover{
          $pos: sprite-position($sprites2x, $name_hover, -$pad * 2, -$pad * 2);
          background-position: nth($pos, 1) / 2 nth($pos, 2) / 2;
        }
      }
      @if $active == true {
        $name_active: $name + _active; // create myButton_active and assign it
        &:active{
          $pos: sprite-position($sprites2x, $name_active, -$pad * 2, -$pad * 2);
          background-position: nth($pos, 1) / 2 nth($pos, 2) / 2;
        }
      }
    }
  }
} */
/* $sprites: sprite-map("retina-icons/default/*.png");   // import 1x sprites
$sprites2x: sprite-map("retina-icons/retina/*.png");   // import 2x sprites
 */
/* For styling input placeholders */
/* For font clearity on OSX
** http://maximilianhoffmann.com/posts/better-font-rendering-on-osx */
/* @mixin sprite-dimensions($map, $sprite) {
  height: image-height(sprite-file($map, $sprite));
  width: image-width(sprite-file($map, $sprite));
} */
.vsp-c-site-logo .vsp-c-site-logo__img {
  display: block; }

.vsp-c-price {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 300; }
  .vsp-c-price p {
    font-size: 3rem; }
  .vsp-c-price .vsp-c-price__period {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 1rem; }
    .vsp-c-price .vsp-c-price__period span {
      font-size: 1.1rem;
      text-transform: uppercase; }

.vsp-c-hero {
  color: #ffffff;
  position: fixed;
  height: 270px;
  top: 70px;
  left: 0;
  right: 0;
  width: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  transition: all .2s ease-out;
  z-index: 100;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center; }
  @media (max-width: 1279px) {
    .vsp-c-hero {
      top: 53px; } }
  @media (min-width: 1280px) {
    .vsp-c-hero {
      height: 350px; } }
  @media (max-width: 1024px) {
    .vsp-c-hero {
      height: 125px;
      background-size: cover; }
      .vsp-c-hero p {
        display: none; } }
  @media (min-width: 1024px) {
    .vsp-c-hero {
      padding-top: calc((125px - 3rem) / 2); } }
  .vsp-c-hero.vsp-c-hero--scrollable {
    position: static; }
    @media (max-width: 1024px) {
      .vsp-c-hero.vsp-c-hero--scrollable {
        position: fixed; } }
  .vsp-c-hero h1 {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 3rem; }
    @media (max-width: 1023px) {
      .vsp-c-hero h1 {
        margin-bottom: 0; } }
  .vsp-c-hero p {
    width: 50%;
    font-size: 1.3rem; }
    @media (max-width: 1023px) {
      .vsp-c-hero p {
        display: none; } }
  .vsp-c-hero.vsp-c-is-scrolled {
    height: 100px;
    padding-top: calc((100px - 3rem) / 2); }
    @media (max-width: 1024px) {
      .vsp-c-hero.vsp-c-is-scrolled {
        height: 80px;
        padding-top: calc((80px - 3rem) / 2); } }
    .vsp-c-hero.vsp-c-is-scrolled h1 {
      margin-bottom: 0; }
    .vsp-c-hero.vsp-c-is-scrolled p {
      display: none; }

.vsp-c-content-gap {
  padding: calc(300px + 8rem) 1rem 8rem;
  transition: all .2s ease-out; }
  @media (max-width: 1024px) {
    .vsp-c-content-gap {
      padding: calc(80px + 4rem) 1rem 4rem; } }
  .vsp-c-content-gap.vsp-c-content-gap--scrollable-hero {
    padding-top: 8rem; }
    @media (max-width: 1024px) {
      .vsp-c-content-gap.vsp-c-content-gap--scrollable-hero {
        padding-top: calc(80px + 4rem); } }
  .vsp-c-content-gap.vsp-c-is-scrolled {
    padding-top: calc(100px + 8rem); }
    @media (max-width: 1024px) {
      .vsp-c-content-gap.vsp-c-is-scrolled {
        padding-top: calc(80px + 4rem); } }

.vsp-c-content-gap--lg {
  padding: calc(363px + 8rem) 1rem 8rem;
  transition: all .2s ease-out; }
  @media (max-width: 1024px) {
    .vsp-c-content-gap--lg {
      padding: calc(143px + 4rem) 1rem 4rem; } }
  .vsp-c-content-gap--lg.vsp-c-is-scrolled {
    padding-top: calc(163px + 8rem); }
    @media (max-width: 1024px) {
      .vsp-c-content-gap--lg.vsp-c-is-scrolled {
        padding-top: calc(143px + 4rem); } }

.vsp-c-search {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .vsp-c-search {
      margin: 1rem 0; } }
  .vsp-c-search .input-group {
    position: relative;
    display: inline-block; }
    .vsp-c-search .input-group:before {
      content: url(/img/icons/search.png);
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 1rem;
      height: 1rem; }
  .vsp-c-search input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1.1rem;
    border: 1px solid #EEEEEE;
    outline: none;
    padding: 1rem 2rem 1rem 4rem;
    border-radius: 2rem;
    color: #333333;
    background-color: #ffffff; }
    .vsp-c-search input:-ms-input-placeholder {
      color: #b1b3b5;
      font-weight: 300; }
    .vsp-c-search input::placeholder {
      color: #b1b3b5;
      font-weight: 300; }
  .vsp-c-search .vsp-c-search__results {
    position: absolute;
    top: 3.4rem;
    left: 5%;
    background-color: #ffffff;
    border: 1px solid #EEEEEE;
    width: 90%;
    font-weight: 300;
    list-style-type: none;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.35); }
    .vsp-c-search .vsp-c-search__results a {
      display: block;
      padding: 1rem;
      width: 100%;
      border: none;
      color: #333333; }
      .vsp-c-search .vsp-c-search__results a:hover {
        border: none; }
    .vsp-c-search .vsp-c-search__results br {
      display: none; }

.vsp-c-cookies-popup {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 50%;
  padding: 1rem;
  font-size: 1rem;
  z-index: 2147483647; }
  @media (max-width: 1280px) {
    .vsp-c-cookies-popup {
      width: 70%; } }
  .vsp-c-cookies-popup.hidden {
    display: none; }
  .vsp-c-cookies-popup div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column; }
  .vsp-c-cookies-popup img {
    margin-right: 1rem;
    position: relative;
    top: .5rem; }
  .vsp-c-cookies-popup p {
    color: #fff;
    font-weight: 300; }
    .vsp-c-cookies-popup p a {
      padding: 0;
      color: #5dc1a6; }
  .vsp-c-cookies-popup a {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 5px 10px;
    -ms-flex-item-align: end;
    align-self: flex-end; }

.vsp-c-sidebar,
.vsp-c-main {
  overflow-y: auto;
  height: calc(100vh - 70px); }
  @media (max-width: 1279px) {
    .vsp-c-sidebar,
    .vsp-c-main {
      height: calc(100vh - 55px); } }

.vsp-c-sidebar {
  padding: 4rem 0;
  background-color: #F6F6F6;
  position: relative; }
  @media (max-width: 767px) {
    .vsp-c-sidebar {
      position: fixed;
      left: -300px;
      z-index: 100;
      transition: all .2s ease-out; }
      .vsp-c-sidebar.active {
        left: 0; } }
  .vsp-c-sidebar a {
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
    .vsp-c-sidebar a.active:not(.vsp-c-sidebar__expand-btn), .vsp-c-sidebar a:hover:not(.vsp-c-sidebar__expand-btn) {
      border-right: 2px solid #5dc1a6;
      background-color: rgba(93, 193, 166, 0.1); }
  .vsp-c-sidebar .vsp-c-sidebar__item > a {
    padding-left: 1.5rem;
    text-transform: uppercase;
    color: #548674;
    font-weight: 600; }
  .vsp-c-sidebar .vsp-c-sidebar__subitem > a {
    padding-left: 3rem;
    color: #333333; }
  .vsp-c-sidebar .vsp-c-search {
    padding: 0 1rem;
    margin-bottom: 1rem; }
    .vsp-c-sidebar .vsp-c-search .input-group,
    .vsp-c-sidebar .vsp-c-search input {
      width: 100%; }
  .vsp-c-sidebar .vsp-c-search__no-results {
    padding: 0 2rem;
    text-align: center;
    font-size: 1.3rem;
    color: #b1b3b5; }
    @media (max-width: 1280px) {
      .vsp-c-sidebar .vsp-c-search__no-results {
        font-size: 1.2rem; } }
  .vsp-c-sidebar .vsp-c-sidebar__expand-btn {
    display: none; }
    @media (max-width: 480px) {
      .vsp-c-sidebar .vsp-c-sidebar__expand-btn {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #F6F6F6;
        width: 3rem;
        height: 3rem;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        color: #333333;
        position: fixed;
        left: 0px;
        top: 6rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-left: none;
        box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.25), 5px 0 5px -5px rgba(0, 0, 0, 0.25), 0 5px 7px -5px rgba(0, 0, 0, 0.25);
        transition: all .2s ease-out; } }
  .vsp-c-sidebar.active {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25); }
    @media (max-width: 480px) {
      .vsp-c-sidebar.active .vsp-c-sidebar__expand-btn {
        left: 298px; }
        .vsp-c-sidebar.active .vsp-c-sidebar__expand-btn .vsp-c-icon {
          transform: rotate(180deg); } }

.vsp-c-main {
  background-color: #FBFBFB; }
  .vsp-c-main .vsp-c-main__container {
    padding: 4rem; }
  @media (max-width: 1024px) {
    .vsp-c-main .vsp-c-footer .vsp-c-footer__main {
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: center;
      align-items: center; } }
  .vsp-c-main .vsp-c-footer .vsp-c-footer__main .vsp-c-footer__col,
  .vsp-c-main .vsp-c-footer .vsp-c-footer__main .vsp-c-footer__col-wide {
    width: 25%; }
    @media (max-width: 1024px) {
      .vsp-c-main .vsp-c-footer .vsp-c-footer__main .vsp-c-footer__col,
      .vsp-c-main .vsp-c-footer .vsp-c-footer__main .vsp-c-footer__col-wide {
        width: 100%;
        text-align: center; } }
  @media (max-width: 1024px) {
    .vsp-c-main .vsp-c-footer .vsp-c-footer__main .vsp-c-footer__col-wide {
      -ms-flex-align: center;
      align-items: center; } }

.vsp-c-single__content h2, .vsp-c-single__content h3, .vsp-c-single__content h4 {
  color: #548674;
  font-weight: 600;
  margin-bottom: 2rem; }

.vsp-c-single__content h2 {
  font-size: 2rem; }

.vsp-c-single__content h3 {
  font-size: 1.5rem; }

.vsp-c-single__content h4 {
  font-size: 1.25rem; }

.vsp-c-single__content ol, .vsp-c-single__content ul, .vsp-c-single__content p, .vsp-c-single__content blockquote {
  margin-bottom: 2rem; }

.vsp-c-single__content ol, .vsp-c-single__content ul {
  padding-left: 4rem;
  list-style-type: none; }
  .vsp-c-single__content ol li, .vsp-c-single__content ul li {
    margin-bottom: 1rem;
    font-size: 1.1rem; }
    .vsp-c-single__content ol li:before, .vsp-c-single__content ul li:before {
      margin-right: 1rem;
      color: #548674; }

.vsp-c-single__content ul li:before {
  content: '\25CF'; }

.vsp-c-single__content ol > li {
  counter-increment: item; }
  .vsp-c-single__content ol > li ol > li {
    counter-reset: item; }
    .vsp-c-single__content ol > li ol > li:before {
      content: none; }
  .vsp-c-single__content ol > li:before {
    content: counter(item, decimal); }

.vsp-c-single__content ol {
  counter-reset: item; }

.vsp-c-single__content a {
  color: #5dc1a6;
  transition: all .1s ease-out; }
  .vsp-c-single__content a:hover {
    text-decoration: underline; }

.vsp-c-single__content strong, .vsp-c-single__content b {
  font-weight: 600; }

.vsp-c-single__content i {
  font-style: italic; }

.vsp-c-single__content blockquote {
  position: relative;
  padding-left: 5rem;
  font-size: 1.1rem;
  font-style: italic;
  line-height: 1.5;
  font-weight: 300;
  color: #333333; }
  .vsp-c-single__content blockquote:before {
    content: url(/img/icons/quote.svg);
    position: absolute;
    top: .5rem;
    left: 0;
    width: 3rem;
    height: 3rem; }

.vsp-c-single__content img {
  max-width: 100%;
  height: auto; }

.vsp-c-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1999;
  padding: 0 1rem;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.35); }
  .vsp-c-header .vsp-c-navigation {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
    .vsp-c-header .vsp-c-navigation a {
      margin-left: 3rem; }
      .vsp-c-header .vsp-c-navigation a:not(.vsp-c-btn) {
        color: #333333;
        font-weight: 300;
        padding: 1.8rem 0 1.65rem;
        border-bottom: .2rem solid transparent; }
        .vsp-c-header .vsp-c-navigation a:not(.vsp-c-btn).active {
          border-bottom: 0.2rem solid #5dc1a6; }
        .vsp-c-header .vsp-c-navigation a:not(.vsp-c-btn):hover {
          color: #b3b3b3; }
        @media (max-width: 1279px) {
          .vsp-c-header .vsp-c-navigation a:not(.vsp-c-btn) {
            padding: 1.2rem 0; } }
      @media (max-width: 1279px) {
        .vsp-c-header .vsp-c-navigation a.vsp-c-btn {
          margin: .5rem 0 .5rem 1rem; } }
      .vsp-c-header .vsp-c-navigation a:last-of-type {
        margin-left: 2rem; }
      @media (max-width: 1400px) {
        .vsp-c-header .vsp-c-navigation a {
          margin-left: 1.5rem; }
          .vsp-c-header .vsp-c-navigation a:last-of-type {
            margin-left: 1rem; } }
    @media (max-width: 1279px) {
      .vsp-c-header .vsp-c-navigation {
        font-size: .85rem; } }
    @media (max-width: 1024px) {
      .vsp-c-header .vsp-c-navigation {
        position: absolute;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 22rem;
        height: 100vh;
        top: 0;
        left: -22rem;
        background: #ffffff;
        transition: all .2s ease-out;
        font-size: 1.5rem; }
        .vsp-c-header .vsp-c-navigation.active {
          left: 0;
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.35);
          overflow: auto; } }
  .vsp-c-header .vsp-c-navigation__hamburger-btn {
    display: none; }
    @media (max-width: 1024px) {
      .vsp-c-header .vsp-c-navigation__hamburger-btn {
        padding: 1.5rem 0;
        display: inline-block;
        color: #333333; } }

.vsp-c-footer {
  color: #ffffff;
  font-weight: 300; }
  .vsp-c-footer .vsp-c-footer__main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 3rem 2.5rem 0;
    background-color: #548674; }
    @media (max-width: 768px) {
      .vsp-c-footer .vsp-c-footer__main {
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        -ms-flex-align: center;
        align-items: center; } }
    .vsp-c-footer .vsp-c-footer__main .vsp-c-footer__col {
      width: 20%;
      padding: 0 0 2.5rem 2rem; }
    .vsp-c-footer .vsp-c-footer__main .vsp-c-footer__col-wide {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-align: end;
      align-items: flex-end;
      width: 20%;
      margin-bottom: 2rem; }
    @media (max-width: 768px) {
      .vsp-c-footer .vsp-c-footer__main .vsp-c-footer__col {
        width: 100%;
        margin: 0 0 2rem 0;
        padding: 0; }
      .vsp-c-footer .vsp-c-footer__main .vsp-c-footer__col-wide {
        -ms-flex-align: center;
        align-items: center;
        margin: 2rem 0; } }
    .vsp-c-footer .vsp-c-footer__main h3 {
      margin: 0 0 1.5rem;
      font-weight: 300;
      font-size: 1.6rem; }
    .vsp-c-footer .vsp-c-footer__main a {
      color: #ffffff;
      text-transform: uppercase;
      display: block; }
      .vsp-c-footer .vsp-c-footer__main a[href*="mailto"] {
        text-transform: lowercase; }
      .vsp-c-footer .vsp-c-footer__main a:hover {
        text-decoration: underline; }
    .vsp-c-footer .vsp-c-footer__main a,
    .vsp-c-footer .vsp-c-footer__main p {
      font-size: .85rem;
      margin-bottom: 1rem;
      line-height: 1.3; }
    .vsp-c-footer .vsp-c-footer__main .vsp-c-social-nav {
      display: -ms-flexbox;
      display: flex; }
      .vsp-c-footer .vsp-c-footer__main .vsp-c-social-nav a {
        margin-bottom: 0; }
      .vsp-c-footer .vsp-c-footer__main .vsp-c-social-nav .vsp-c-icon {
        fill: #ffffff; }
  .vsp-c-footer .vsp-c-footer__copyright {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem 2.5rem;
    background-color: #4a7666; }

.vsp-c-btn {
  display: inline-block;
  border-radius: 3rem;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
  color: #ffffff;
  line-height: 1;
  cursor: pointer;
  outline: none; }

.vsp-c-btn--primary {
  background-color: #5dc1a6; }
  .vsp-c-btn--primary:hover {
    color: #ffffff;
    background-color: #41aa8e; }

.vsp-c-btn--primary-dark {
  background-color: #548674; }
  .vsp-c-btn--primary-dark:hover {
    color: #ffffff;
    background-color: #406759; }

.vsp-c-btn--secondary {
  background-color: #FE6227; }
  .vsp-c-btn--secondary:hover {
    color: #ffffff;
    background-color: #f14301; }

.vsp-c-btn--ghost {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid transparent; }
  .vsp-c-btn--ghost.vsp-c-btn--secondary {
    border-color: #FE6227; }

.vsp-c-btn--white {
  background-color: #ffffff;
  color: #2c7360; }
  .vsp-c-btn--white:hover {
    background-color: #f2f2f2;
    color: #2c7360; }

.vsp-c-btn--block {
  display: block;
  text-align: center; }

.vsp-c-btn--disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.vsp-c-btn--price {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 1rem;
  border-radius: 1rem; }

.vsp-c-btn--nowrap {
  white-space: nowrap; }

.vsp-c-alert {
  display: block;
  font-weight: 600 !important;
  font-size: 1rem !important;
  padding: 1rem;
  color: #333333;
  margin: 1rem 0;
  border-radius: .5rem;
  box-shadow: 0 0 5px 0 #d4d4d4; }

.vsp-c-alert--success {
  border: 2px solid #5dc1a6; }

.vsp-c-alert--danger {
  border: 2px solid #FE6227; }

.vsp-c-icon {
  width: 1rem;
  height: 1rem;
  fill: currentColor; }

.vsp-c-icon--lg {
  width: 1.4rem;
  height: 1.4rem; }

.vsp-c-icon--xl {
  width: 1.7rem;
  height: 1.7rem; }

.vsp-c-contact .vsp-c-hero {
  background-image: url("/img/hero-bg/contact-us.webp"); }

.vsp-c-contact .vsp-c-contact__content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .vsp-c-contact .vsp-c-contact__content {
      -ms-flex-direction: column;
      flex-direction: column; } }
  .vsp-c-contact .vsp-c-contact__content p {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 2rem; }
    .vsp-c-contact .vsp-c-contact__content p small {
      font-weight: 300;
      font-size: 1rem;
      line-height: 1; }

.vsp-c-contact .vsp-c-contact__text,
.vsp-c-contact .vsp-c-contact__form {
  width: 45%; }
  @media (max-width: 767px) {
    .vsp-c-contact .vsp-c-contact__text,
    .vsp-c-contact .vsp-c-contact__form {
      width: 100%; } }

.vsp-c-contact .vsp-c-contact__form input,
.vsp-c-contact .vsp-c-contact__form textarea {
  width: 100%;
  border: none;
  outline: none;
  background-color: #EEEEEE;
  border-radius: .5rem;
  padding: 1rem;
  color: #548674;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif; }
  .vsp-c-contact .vsp-c-contact__form input:-ms-input-placeholder,
  .vsp-c-contact .vsp-c-contact__form textarea:-ms-input-placeholder {
    color: rgba(84, 134, 116, 0.5);
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    line-height: 1.4rem; }
  .vsp-c-contact .vsp-c-contact__form input::placeholder,
  .vsp-c-contact .vsp-c-contact__form textarea::placeholder {
    color: rgba(84, 134, 116, 0.5);
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    line-height: 1.4rem; }

.vsp-c-contact .vsp-c-contact__form .vsp-c-btn {
  margin-top: 1.5rem;
  border: none; }

.vsp-c-features .vsp-c-hero {
  background-image: url("/img/hero-bg/features.webp"); }

.vsp-c-features .vsp-c-features__item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .vsp-c-features .vsp-c-features__item {
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; } }
  .vsp-c-features .vsp-c-features__item:not(:last-of-type) {
    margin-bottom: 10rem; }
  .vsp-c-features .vsp-c-features__item:nth-of-type(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
    @media (max-width: 767px) {
      .vsp-c-features .vsp-c-features__item:nth-of-type(even) {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; } }
  .vsp-c-features .vsp-c-features__item .vsp-c-search__item-body {
    width: 70%; }
  @media (max-width: 767px) {
    .vsp-c-features .vsp-c-features__item .vsp-c-search__item-body {
      width: 100%; }
    .vsp-c-features .vsp-c-features__item .vsp-c-search__item-media {
      margin: 0 auto 4rem; } }
  .vsp-c-features .vsp-c-features__item a > h2 {
    text-transform: none; }
  .vsp-c-features .vsp-c-features__item h2 {
    font-size: 3rem;
    color: #548674;
    margin-bottom: 2rem; }
  .vsp-c-features .vsp-c-features__item p {
    margin-bottom: 2rem; }
  .vsp-c-features .vsp-c-features__item a {
    text-transform: uppercase;
    color: #FE6227;
    transition: all 0.1s ease-out;
    font-size: 1.25rem;
    font-weight: 600; }
    .vsp-c-features .vsp-c-features__item a:hover {
      color: #d83c01; }

@media (max-width: 1024px) {
  .vsp-c-features .vsp-c-content-gap.vsp-c-content-gap--scrollable-hero {
    padding-top: calc(80px + 9rem); } }

.vsp-c-how-it-works .vsp-c-how-it-works__intro {
  text-align: center; }
  .vsp-c-how-it-works .vsp-c-how-it-works__intro h1 {
    font-weight: 300; }
  .vsp-c-how-it-works .vsp-c-how-it-works__intro .vsp-c-search {
    display: block; }
  .vsp-c-how-it-works .vsp-c-how-it-works__intro .input-group {
    width: 50%; }
    @media (max-width: 767px) {
      .vsp-c-how-it-works .vsp-c-how-it-works__intro .input-group {
        width: 100%; } }
    .vsp-c-how-it-works .vsp-c-how-it-works__intro .input-group input {
      width: 100%;
      -webkit-appearance: caret;
      -moz-appearance: caret; }
  .vsp-c-how-it-works .vsp-c-how-it-works__intro .vsp-c-search__results {
    width: 50%;
    margin: 0 auto;
    right: 0;
    left: 0; }
    @media (max-width: 767px) {
      .vsp-c-how-it-works .vsp-c-how-it-works__intro .vsp-c-search__results {
        width: 100%; } }

.vsp-c-how-it-works .vsp-c-how-it-works__list {
  padding: 4rem 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center; }

.vsp-c-how-it-works .vsp-c-how-it-works__item h2,
.vsp-c-how-it-works .vsp-c-how-it-works__results h2,
.vsp-c-how-it-works .vsp-c-how-it-works__single h2 {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
  transition: all .1s ease-out; }
  .vsp-c-how-it-works .vsp-c-how-it-works__item h2 a,
  .vsp-c-how-it-works .vsp-c-how-it-works__results h2 a,
  .vsp-c-how-it-works .vsp-c-how-it-works__single h2 a {
    color: #FE6227; }
    .vsp-c-how-it-works .vsp-c-how-it-works__item h2 a:hover,
    .vsp-c-how-it-works .vsp-c-how-it-works__results h2 a:hover,
    .vsp-c-how-it-works .vsp-c-how-it-works__single h2 a:hover {
      color: #fe9973; }

.vsp-c-how-it-works .vsp-c-how-it-works__item {
  width: 30%;
  height: 470px; }
  @media (max-width: 1024px) {
    .vsp-c-how-it-works .vsp-c-how-it-works__item {
      width: 48%; } }
  @media (max-width: 767px) {
    .vsp-c-how-it-works .vsp-c-how-it-works__item {
      width: 100%;
      height: auto; } }
  .vsp-c-how-it-works .vsp-c-how-it-works__item .vsp-c-how-it-works__img-container {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 200px; }
    .vsp-c-how-it-works .vsp-c-how-it-works__item .vsp-c-how-it-works__img-container img {
      width: 10rem;
      height: auto; }
  .vsp-c-how-it-works .vsp-c-how-it-works__item h2 {
    text-align: center;
    height: 3rem; }
  @media (max-width: 1024px) {
    .vsp-c-how-it-works .vsp-c-how-it-works__item p {
      text-align: center; } }

.vsp-c-how-it-works .vsp-c-how-it-works__results {
  margin-bottom: 2rem; }
  .vsp-c-how-it-works .vsp-c-how-it-works__results h2 {
    color: #FE6227; }

.vsp-c-how-it-works .vsp-c-how-it-works__no-results {
  font-size: 2rem;
  text-align: center;
  color: #b1b3b5;
  margin-bottom: 5rem; }

.vsp-c-how-it-works .vsp-c-how-it-works__no-results-image {
  width: 20%;
  min-width: 120px;
  display: block;
  margin: 6rem auto; }

.vsp-c-how-it-works .vsp-c-how-it-works__category-item {
  width: 100%;
  padding: 2rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  transition: all .1s ease-out; }
  .vsp-c-how-it-works .vsp-c-how-it-works__category-item:not(:last-of-type) {
    margin-bottom: 2rem; }
  .vsp-c-how-it-works .vsp-c-how-it-works__category-item:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5); }
  .vsp-c-how-it-works .vsp-c-how-it-works__category-item h3,
  .vsp-c-how-it-works .vsp-c-how-it-works__category-item p {
    margin-bottom: 2rem; }
  .vsp-c-how-it-works .vsp-c-how-it-works__category-item h3 {
    color: #548674;
    font-size: 1.25rem; }
  .vsp-c-how-it-works .vsp-c-how-it-works__category-item > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end; }
    .vsp-c-how-it-works .vsp-c-how-it-works__category-item > div a {
      font-size: 1.1rem;
      color: #5dc1a6;
      font-weight: 600;
      text-transform: uppercase;
      transition: all .1s ease-out; }
      .vsp-c-how-it-works .vsp-c-how-it-works__category-item > div a:hover {
        color: #548674; }

.vsp-c-how-it-works .vsp-c-how-it-works__single-content h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #333333; }

.vsp-c-how-it-works .vsp-c-how-it-works__single-content .vsp-c-single__content ol li {
  font-size: 18px; }

.vsp-c-how-it-works .vsp-c-how-it-works__prev-next {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 5rem 0 2rem 0; }

.vsp-c-how-it-works .vsp-c-how-it-works__previous,
.vsp-c-how-it-works .vsp-c-how-it-works__next {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  height: 4rem; }
  .vsp-c-how-it-works .vsp-c-how-it-works__previous svg, .vsp-c-how-it-works .vsp-c-how-it-works__previous h3,
  .vsp-c-how-it-works .vsp-c-how-it-works__next svg,
  .vsp-c-how-it-works .vsp-c-how-it-works__next h3 {
    color: #d4d4d4; }
  .vsp-c-how-it-works .vsp-c-how-it-works__previous svg,
  .vsp-c-how-it-works .vsp-c-how-it-works__next svg {
    height: 85%;
    -ms-flex-item-align: center;
    align-self: center; }
  .vsp-c-how-it-works .vsp-c-how-it-works__previous h3,
  .vsp-c-how-it-works .vsp-c-how-it-works__next h3 {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1.5rem; }
  .vsp-c-how-it-works .vsp-c-how-it-works__previous p,
  .vsp-c-how-it-works .vsp-c-how-it-works__next p {
    font-weight: 600;
    font-size: 1rem;
    color: #548674; }
  .vsp-c-how-it-works .vsp-c-how-it-works__previous div,
  .vsp-c-how-it-works .vsp-c-how-it-works__next div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 1rem; }

.vsp-c-how-it-works .vsp-c-how-it-works__previous svg {
  transform: rotate(180deg); }

.vsp-c-how-it-works .vsp-c-how-it-works__next div {
  text-align: right; }

.vsp-c-how-it-works .vsp-c-main__container {
  min-height: 61vh; }

.vsp-c-about .vsp-c-hero {
  background-image: url("/img/hero-bg/about.webp"); }
  @media (max-width: 1024px) {
    .vsp-c-about .vsp-c-hero {
      background-position: center; } }

.vsp-c-about h2 {
  font-weight: 300;
  font-size: 3rem;
  margin-bottom: 1.5rem; }

.vsp-c-about .vsp-c-about__content-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 5rem;
  -ms-flex-align: center;
  align-items: center; }
  @media (max-width: 1024px) {
    .vsp-c-about .vsp-c-about__content-item {
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-direction: column;
      flex-direction: column; } }

.vsp-c-about .vsp-c-about__content-item-image {
  padding: 2rem;
  margin: 2rem; }
  @media (max-width: 1024px) {
    .vsp-c-about .vsp-c-about__content-item-image {
      width: 60vw;
      padding: 0; } }

.vsp-c-about .vsp-c-about__content-item-text {
  width: 60%; }
  @media (max-width: 1024px) {
    .vsp-c-about .vsp-c-about__content-item-text {
      width: 100%; } }

.vsp-c-about .vsp-c-about__content-product-timeline h2 {
  color: #5dc1a6; }

.vsp-c-about .vsp-c-about__content-product-timeline img {
  width: 100%;
  padding: 2rem; }
  @media (max-width: 1024px) {
    .vsp-c-about .vsp-c-about__content-product-timeline img {
      padding: 0; } }

.vsp-c-insights {
  background-color: #FBFBFB; }
  .vsp-c-insights .vsp-c-hero {
    background-image: url("/img/hero-bg/insights.webp");
    position: static; }
    .vsp-c-insights .vsp-c-hero p {
      width: 80%; }
  .vsp-c-insights .vsp-c-content-gap--scrollable-hero {
    padding-top: 4.6rem; }
    @media (max-width: 1023px) and (orientation: portrait) {
      .vsp-c-insights .vsp-c-content-gap--scrollable-hero {
        padding-top: calc(70px + 4rem); } }
    @media (max-width: 800px) and (max-height: 480px) and (orientation: landscape) {
      .vsp-c-insights .vsp-c-content-gap--scrollable-hero {
        padding-top: 5.3rem; } }
  .vsp-c-insights .vsp-c-insights__tabs {
    background-color: #FBFBFB;
    width: 100%;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 1rem;
    transition: all .2s ease-out;
    z-index: 1; }
    .vsp-c-insights .vsp-c-insights__tabs,
    .vsp-c-insights .vsp-c-insights__tabs nav {
      display: -ms-flexbox;
      display: flex; }
    @media (max-width: 1024px) {
      .vsp-c-insights .vsp-c-insights__tabs {
        top: 54px; } }
    @media (max-width: 768px) {
      .vsp-c-insights .vsp-c-insights__tabs {
        display: block; } }
    @media (max-height: 768px) and (orientation: landscape) {
      .vsp-c-insights .vsp-c-insights__tabs {
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        justify-content: space-between; } }
    .vsp-c-insights .vsp-c-insights__tabs nav {
      -ms-flex-pack: center;
      justify-content: center; }
      .vsp-c-insights .vsp-c-insights__tabs nav a {
        color: #333333;
        padding: 1.5rem .35rem;
        margin: 0 1.5rem;
        border-bottom: 2px solid transparent;
        font-weight: 600;
        transition: all .1s ease-out; }
        .vsp-c-insights .vsp-c-insights__tabs nav a:hover, .vsp-c-insights .vsp-c-insights__tabs nav a.active {
          border-bottom: 2px solid #5dc1a6; }
        .vsp-c-insights .vsp-c-insights__tabs nav a.active {
          color: #548674; }
        @media (max-width: 768px) {
          .vsp-c-insights .vsp-c-insights__tabs nav a {
            margin: 0 .5rem;
            font-size: .9rem; } }
    .vsp-c-insights .vsp-c-insights__tabs.vsp-c-is-scrolled {
      position: fixed; }
      @media (min-width: 1280px) {
        .vsp-c-insights .vsp-c-insights__tabs.vsp-c-is-scrolled {
          top: 70px; } }
      @media (max-width: 1279px) {
        .vsp-c-insights .vsp-c-insights__tabs.vsp-c-is-scrolled {
          top: 53px; } }
  .vsp-c-insights .vsp-c-insights__content {
    padding: 5rem 2rem; }
    .vsp-c-insights .vsp-c-insights__content h2 {
      color: #FE6227;
      font-size: 2rem;
      font-weight: 600; }
    @media (max-width: 768px) {
      .vsp-c-insights .vsp-c-insights__content {
        padding-top: 1rem; } }
  .vsp-c-insights .vsp-c-insights__post-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .vsp-c-insights .vsp-c-insights__post-item {
    width: 48%;
    height: 335px;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .1s ease-out; }
    @media (max-width: 1024px) {
      .vsp-c-insights .vsp-c-insights__post-item {
        width: 100%; } }
    .vsp-c-insights .vsp-c-insights__post-item:hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); }
    .vsp-c-insights .vsp-c-insights__post-item:not(:last-of-type) {
      margin-bottom: 2rem; }
    .vsp-c-insights .vsp-c-insights__post-item:nth-of-type(3n + 1) {
      width: 100%;
      height: 415px; }
      @media (max-width: 1024px) {
        .vsp-c-insights .vsp-c-insights__post-item:nth-of-type(3n + 1) {
          height: 335px; } }
    .vsp-c-insights .vsp-c-insights__post-item a {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: end;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      color: #ffffff;
      padding: 3rem;
      background-color: rgba(0, 0, 0, 0.25); }
    .vsp-c-insights .vsp-c-insights__post-item h3 {
      margin-bottom: 2rem;
      font-weight: 600; }
    .vsp-c-insights .vsp-c-insights__post-item p {
      line-height: 1;
      word-break: break-word; }

.vsp-c-single .vsp-c-single__featured-image {
  height: 40vh;
  background-size: cover;
  background-repeat: no-repeat; }

.vsp-c-single .vsp-c-single__container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 6rem 0 8rem;
  position: relative; }
  .vsp-c-single .vsp-c-single__container main {
    width: 70%; }
  .vsp-c-single .vsp-c-single__container aside {
    width: 25%; }
    .vsp-c-single .vsp-c-single__container aside .vsp-c-single__header {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #5dc1a6;
      margin-bottom: 1.5rem; }
      @media (max-width: 1400px) {
        .vsp-c-single .vsp-c-single__container aside .vsp-c-single__header {
          padding-bottom: 1rem;
          margin-bottom: 1rem; } }
  @media (max-width: 1400px) {
    .vsp-c-single .vsp-c-single__container {
      padding: 3rem 0 4rem; } }
  @media (max-width: 1023px) {
    .vsp-c-single .vsp-c-single__container {
      -ms-flex-direction: column;
      flex-direction: column; }
      .vsp-c-single .vsp-c-single__container main,
      .vsp-c-single .vsp-c-single__container aside {
        width: 100%; }
      .vsp-c-single .vsp-c-single__container main {
        margin-bottom: 2rem; } }

.vsp-c-single .vsp-c-single__header {
  font-size: 2.5rem;
  font-weight: 700; }
  @media (max-width: 1400px) {
    .vsp-c-single .vsp-c-single__header {
      font-size: 2rem; } }

.vsp-c-single .vsp-c-single__back {
  color: #548674;
  font-weight: 600; }

.vsp-c-single .vsp-c-single__meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #5dc1a6; }
  .vsp-c-single .vsp-c-single__meta p {
    color: #548674;
    font-weight: 600; }
  .vsp-c-single .vsp-c-single__meta nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }

@media (min-width: 1280px) {
  .vsp-c-single .vsp-c-single__sidebar.vsp-c-is-scrolled {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    margin: 0 auto;
    width: 1200px; }
    .vsp-c-single .vsp-c-single__sidebar.vsp-c-is-scrolled .vsp-c-single__sidebar-container {
      position: absolute;
      right: 1rem;
      width: 24.5%;
      top: calc(3rem + 70px); } }

@media (min-width: 1400px) {
  .vsp-c-single .vsp-c-single__sidebar.vsp-c-is-scrolled .vsp-c-single__sidebar-container {
    top: calc(6rem + 70px); } }

.vsp-c-single .vsp-c-single__sidebar-item {
  margin-bottom: 1.5rem; }
  @media (max-width: 1400px) {
    .vsp-c-single .vsp-c-single__sidebar-item {
      margin-bottom: 1rem; } }
  .vsp-c-single .vsp-c-single__sidebar-item ul li {
    margin-bottom: 1rem; }
    @media (max-width: 1400px) {
      .vsp-c-single .vsp-c-single__sidebar-item ul li {
        margin-bottom: .5rem; } }
    @media (max-height: 768px) {
      .vsp-c-single .vsp-c-single__sidebar-item ul li:not(:first-of-type):not(:nth-of-type(2)) {
        display: none; }
      .vsp-c-single .vsp-c-single__sidebar-item ul li:nth-of-type(2) {
        margin-bottom: 0; } }
    @media (max-width: 1279px) and (max-height: 768px) {
      .vsp-c-single .vsp-c-single__sidebar-item ul li:not(:first-of-type):not(:nth-of-type(2)) {
        display: initial; } }
    .vsp-c-single .vsp-c-single__sidebar-item ul li a {
      color: #548674;
      font-size: 1.1rem;
      font-weight: 600;
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
      transition: all .1s ease-out; }
      .vsp-c-single .vsp-c-single__sidebar-item ul li a:focus {
        outline: none; }
      .vsp-c-single .vsp-c-single__sidebar-item ul li a:hover {
        color: #5dc1a6; }

.vsp-c-single .vsp-c-single__content {
  padding-top: 4rem; }

.vsp-c-insights__group-previews .vsp-c-single__preview-card,
.vsp-c-single .vsp-c-single__preview-card {
  cursor: pointer;
  border-radius: 1rem;
  transition: all .1s ease-out; }
  @media (max-width: 1023px) {
    .vsp-c-insights__group-previews .vsp-c-single__preview-card,
    .vsp-c-single .vsp-c-single__preview-card {
      width: 280px; } }
  .vsp-c-insights__group-previews .vsp-c-single__preview-card:hover,
  .vsp-c-single .vsp-c-single__preview-card:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); }
  .vsp-c-insights__group-previews .vsp-c-single__preview-card a,
  .vsp-c-single .vsp-c-single__preview-card a {
    cursor: pointer;
    color: #000000; }
    .vsp-c-insights__group-previews .vsp-c-single__preview-card a a:active,
    .vsp-c-single .vsp-c-single__preview-card a a:active {
      color: #333333; }
  .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-img-container,
  .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-img-container {
    width: 100%;
    height: 200px; }
    @media (max-height: 768px) {
      .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-img-container,
      .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-img-container {
        height: 140px; } }
    @media (max-width: 1279px) and (max-height: 768px) {
      .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-img-container,
      .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-img-container {
        height: 200px; } }
    .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-img-container img,
    .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-img-container img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem; }
  .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-info,
  .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-info {
    height: 200px;
    padding: 1rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid #EEEEEE;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: #ffffff; }
    @media (max-height: 768px) {
      .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-info,
      .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-info {
        height: 140px; } }
    @media (max-width: 1279px) and (max-height: 768px) {
      .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-info,
      .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-info {
        height: 200px; } }
    .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-info h3,
    .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-info h3 {
      font-size: 1.75rem;
      font-weight: 300;
      overflow: hidden;
      max-height: 5rem;
      position: relative;
      height: 3.6em;
      cursor: pointer; }
      .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-info h3:after,
      .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-info h3:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30%;
        height: 1.2em;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%); }
      .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-info h3 a,
      .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-info h3 a {
        color: #333333; }
  .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-meta,
  .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-meta {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-meta a,
    .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-meta p,
    .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-meta a,
    .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-meta p {
      font-weight: 600;
      font-size: 1rem; }
    .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-meta p,
    .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-meta p {
      cursor: default; }
    .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-meta a,
    .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-meta p,
    .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-meta a,
    .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-meta p {
      color: #548674; }
      .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-meta a:hover,
      .vsp-c-insights__group-previews .vsp-c-single__preview-card .vsp-c-single__preview-card-meta p:hover,
      .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-meta a:hover,
      .vsp-c-single .vsp-c-single__preview-card .vsp-c-single__preview-card-meta p:hover {
        color: #5dc1a6; }

.vsp-c-insights__group-previews-heading {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.vsp-c-insights__group-previews {
  margin-bottom: 5rem; }
  @media (max-width: 1024px) {
    .vsp-c-insights__group-previews {
      padding: 0 1rem; } }
  @media (max-width: 768px) {
    .vsp-c-insights__group-previews h2 {
      text-align: center;
      margin-top: 2rem; } }
  .vsp-c-insights__group-previews ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-direction: row;
    flex-direction: row; }
    @media (max-width: 600px) {
      .vsp-c-insights__group-previews ul {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center; } }
    @media (max-width: 924px) {
      .vsp-c-insights__group-previews ul {
        -ms-flex-pack: distribute;
        justify-content: space-around; }
        .vsp-c-insights__group-previews ul li:last-child {
          display: none; } }
  .vsp-c-insights__group-previews h3 {
    cursor: default; }
  .vsp-c-insights__group-previews .vsp-c-single__preview-card {
    width: 21.5rem; }
    @media (max-width: 768px) {
      .vsp-c-insights__group-previews .vsp-c-single__preview-card {
        margin-bottom: 2rem;
        min-width: 45%; } }

.vs-c-blog__text {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 25px; }

.vs-c-blog__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.vsp-l-content {
  padding-top: 70px;
  overflow-x: hidden;
  width: 100%; }
  @media (max-width: 1279px) {
    .vsp-l-content {
      padding-top: 53px; } }

.vsp-l-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem; }

.vsp-l-container--narrow {
  max-width: 1000px; }

.vsp-l-container--wide {
  max-width: 1200px; }

.vsp-l-sidebar-container {
  width: 100%;
  padding: 0;
  display: -ms-flexbox;
  display: flex; }
  .vsp-l-sidebar-container aside {
    width: 320px; }
  .vsp-l-sidebar-container main {
    width: calc(100% - 320px); }
  @media (max-width: 1279px) {
    .vsp-l-sidebar-container aside {
      width: 300px; }
    .vsp-l-sidebar-container main {
      width: calc(100% - 245px); } }
  @media (max-width: 767px) {
    .vsp-l-sidebar-container main {
      width: 100%; } }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  font-size: 14px;
  scroll-behavior: smooth; }
  @media (max-width: 1024px) {
    html {
      font-size: 12px; } }

body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 100%;
  counter-reset: item; }
  body.colorbox-active {
    overflow: hidden; }

::-moz-selection,
::-moz-selection {
  color: white;
  background: #5dc1a6; }

::selection,
::-moz-selection {
  color: white;
  background: #5dc1a6; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

h1 {
  font-size: 60px; }

h2 {
  font-size: 4.3rem; }

h3 {
  font-size: 2rem; }

h4 {
  font-size: 1.5rem; }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

p {
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
  font-weight: 300; }

a {
  transition: all .1s ease-out; }
  a, a:hover, a:focus {
    text-decoration: none; }

small {
  font-size: .8rem; }

object {
  pointer-events: none; }

.vsp-c-landing h1 {
  line-height: 1.3;
  font-weight: 700;
  font-size: 3rem; }
  @media (orientation: landscape) and (max-width: 1023px) {
    .vsp-c-landing h1 {
      font-size: 2.5rem; } }

.vsp-c-landing h2 {
  font-size: 2rem;
  font-weight: 300; }

.vsp-c-landing-desc__subtitle {
  font-size: 3rem !important; }

.vsp-c-landing-intro-container {
  -ms-flex-pack: justify;
  justify-content: space-between; }

.vsp-c-landing-intro {
  position: relative;
  height: calc(100vh - 70px);
  background-image: url("/img/home/background/green-bg.png");
  background-size: cover; }
  @media (max-width: 1279px) {
    .vsp-c-landing-intro {
      height: calc(100vh - 52px); } }
  .vsp-c-landing-intro .vsp-l-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%; }
  .vsp-c-landing-intro .vsp-c-landing-intro__desc {
    width: 40%;
    color: #ffffff;
    z-index: 10; }
    @media (max-width: 1024px) {
      .vsp-c-landing-intro .vsp-c-landing-intro__desc {
        width: 70%; } }
    @media (max-width: 480px) {
      .vsp-c-landing-intro .vsp-c-landing-intro__desc {
        width: 100%; } }
    .vsp-c-landing-intro .vsp-c-landing-intro__desc p {
      font-weight: 400; }
      @media (orientation: landscape) and (max-width: 1023px) {
        .vsp-c-landing-intro .vsp-c-landing-intro__desc p {
          font-size: 15px; } }
  .vsp-c-landing-intro .vsp-c-landing-intro__animation {
    position: absolute;
    right: -10rem;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1; }
    @media (max-width: 1400px) {
      .vsp-c-landing-intro .vsp-c-landing-intro__animation {
        right: 0; } }

.vsp-c-landing-desc {
  padding-top: 3rem;
  background: url("/img/mac.png") 0 15rem no-repeat #FBFBFB;
  background-size: 40rem; }
  @media (max-width: 1024px) {
    .vsp-c-landing-desc {
      background: #FBFBFB; } }
  .vsp-c-landing-desc h2 {
    color: #5dc1a6;
    margin-bottom: 2.5rem;
    text-align: center; }
  .vsp-c-landing-desc .vsp-c-landing-desc__text {
    width: 70%;
    margin: 0 auto;
    text-align: center; }
    @media (max-width: 1024px) {
      .vsp-c-landing-desc .vsp-c-landing-desc__text {
        width: 100%; } }
  .vsp-c-landing-desc .vs-c-landing-desc__content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 4rem; }
    @media (max-width: 1024px) {
      .vsp-c-landing-desc .vs-c-landing-desc__content {
        -ms-flex-pack: start;
        justify-content: flex-start; } }
    .vsp-c-landing-desc .vs-c-landing-desc__content ul {
      width: 45%;
      padding-bottom: 5rem;
      position: relative;
      z-index: 10; }
      @media (max-width: 1024px) {
        .vsp-c-landing-desc .vs-c-landing-desc__content ul {
          width: 100%; } }
      .vsp-c-landing-desc .vs-c-landing-desc__content ul:before {
        content: "";
        border-left: 2px dashed #5dc1a6;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 2.8rem;
        z-index: 1; }
        @media (max-width: 1024px) {
          .vsp-c-landing-desc .vs-c-landing-desc__content ul:before {
            left: 2.5rem; } }
    .vsp-c-landing-desc .vs-c-landing-desc__content li {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      margin-bottom: 4rem;
      /* Fix for this svg not showing correctly. */ }
      .vsp-c-landing-desc .vs-c-landing-desc__content li:nth-child(2) svg {
        width: 7rem; }
        @media (max-width: 1024px) {
          .vsp-c-landing-desc .vs-c-landing-desc__content li:nth-child(2) svg {
            width: 5rem; } }
      .vsp-c-landing-desc .vs-c-landing-desc__content li img, .vsp-c-landing-desc .vs-c-landing-desc__content li svg {
        margin-right: 2rem;
        z-index: 10;
        width: 8rem;
        height: 6rem; }
        @media (max-width: 1024px) {
          .vsp-c-landing-desc .vs-c-landing-desc__content li img, .vsp-c-landing-desc .vs-c-landing-desc__content li svg {
            width: 5rem;
            height: 4.5rem;
            -ms-flex-negative: 0;
            flex-shrink: 0; } }
  .vsp-c-landing-desc .vsp-c-landing-desc__features h3 {
    text-transform: uppercase;
    color: #FE6227;
    margin-bottom: 2rem; }
    @media (max-width: 1024px) {
      .vsp-c-landing-desc .vsp-c-landing-desc__features h3 {
        margin-bottom: 1rem; } }

.vsp-c-landing-intro__image-container {
  width: 40%;
  padding: 3rem; }
  @media (max-width: 768px) {
    .vsp-c-landing-intro__image-container {
      display: none; } }
  @media (max-width: 1280px) {
    .vsp-c-landing-intro__image-container {
      margin: 0 2rem; } }

.vsp-c-landing-intro__image {
  width: 100%; }

@media (max-width: 768px) {
  .vsp-c-landing-intro__desc-heading {
    font-size: 2.5rem; } }

.vsp-c-landing-desc__container {
  padding-bottom: 1rem; }
  .vsp-c-landing-desc__container p {
    width: 60%;
    text-align: center;
    margin: 0 auto 2rem; }

.vsp-c-landing-desc--subtitle {
  background: none;
  padding: 3rem 3rem 0 3rem; }

.vsp-c-landing-features {
  padding: 8rem; }
  @media (max-width: 768px) {
    .vsp-c-landing-features {
      padding: 3rem 1.5rem; } }
  .vsp-c-landing-features ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .vsp-c-landing-features .vs-c-landing-features-card {
    width: 28%;
    padding: 4rem 2rem;
    border-radius: 1.5rem;
    position: relative;
    text-align: center;
    height: 500px;
    transition: all .2s ease-out;
    cursor: pointer; }
    @media (max-width: 1024px) {
      .vsp-c-landing-features .vs-c-landing-features-card {
        width: 45%;
        height: 450px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        margin-bottom: 2rem; } }
    @media (max-width: 767px) {
      .vsp-c-landing-features .vs-c-landing-features-card {
        width: 80%; } }
    @media (max-width: 480px) {
      .vsp-c-landing-features .vs-c-landing-features-card {
        width: 95%; } }
    .vsp-c-landing-features .vs-c-landing-features-card:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4); }
      .vsp-c-landing-features .vs-c-landing-features-card:hover a {
        opacity: 1; }
    .vsp-c-landing-features .vs-c-landing-features-card img, .vsp-c-landing-features .vs-c-landing-features-card svg {
      width: 7rem;
      height: 7rem; }
    .vsp-c-landing-features .vs-c-landing-features-card h3 {
      color: #548674;
      font-size: 2rem;
      margin: 1.5rem 0 2.5rem;
      height: 3.5rem; }
    .vsp-c-landing-features .vs-c-landing-features-card p {
      margin-bottom: 3rem; }
    .vsp-c-landing-features .vs-c-landing-features-card a {
      opacity: 0;
      color: #FE6227;
      text-transform: uppercase;
      position: absolute;
      bottom: 1rem;
      right: 2rem;
      transition: all .2s ease-out; }
      @media (max-width: 1024px) {
        .vsp-c-landing-features .vs-c-landing-features-card a {
          opacity: 1; } }
    .vsp-c-landing-features .vs-c-landing-features-card .vs-c-landing-features-card--download {
      position: absolute;
      bottom: 1rem;
      left: 2rem;
      text-align: left; }
      .vsp-c-landing-features .vs-c-landing-features-card .vs-c-landing-features-card--download small {
        padding: .6rem;
        color: #ffffff;
        border-radius: 1rem; }

.vsp-c-landing-desc__section {
  display: -ms-flexbox;
  display: flex;
  padding: 4rem; }
  @media (max-width: 1024px) {
    .vsp-c-landing-desc__section {
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: center;
      align-items: center; } }

.vsp-c-landing-desc__section-image {
  width: 100%; }
  @media (max-width: 1024px) {
    .vsp-c-landing-desc__section-image {
      width: 60%; } }
  @media (max-width: 768px) {
    .vsp-c-landing-desc__section-image {
      width: 70%; } }
  @media (max-width: 480px) {
    .vsp-c-landing-desc__section-image {
      width: 90%; } }

.vsp-c-landing-desc__section-content {
  width: 60%;
  padding: 0 5% 3rem 10%; }
  @media (max-width: 1280px) {
    .vsp-c-landing-desc__section-content {
      min-height: 20rem; } }
  @media (max-width: 1024px) {
    .vsp-c-landing-desc__section-content {
      text-align: center;
      width: 90%;
      padding: 0 0 3rem 0;
      min-height: 0; } }
  .vsp-c-landing-desc__section-content h2 {
    font-weight: 700;
    margin-bottom: 2rem;
    color: #548674;
    font-size: 2.5rem; }
  .vsp-c-landing-desc__section-content p {
    word-break: break-word; }
  @media (max-width: 768px) {
    .vsp-c-landing-desc__section-content h2 {
      font-size: 2rem; }
    .vsp-c-landing-desc__section-content p {
      font-size: 1rem; } }

.vsp-c-landing-desc__section-image-first {
  padding-left: 15%; }
  .vsp-c-landing-desc__section-image-first .vsp-c-landing-desc__section-content {
    padding-left: 5%; }
  @media (max-width: 1024px) {
    .vsp-c-landing-desc__section-image-first {
      padding-left: 4rem;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }
      .vsp-c-landing-desc__section-image-first .vsp-c-landing-desc__section-content {
        padding-left: 0; } }

.vsp-c-landing-desc__section-image-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 40%;
  padding: 0 3rem; }
  @media (max-width: 1280px) {
    .vsp-c-landing-desc__section-image-container {
      padding: 0 1rem; } }
  @media (max-width: 1024px) {
    .vsp-c-landing-desc__section-image-container {
      width: 80%;
      -ms-flex-pack: center;
      justify-content: center; } }

.vsp-c-landing .vsp-c-landing-pricing__title {
  color: #548674;
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 700; }

.vsp-c-landing-pricing__container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center; }
  .vsp-c-landing-pricing__container h1 {
    color: #548674; }
    @media (max-width: 768px) {
      .vsp-c-landing-pricing__container h1 {
        font-size: 2rem; } }

.vsp-c-landing-pricing {
  background-image: url("/img/home/background/grey-bg.png");
  background-size: cover;
  padding: 3rem 0; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__intro {
    text-align: center; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-align: start;
    align-items: flex-start; }
    @media (max-width: 1024px) {
      .vsp-c-landing-pricing .vsp-c-landing-pricing__plans {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center; } }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item {
    width: 300px;
    max-width: 90%;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); }
    @media (max-width: 1024px) {
      .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item:first-of-type, .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item:nth-of-type(2) {
        margin-bottom: 2rem; } }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-header,
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-header--enterprise {
    padding: 3rem 1rem 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    height: 17rem; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-header--enterprise {
    background-image: url("/img/home/pricing-enterprise.jpg");
    background-size: cover; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-header--customized {
    padding-top: 1rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-body {
    padding: 1rem;
    background-color: #ffffff;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem; }
    .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-body .vsp-c-btn {
      margin-top: 1rem; }
    .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-body .vsp-c-btn-pricing--top-gap {
      margin-top: 2rem; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-body--spacing {
    padding: 1rem 0; }
  .vsp-c-landing-pricing .vsp-c-price {
    color: #ffffff;
    padding-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 400; }
    .vsp-c-landing-pricing .vsp-c-price span {
      color: #d9d9d9; }
  .vsp-c-landing-pricing .vsp-c-price--spacing-none {
    padding-bottom: 0; }
  .vsp-c-landing-pricing .vsp-c-btn--white:hover {
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5); }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__disclaimer {
    color: #ffffff;
    text-align: center;
    margin-top: 1rem; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__disclaimer--gap {
    margin-top: 0;
    margin-bottom: 1rem; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-options,
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-options--enterprise {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-options p,
    .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-options--enterprise p {
      font-size: .95rem; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-options:not(:last-of-type) {
    margin-bottom: 1rem; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-options p:last-of-type {
    color: #5dc1a6;
    text-transform: uppercase;
    font-weight: 600; }
  .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-options--enterprise {
    margin-bottom: 1rem; }
    .vsp-c-landing-pricing .vsp-c-landing-pricing__plans-item-options--enterprise p {
      color: #548674;
      font-weight: 600; }

.vsp-c-landing-pricing__plans--gap-top {
  margin-top: 5rem; }

.vsp-c-landing-pricing--gap-big {
  padding: 6rem 0; }

.vsp-c-landing-pricing__plans-img-wrapper {
  height: 3.5rem;
  width: 3.5rem; }

.vsp-c-landing-pricing__plans-img {
  width: 100%; }

.vsp-c-landing-pricing__plans-item--centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 2rem;
  height: 22rem;
  background-color: #ffffff;
  box-shadow: none !important;
  border: 3px solid transparent; }
  .vsp-c-landing-pricing__plans-item--centered:hover {
    border-color: #5dc1a6;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25); }
    .vsp-c-landing-pricing__plans-item--centered:hover .vsp-c-landing-pricing__plans-heading,
    .vsp-c-landing-pricing__plans-item--centered:hover .vsp-c-landing-pricing__plans-description {
      color: #548674; }
    .vsp-c-landing-pricing__plans-item--centered:hover .vsp-c-landing-pricing__plans-btn {
      background-color: #5dc1a6;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }

.vsp-c-landing-pricing__plans-item--premium, .vsp-c-landing-pricing__plans-item--enterprise {
  border-color: #5dc1a6;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25); }
  .vsp-c-landing-pricing__plans-item--premium .vsp-c-landing-pricing__plans-heading,
  .vsp-c-landing-pricing__plans-item--premium .vsp-c-landing-pricing__plans-description, .vsp-c-landing-pricing__plans-item--enterprise .vsp-c-landing-pricing__plans-heading,
  .vsp-c-landing-pricing__plans-item--enterprise .vsp-c-landing-pricing__plans-description {
    color: #548674; }
  .vsp-c-landing-pricing__plans-item--premium .vsp-c-landing-pricing__plans-btn, .vsp-c-landing-pricing__plans-item--enterprise .vsp-c-landing-pricing__plans-btn {
    background-color: #5dc1a6;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }

.vsp-c-landing-pricing__plans-description-link {
  font-weight: bold; }

.vsp-c-landing-pricing__plans-btn {
  width: 100%;
  font-size: .75rem;
  padding: .75rem;
  text-align: center;
  background-color: #C6E2D9; }

.vsp-c-landing-pricing__plans-heading,
.vsp-c-landing-pricing__plans-description {
  color: #C6E2D9; }

.vsp-c-landing-pricing__plans-heading {
  text-transform: uppercase; }

.vsp-c-landing-pricing__plans-description {
  text-align: center;
  font-size: 14px; }

.vsp-c-landing-pricing__plans-description--gap-md {
  padding: .7rem; }

.vsp-c-landing-pricing__intro--light {
  color: rgba(0, 0, 0, 0.87); }

.vsp-c-landing-pricing--gap {
  padding: 5rem 0 3rem; }

.vsp-c-btn--gap {
  margin-top: 15%; }

.vsp-c-landing-customers {
  padding: 6rem 0; }
  .vsp-c-landing-customers .vsp-c-landing-customers__content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center; }
  .vsp-c-landing-customers .vsp-c-landing-customers__text {
    display: -ms-flexbox;
    display: flex;
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-direction: column;
    flex-direction: column; }
    @media (max-width: 480px) {
      .vsp-c-landing-customers .vsp-c-landing-customers__text {
        -ms-flex-align: center;
        align-items: center; } }
    .vsp-c-landing-customers .vsp-c-landing-customers__text h2 {
      margin-bottom: 2rem;
      text-align: center;
      color: #5dc1a6;
      margin-left: 13%;
      margin-top: 10%; }
      @media (max-width: 480px) {
        .vsp-c-landing-customers .vsp-c-landing-customers__text h2 {
          margin-left: 0;
          margin-top: 0; } }
    .vsp-c-landing-customers .vsp-c-landing-customers__text p {
      font-size: 1.25rem;
      margin-left: 13%; }
      @media (max-width: 480px) {
        .vsp-c-landing-customers .vsp-c-landing-customers__text p {
          margin-left: 0;
          text-align: center; } }
  .vsp-c-landing-customers .vsp-c-landing-customers__companies {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr)); }
    .vsp-c-landing-customers .vsp-c-landing-customers__companies .vsp-c-landing-customers__logo-container {
      margin: .5rem 0;
      text-align: center;
      padding: 0 17%; }
      .vsp-c-landing-customers .vsp-c-landing-customers__companies .vsp-c-landing-customers__logo-container img {
        display: block;
        transition: all .2s ease-out;
        width: 100%; }
        .vsp-c-landing-customers .vsp-c-landing-customers__companies .vsp-c-landing-customers__logo-container img:hover {
          transform: scale(1.1); }

.vsp-c-landing-conclusion {
  padding: 6rem 0;
  background-color: #FBFBFB; }
  .vsp-c-landing-conclusion h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem; }
  .vsp-c-landing-conclusion p {
    font-size: 1.25rem;
    margin-bottom: 2rem; }
  .vsp-c-landing-conclusion .vsp-c-landing-conclusion__content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .vsp-c-landing-conclusion .vsp-c-landing-conclusion__text {
    width: 30%; }
    @media (max-width: 1024px) {
      .vsp-c-landing-conclusion .vsp-c-landing-conclusion__text {
        width: 40%; } }
    @media (max-width: 767px) {
      .vsp-c-landing-conclusion .vsp-c-landing-conclusion__text {
        width: 100%;
        margin-bottom: 2rem; } }
  .vsp-c-landing-conclusion .vsp-c-landing-conclusion__gif-container,
  .vsp-c-landing-conclusion .vsp-c-landing-conclusion__img-container {
    text-align: center;
    width: 30%; }
    @media (max-width: 767px) {
      .vsp-c-landing-conclusion .vsp-c-landing-conclusion__gif-container,
      .vsp-c-landing-conclusion .vsp-c-landing-conclusion__img-container {
        width: 50%; }
        .vsp-c-landing-conclusion .vsp-c-landing-conclusion__gif-container img,
        .vsp-c-landing-conclusion .vsp-c-landing-conclusion__img-container img {
          width: 50%; } }
    @media (max-width: 480px) {
      .vsp-c-landing-conclusion .vsp-c-landing-conclusion__gif-container img,
      .vsp-c-landing-conclusion .vsp-c-landing-conclusion__img-container img {
        width: 70%; } }
  .vsp-c-landing-conclusion .vsp-c-landing-conclusion__gif-container {
    -ms-flex-item-align: end;
    align-self: flex-end; }

.vsp-c-landing-conclusion__text--template {
  margin: 0 auto;
  text-align: center;
  width: 100% !important; }
  .vsp-c-landing-conclusion__text--template h2 {
    font-weight: 700;
    color: #548674;
    font-size: 2.5rem; }

.vsp-u-gap--bottom-xs {
  margin-bottom: 0.5rem; }

.vsp-u-gap--bottom {
  margin-bottom: 1rem; }

.vsp-u-gap--bottom-md {
  margin-bottom: 1.5rem; }

.vsp-u-gap--bottom-lg {
  margin-bottom: 2rem !important; }

.vsp-u-gap--bottom-xl {
  margin-bottom: 2.5rem !important; }

.vsp-u-gap--left {
  margin-left: 1rem; }

.vsp-u-gap--left-xxl {
  margin-left: 3rem; }

.vsp-u-gap--right {
  margin-right: 1rem; }

.vsp-u-gap--right-sm {
  margin-right: 0.5rem; }

.vsp-u-gap--right-xxl {
  margin-right: 3rem; }

.vsp-u-gap--bottom-md {
  margin-bottom: 1.5rem !important; }

.vsp-u-gap--bottom-xxl {
  margin-bottom: 3rem !important; }

.vsp-u-gap--bottom-xxxl {
  margin-bottom: 8rem; }

.vsp-u-gap--left-xs {
  margin-left: 0.5rem; }

.vsp-u-gap--left {
  margin-left: 1rem; }

.vsp-u-gap--right-lg {
  margin-right: 2rem; }

.vsp-u-gap--top-sm {
  margin-top: 0.5rem; }

.vsp-u-gap--top-md {
  margin-top: 1.5rem; }

.vsp-c-gap--top-lg {
  margin-top: 2rem; }

.vsp-u-gap--top-xxl {
  margin-top: 3rem; }

.vsp-u-gap--top-5 {
  margin-top: 5rem; }

.vsp-u-text-uppercase {
  text-transform: uppercase; }

.vsp-u-text-normal {
  text-transform: inherit !important; }

.vsp-u-bg-color--primary {
  background-color: #5dc1a6; }

.vsp-u-bg-color--primary-dark {
  background-color: #548674; }

.vsp-u-text--center {
  text-align: center; }

.vsp-u-color--primary {
  color: #5dc1a6; }

.vsp-u-color--secondary {
  color: #FE6227; }

.vsp-u-color--primary-dark {
  color: #548674; }

.vsp-u-color--fb {
  color: #3b5998; }

.vsp-u-color--in {
  color: #4875B4; }

.vsp-u-color--tw {
  color: #55ACEE; }

.vsp-u-overflow--hidden {
  overflow: hidden; }

.vsp-u-pull--right-flex {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.vsp-u-justify-center {
  -ms-flex-pack: center;
  justify-content: center; }

.vsp-u-justify-space-between {
  -ms-flex-pack: justify;
  justify-content: space-between; }

.vsp-u-justify-space-evenly {
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly; }

.vsp-u-align-center {
  -ms-flex-align: center;
  align-items: center; }

.vsp-u-display-flex {
  display: -ms-flexbox;
  display: flex; }

.vsp-u-flex-row {
  -ms-flex-direction: row;
  flex-direction: row; }

.vsp-u-flex-column {
  -ms-flex-direction: column;
  flex-direction: column; }

.vsp-u-cursor-pointer {
  cursor: pointer; }

.vsp-u-font-normal {
  font-weight: normal !important; }

.vsp-u-font-semi-bold {
  font-weight: 600 !important; }

.vsp-u-display--none {
  display: none !important; }

.vsp-u-lock-scroll {
  height: 100vh;
  overflow-y: hidden; }

:root {
  --color-brand-green: #418b75;
  --color-green-light: #59b89e;
  --color-green-lighter: #63c0a8;
  --color-green-lightest: #74d1b6;
  --color-green-lighter-than-lightest: #Def1ec;
  --color-green-light-20: rgba(116, 209, 182, 0.2);
  --color-green-dark: #20453a;
  --color-white: #ffffff;
  --color-grey: #c2c2c2;
  --color-glider-grey: #ccc;
  --color-white-dirty: #eeeeee;
  --color-black-10: rgba(0, 0, 0, 0.1);
  --color-black-30: rgba(0, 0, 0, 0.3);
  --font-size-sm: 0.85rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.2rem;
  --gap-negative: -0.36rem;
  --gap: 1rem;
  --gap-sm: 0.5rem;
  --gap-md: 0.75rem;
  --gap-ml: 1.5rem;
  --gap-lg: 2rem;
  --gap-xl: 4rem; }

.vsp-u-container {
  width: calc(100% - var(--gap-lg));
  margin-right: var(--gap);
  margin-left: var(--gap); }
  @media (min-width: 1024px) {
    .vsp-u-container {
      margin-right: auto;
      margin-left: auto; } }
  @media (min-width: 1280px) {
    .vsp-u-container {
      width: 1180px; } }

.vsp-u-color--primary-dark {
  color: var(--color-brand-green); }

.vsp-c-icon {
  display: inline-block;
  vertical-align: middle; }

.vsp-c-collapse > summary {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: var(--gap);
  color: var(--color-brand-green);
  font-weight: 700;
  font-size: 1.7rem;
  cursor: pointer; }
  .vsp-c-collapse > summary .safari-fix-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
  .vsp-c-collapse > summary::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: auto;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: var(--color-brand-green) transparent transparent transparent;
    content: ''; }
  .vsp-c-collapse > summary::-webkit-details-marker {
    display: none; }
  .vsp-c-collapse > summary:focus:not(:focus-visible) {
    outline: none; }
  .vsp-c-collapse > summary:focus {
    outline: none; }

.vsp-c-collapse[open] > summary::after {
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent var(--color-brand-green) transparent; }

.vsp-c-collapse--faq > summary {
  padding-bottom: var(--gap-sm);
  font-size: 1.3rem;
  border-bottom: 1px solid var(--color-green-light-20);
  padding-right: var(--gap-ml); }
  .vsp-c-collapse--faq > summary::after {
    position: absolute;
    top: 0.7rem;
    right: 0; }

.vsp-c-tick-list {
  margin-bottom: var(--gap-lg); }
  .vsp-c-tick-list > li {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    padding-left: 1.64rem;
    margin-left: -1.64rem;
    font-size: var(--font-size-sm);
    text-align: left;
    color: var(--color-green-dark); }
    .vsp-c-tick-list > li:before {
      display: inline-block;
      width: 1.14rem;
      height: 1.14rem;
      margin-right: var(--gap-sm);
      background-size: cover;
      background: url("/img/icons/tick.svg") center no-repeat;
      content: ''; }
    .vsp-c-tick-list > li:not(:last-child) {
      margin-bottom: var(--gap-sm); }

.vsp-c-enterpirse-list {
  margin-bottom: var(--gap-md); }
  .vsp-c-enterpirse-list > li {
    color: var(--color-green-dark);
    font-size: var(--font-size-sm);
    text-align: center; }
    .vsp-c-enterpirse-list > li:not(:last-child) {
      margin-bottom: var(--gap-md); }

.vsp-c-grid {
  display: -ms-flexbox;
  display: flex; }
  @supports (display: grid) {
    .vsp-c-grid {
      display: grid; } }

.vsp-c-grid--gap {
  grid-gap: var(--gap-xl); }

.vsp-c-pricing__custumers {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap-lg);
  max-width: 150rem;
  margin: auto;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 5%; }
  @media (min-width: 1280px) {
    .vsp-c-pricing__custumers {
      margin: 0; } }
  @media (min-width: 1024px) {
    .vsp-c-pricing__custumers {
      grid-template-columns: 1fr 1.5fr; } }

.vsp-c-pricing__custumers-text {
  text-align: center; }
  @media (min-width: 1024px) {
    .vsp-c-pricing__custumers-text {
      text-align: left; } }

.vsp-c-pricing__custumers-logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -ms-flex-align: start;
  align-items: start;
  justify-items: center; }
  @media (min-width: 480px) {
    .vsp-c-pricing__custumers-logos {
      grid-template-columns: repeat(3, 1fr); } }
  .vsp-c-pricing__custumers-logos img {
    display: inline-block;
    width: 100%;
    max-width: 100%; }
    @media (min-width: 480px) {
      .vsp-c-pricing__custumers-logos img {
        width: 50%; } }

.vsp-c-grid--thirds {
  grid-template-columns: 1fr; }
  @media (min-width: 1024px) {
    .vsp-c-grid--thirds {
      grid-template-columns: 1fr 1fr 1fr; } }

@media (min-width: 1024px) {
  .vsp-c-grid--sidebar-content {
    grid-template-columns: 1fr 3fr; } }

.vsp-c-grid--content-sidebar {
  margin-bottom: var(--gap-xl); }
  @media (max-width: 1280px) {
    .vsp-c-grid--content-sidebar {
      margin-left: 5%;
      margin-right: 5%; } }
  @media (min-width: 1024px) {
    .vsp-c-grid--content-sidebar {
      margin-bottom: 0;
      grid-template-columns: 3fr 2fr; } }

.vsp-c-section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-brand-green);
  margin-bottom: 1em; }

.vsp-c-section-title--smaller {
  font-size: 2.25rem; }

.vsp-c-section-title--light {
  font-weight: 300; }

.vsp-c-section-subtitle {
  font-size: 1.7rem;
  font-weight: 300;
  color: var(--color-brand-green);
  margin-bottom: 1em; }

.vsp-c-section-subtitle--smaller {
  font-size: 1.6rem; }

.vsp-u-container-center-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }

.vsp-c-switch-pricing-plan-list {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -ms-flex-pack: center;
  justify-content: center;
  height: 44px;
  width: 200px;
  border-radius: 22px;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--color-white); }

.vsp-c-switch-pricing-plan-option {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 44px;
  width: 100px;
  transition: all .2s ease-in-out; }
  .vsp-c-switch-pricing-plan-option.checked {
    background-color: var(--color-green-lighter-than-lightest); }

.vsp-c-switch-pricing-plan-option > p {
  color: var(--color-brand-green);
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: center;
  padding: 8px 16px; }

.vsp-c-switch-pricing-plan-list *:hover {
  cursor: pointer; }

.vsp-c-section {
  margin-bottom: var(--gap-xl); }

.vsp-c-landing-pricing,
.vsp-c-testimonials {
  position: relative;
  padding: var(--gap-xl) 0;
  background: none; }
  .vsp-c-landing-pricing:before,
  .vsp-c-testimonials:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url("/img/pricing-grey-bg.png");
    background-color: var(--color-white-dirty);
    background-repeat: no-repeat;
    background-size: cover;
    content: ''; }

.vsp-c-review-apps {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: var(--gap-lg) auto 0 auto; }
  .vsp-c-review-apps img {
    margin: 0 var(--gap-lg); }
    .vsp-c-review-apps img:first-child {
      margin-bottom: var(--gap); }

.vsp-c-landing-pricing--clipped {
  padding-bottom: 0; }
  .vsp-c-landing-pricing--clipped:before {
    height: 33rem; }
    @media (max-width: 1280px) {
      .vsp-c-landing-pricing--clipped:before {
        height: 100%;
        padding-bottom: 2rem; } }

.vsp-c-pricing-plan-list {
  display: -ms-flexbox;
  display: flex;
  margin: 0 5%;
  padding: 0;
  list-style-type: none; }
  @supports (display: grid) {
    .vsp-c-pricing-plan-list {
      display: grid;
      grid-template-columns: 1fr;
      -ms-flex-pack: center;
      justify-content: center;
      grid-gap: var(--gap); } }
  @media (min-width: 640px) {
    .vsp-c-pricing-plan-list {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 1024px) {
    .vsp-c-pricing-plan-list {
      grid-template-columns: repeat(3, 1fr); } }
  @media (min-width: 1280px) {
    .vsp-c-pricing-plan-list {
      grid-template-columns: repeat(6, 1fr);
      margin: 0; } }

.vsp-c-pricing-plan-list--monthly, .vsp-c-pricing-plan-list--annual {
  display: none;
  opacity: 0;
  animation: fade 1s; }
  .vsp-c-pricing-plan-list--monthly.is-active, .vsp-c-pricing-plan-list--annual.is-active {
    display: -ms-flexbox;
    display: flex;
    opacity: 1; }
    @supports (display: grid) {
      .vsp-c-pricing-plan-list--monthly.is-active, .vsp-c-pricing-plan-list--annual.is-active {
        display: grid;
        grid-template-columns: 1fr;
        -ms-flex-pack: center;
        justify-content: center;
        grid-gap: var(--gap-lg); } }
    @media (min-width: 640px) {
      .vsp-c-pricing-plan-list--monthly.is-active, .vsp-c-pricing-plan-list--annual.is-active {
        grid-template-columns: repeat(2, 1fr); } }
    @media (min-width: 1280px) {
      .vsp-c-pricing-plan-list--monthly.is-active, .vsp-c-pricing-plan-list--annual.is-active {
        grid-template-columns: repeat(4, 1fr);
        margin: 0; } }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.vsp-c-pricing-plan {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  min-height: 31rem;
  padding: var(--gap);
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 0 1rem var(--color-black-10);
  filter: drop-shadow(0 0 1rem var(--color-black-10));
  text-align: center;
  border: 2px solid transparent;
  transition: all .1s ease-out; }
  .vsp-c-pricing-plan.is-active {
    box-shadow: 0 0 1rem var(--color-black-30);
    border-color: var(--color-green-lighter); }
  .vsp-c-pricing-plan hr {
    width: 100%;
    margin: var(--gap-lg) 0;
    border: none;
    border-bottom: 1px solid var(--color-white-dirty); }
  .vsp-c-pricing-plan:hover {
    box-shadow: 0 0 1rem var(--color-black-30);
    border-color: var(--color-green-lighter);
    transform: scale(1.02); }
  .vsp-c-pricing-plan a:last-child {
    margin-top: auto; }

.vsp-c-pricing-plan__title {
  position: relative;
  margin-bottom: var(--gap-lg);
  color: var(--color-green-lightest);
  text-transform: uppercase;
  font-weight: 700; }
  .vsp-c-pricing-plan__title:after {
    position: absolute;
    bottom: var(--gap-negative);
    left: 50%;
    transform: translateX(-50%);
    width: 4.5rem;
    border-bottom: 1px solid var(--color-green-lightest);
    content: ''; }

.vsp-c-pricing-plan__old-price {
  margin-bottom: var(--gap);
  text-transform: uppercase;
  color: var(--color-green-dark); }
  .vsp-c-pricing-plan__old-price strike {
    display: block;
    color: var(--color-grey);
    font-size: 1.7rem; }

.vsp-c-pricing-plan__new-price {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  color: var(--color-green-dark); }
  .vsp-c-pricing-plan__new-price strong {
    display: block;
    color: var(--color-brand-green);
    font-size: 2.5rem; }
    .vsp-c-pricing-plan__new-price strong.is-medium {
      font-size: 1.95rem;
      text-transform: none; }
    .vsp-c-pricing-plan__new-price strong.is-small {
      font-size: 1.7rem; }

.vsp-c-pricing-plan__members {
  margin-bottom: var(--gap);
  color: var(--color-green-dark);
  max-width: 60%;
  font-size: 1rem; }

.vsp-c-pricing-plan__enterprise-description {
  color: var(--color-green-dark);
  font-size: 1.2rem;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto; }

.vsp-c-pricing-plan__trial-description {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  color: var(--color-green-dark);
  margin-top: 0.9rem; }

.vsp-c-sticky-pricing {
  position: fixed;
  top: 3.8rem;
  left: 0;
  z-index: 20;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100vw;
  list-style-type: none;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-white-dirty);
  box-shadow: 0 0 1rem var(--color-black-10); }
  @media (min-width: 1280px) {
    .vsp-c-sticky-pricing {
      top: 5rem; } }
  @media (min-width: 1024px) {
    .vsp-c-sticky-pricing.is-active {
      display: -ms-flexbox;
      display: flex; } }
  .vsp-c-sticky-pricing > li {
    -ms-flex: 1 0 20%;
    flex: 1 0 20%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--gap);
    border: 1px solid transparent; }
    .vsp-c-sticky-pricing > li:not(:last-child) {
      border-right-color: var(--color-white-dirty); }
    .vsp-c-sticky-pricing > li > div {
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-positive: 1;
      flex-grow: 1;
      text-align: center; }
      .vsp-c-sticky-pricing > li > div p:nth-child(2) {
        margin-top: 5px !important; }
    .vsp-c-sticky-pricing > li > p {
      -ms-flex-positive: 1;
      flex-grow: 1;
      text-align: center; }
    .vsp-c-sticky-pricing > li small {
      text-transform: uppercase; }

.vsp-c-pitch {
  margin-bottom: var(--gap-lg);
  padding: var(--gap-lg);
  color: var(--color-brand-green);
  border-radius: 16px;
  background-color: var(--color-green-light-20); }
  @media (max-width: 480px) {
    .vsp-c-pitch {
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: center;
      align-items: center; }
      .vsp-c-pitch .vsp-c-media__object {
        margin-bottom: var(--gap-lg);
        -webkit-margin-end: 0;
        margin-inline-end: 0; } }

.vsp-c-pitch__title {
  margin-bottom: var(--gap-sm);
  font-weight: normal; }
  .vsp-c-pitch__title strong {
    font-weight: 700; }

.vsp-c-media {
  display: -ms-flexbox;
  display: flex; }

.vsp-c-media__body p {
  margin-top: var(--gap-md);
  color: var(--color-green-dark);
  font-size: var(--font-size-md);
  line-height: 1.8rem; }

.vsp-c-media--sidebar {
  -ms-flex-align: start;
  align-items: flex-start; }

.vsp-c-media__object {
  -webkit-margin-end: var(--gap-lg);
  margin-inline-end: var(--gap-lg); }
  @media (min-width: 1024px) {
    .vsp-c-media__object img, .vsp-c-media__object svg {
      transform: translateY(0.1rem); } }

.vsp-c-pricing-text {
  color: var(--color-green-dark);
  font-size: var(--font-size-md);
  line-height: 1.8rem; }

.vsp-c-pricing-text--regular {
  font-size: var(--font-size-lg);
  line-height: 1.5;
  font-weight: 300; }

.vsp-c-pricing-text--lg {
  font-size: var(--font-size-lg);
  line-height: 2.2rem; }

.vsp-c-pricing-text--sidebar {
  font-size: var(--font-size-lg);
  line-height: 2rem;
  margin-bottom: var(--gap-sm); }

.vsp-c-pricing-text--brands {
  font-size: var(--font-size-lg);
  color: var(--color-green-dark);
  line-height: 2.2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  height: 100%; }

.vsp-c-pricing-comparison {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
  transition: all .1s ease-out; }
  @media (min-width: 1024px) {
    .vsp-c-pricing-comparison {
      -ms-flex-direction: row;
      flex-direction: row; } }

.vsp-c-pricing-comparison__vs {
  position: absolute;
  top: 34%;
  right: -5.7rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  color: var(--color-white);
  font-size: 3.5rem;
  border-radius: 50%;
  background-color: var(--color-green-light);
  box-shadow: 0 0 1rem var(--color-black-10);
  content: 'vs';
  z-index: 10; }
  @media (max-width: 1024px) {
    .vsp-c-pricing-comparison__vs {
      top: unset;
      right: 50%;
      bottom: -5.7rem;
      transform: translate(50%, 0); } }
  .vsp-c-pricing-comparison__vs span {
    color: var(--color-white);
    font-size: 3.5rem; }

.vsp-c-pricing-comparison__list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: 2.5rem;
  color: var(--color-brand-green); }
  .vsp-c-pricing-comparison__list li img, .vsp-c-pricing-comparison__list li svg {
    margin-right: var(--gap-sm); }
  .vsp-c-pricing-comparison__list li:not(:last-child) {
    margin-bottom: var(--gap-sm); }

.vsp-c-pricing-comparison__we,
.vsp-c-pricing-comparison__rest {
  margin: var(--gap-lg) 0;
  padding: var(--gap-lg);
  border-radius: 16px;
  border: 2px solid transparent;
  transition: all .1s ease-out; }
  .vsp-c-pricing-comparison__we:hover,
  .vsp-c-pricing-comparison__rest:hover {
    box-shadow: 0 0 1rem var(--color-black-30); }
  @media (min-width: 1024px) {
    .vsp-c-pricing-comparison__we,
    .vsp-c-pricing-comparison__rest {
      width: 30rem;
      margin: 0 var(--gap-lg); } }

.vsp-c-pricing-comparison__we {
  position: relative;
  background-color: var(--color-white);
  border-color: var(--color-green-light); }
  .vsp-c-pricing-comparison__we > p {
    margin: var(--gap) 0 var(--gap-ml); }
    .vsp-c-pricing-comparison__we > p strong {
      color: var(--color-green-light);
      font-size: var(--font-size-lg); }
  @media (min-width: 1024px) {
    .vsp-c-pricing-comparison__we > p,
    .vsp-c-pricing-comparison__we .vsp-c-tick-list {
      margin-left: 2.5rem; } }

.vsp-c-pricing-comparison__rest {
  background-color: var(--color-white-dirty); }
  .vsp-c-pricing-comparison__rest img, .vsp-c-pricing-comparison__rest svg {
    vertical-align: middle;
    margin-right: var(--gap); }
  @media (max-width: 480px) {
    .vsp-c-pricing-comparison__rest > ul > li {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start; } }
  .vsp-c-pricing-comparison__rest > ul > li:not(:last-child) {
    margin-bottom: var(--gap-ml); }
  .vsp-c-pricing-comparison__rest:hover {
    box-shadow: 0 0 1rem var(--color-black-30); }

.vsp-c-link--secondary {
  color: #FE6227; }
  .vsp-c-link--secondary:hover {
    color: #f14301; }

/* Glider styles START */
.glider-contain {
  max-width: 23rem;
  margin-bottom: var(--gap-xl); }
  @media (min-width: 540px) {
    .glider-contain {
      max-width: 46rem; } }
  @media (min-width: 800px) {
    .glider-contain {
      max-width: 75rem; } }

.glider {
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .glider::-webkit-scrollbar {
    display: none; }

.glider-navigation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: var(--gap) 5%; }
  .glider-navigation .disabled {
    border-color: var(--color-glider-grey); }

.glider-dots {
  margin: 0 var(--gap-ml); }
  @media (max-width: 540px) {
    .glider-dots {
      visibility: hidden;
      width: 2rem;
      height: 3rem; } }
  .glider-dots .active {
    background-color: var(--color-green-light); }

.glider-dot {
  width: 1.2rem;
  height: 1.2rem;
  margin: var(--gap-sm); }

.glider-arrow {
  border: solid var(--color-green-light);
  border-width: 0 0.3rem 0.3rem 0;
  display: inline-block;
  padding: var(--gap-sm); }

.glider-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.glider-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

/* Glider styles END */
.vsp-c-slider-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: var(--gap);
  padding: var(--gap-lg);
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 0 1rem var(--color-black-10);
  color: var(--color-green-dark); }
  .vsp-c-slider-card > img {
    width: 4.5rem;
    height: 4.5rem;
    margin-bottom: var(--gap-lg);
    margin-top: var(--gap-md);
    object-fit: cover;
    border-radius: 50%; }
  .vsp-c-slider-card p {
    text-align: center; }

.vsp-c-slider-card__author {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: auto;
  color: var(--color-green-light); }
  .vsp-c-slider-card__author strong {
    text-transform: uppercase;
    color: var(--color-brand-green); }
  .vsp-c-slider-card__author::before {
    display: block;
    width: 2.3rem;
    margin-bottom: var(--gap);
    margin-top: var(--gap-lg);
    border-bottom: 1px solid var(--color-green-lightest);
    content: ''; }

.vsp-c-pricing--dropdown-group {
  max-width: 150rem;
  margin: 0 5%; }

.vsp-c-features .safari-fix-wrapper {
  margin-right: var(--gap-lg); }

.vsp-c-features summary::after {
  position: absolute;
  top: var(--gap-ml);
  right: 0; }

.vsp-c-features .vsp-c-icon-box {
  margin-right: var(--gap); }

.vsp-c-features .vsp-c-media__title {
  font-size: 1.3rem;
  font-weight: 700; }

.vsp-c-customers {
  padding: var(--gap-xl) 0;
  background-color: var(--color-white-dirty); }

.vsp-c-icon-box {
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: var(--gap-sm);
  border-radius: 8px;
  background-color: var(--color-green-light-20); }

.vsp-c-pricing .vsp-c-pricing__image--money-guarantee {
  width: 10rem;
  height: 10rem; }

.vsp-c-pricing .vsp-c-pricing__image--logo-black {
  width: 13rem;
  height: 3rem; }

.vsp-c-pricing .vsp-c-pricing__feature-icon {
  width: 2rem;
  height: 2rem; }

.vsp-c-pricing-text-default {
  font-size: 14px;
  color: #7a7a7a; }

.vsp-c-pricing-text-default--font-small {
  font-size: 0.75rem; }

.vsp-c-pricing__input {
  background-color: #EEEEEE;
  padding: 0 1rem;
  border: none;
  color: #548674;
  border-radius: 5px; }
  .vsp-c-pricing__input:focus {
    outline: none; }

.vsp-c-pricing-input--small {
  width: 16rem;
  height: 2.5rem; }
  @media (max-width: 480px) {
    .vsp-c-pricing-input--small {
      width: 90%; } }

.vsp-c-pricing-input--big {
  width: 34rem;
  max-width: 100%;
  height: 10rem;
  resize: none;
  padding: 1rem; }

.vsp-c-pricing-main__heading {
  color: #548674;
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  text-align: center; }
  @media (max-width: 480px) {
    .vsp-c-pricing-main__heading {
      font-size: 2rem; } }

.vsp-c-white-label {
  font-family: "Open Sans", sans-serif; }

.vsp-c-white-label__section-wrapper section:first-child {
  max-width: 91.5rem;
  margin-right: auto;
  margin-left: auto; }

.vsp-c-white-label__section-wrapper--hero {
  position: relative;
  padding-top: 4.7rem;
  padding-bottom: 5.6rem;
  background-color: #ffffff;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  z-index: 1; }

.vsp-c-white-label__section-wrapper--features {
  padding-top: 4rem;
  padding-bottom: 5.7rem;
  background-color: #FBFBFB; }

.vsp-c-white-label__section-wrapper--faq {
  padding-top: 4rem;
  padding-bottom: 5.7rem;
  background-color: #ffffff; }

.vsp-c-white-label__section-wrapper-partner {
  padding-top: 2.8rem;
  padding-bottom: 5.1rem;
  background-color: #FBFBFB; }
  .vsp-c-white-label__section-wrapper-partner section:first-child {
    max-width: 84rem;
    margin-right: auto;
    margin-left: auto; }

.vsp-c-white-label__heading {
  color: #168d74;
  font-weight: 700;
  font-size: 2rem; }
  @media (min-width: 1024px) {
    .vsp-c-white-label__heading {
      font-size: 2.8571rem; } }

.vsp-c-white-label__heading--left {
  text-align: left; }

.vsp-c-white-label__heading--center {
  text-align: center; }

.vsp-c-white-label__text {
  font-size: 1.1428rem;
  color: #438873;
  margin-bottom: 1.8rem; }

.vsp-c-white-label__text--bold {
  font-weight: 700; }

.vsp-c-white-label__text--center {
  text-align: center; }

.vsp-c-white-label__button {
  padding: 1.45rem 2.7rem 1.35rem;
  background-color: #FF5622;
  color: #ffffff;
  font-size: 1.2857rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 3rem;
  outline: none;
  transform: scale(1);
  transition: background-color 100ms ease-in;
  cursor: pointer; }
  .vsp-c-white-label__button:hover {
    background-color: #fa754c; }

/* Hero section START ---------------------------------------- */
.vsp-c-white-label-hero {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 2rem; }
  @media (min-width: 1024px) {
    .vsp-c-white-label-hero {
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-align: initial;
      align-items: initial;
      padding: 0; } }

.vsp-c-white-label-hero__content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 40rem; }
  @media (min-width: 1024px) {
    .vsp-c-white-label-hero__content {
      max-width: 34rem;
      margin-top: 1.3rem;
      margin-right: 1.1rem;
      margin-left: 10rem;
      -ms-flex: 2 1 auto;
      flex: 2 1 auto; } }
  .vsp-c-white-label-hero__content button:last-child {
    -ms-flex-item-align: center;
    align-self: center; }
    @media (min-width: 1024px) {
      .vsp-c-white-label-hero__content button:last-child {
        -ms-flex-item-align: start;
        align-self: flex-start; } }

.vsp-c-white-label-hero__text {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.vsp-c-white-label-hero__animation {
  max-width: 42rem;
  max-height: 42rem;
  -ms-flex-order: -1;
  order: -1; }
  @media (min-width: 1024px) {
    .vsp-c-white-label-hero__animation {
      margin-right: 3.4rem;
      margin-bottom: 7.6rem;
      -ms-flex: 2 1 auto;
      flex: 2 1 auto;
      -ms-flex-order: initial;
      order: initial; } }
  .vsp-c-white-label-hero__animation img {
    max-width: 100%;
    max-height: 100%; }

/* Hero section END ---------------------------------------- */
/* Features section START ---------------------------------------- */
.vsp-c-white-label-features {
  padding: 0 2rem; }
  @media (min-width: 1024px) {
    .vsp-c-white-label-features {
      padding: 0; } }

.vsp-c-white-label-features__cards {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 2.8rem;
  margin-bottom: 4rem; }

.vsp-c-white-label-features__card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 15rem;
  margin: 2.8rem 2.6rem; }
  .vsp-c-white-label-features__card svg, .vsp-c-white-label-features__card img {
    width: 5.6rem;
    height: 5.6rem;
    margin-bottom: 3.4rem;
    -ms-flex-item-align: center;
    align-self: center;
    color: #ff4300; }
  .vsp-c-white-label-features__card h3 {
    height: 4.8rem;
    font-size: 1.7143rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: #168d74;
    margin-bottom: 1rem; }
  .vsp-c-white-label-features__card p {
    font-size: 1rem;
    color: #000000; }

.vsp-c-white-label-features__subtitle {
  max-width: 57rem;
  margin: 1.7rem auto 7.4rem; }

.vsp-c-white-label-features__collage {
  max-width: 74.3rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  -ms-flex-align: center;
  align-items: center;
  row-gap: 5.7rem;
  column-gap: 1rem;
  grid-template-areas: 'image-1' 'text-1' 'image-2' 'text-2' 'image-3' 'text-3'; }
  @media (min-width: 768px) {
    .vsp-c-white-label-features__collage {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'text-1 image-1' 'image-2 text-2' 'text-3 image-3'; } }
  .vsp-c-white-label-features__collage article {
    max-width: 36rem; }
    @media (min-width: 768px) {
      .vsp-c-white-label-features__collage article {
        max-width: 28.6rem; } }
  .vsp-c-white-label-features__collage img {
    width: 100%;
    max-width: 40rem;
    transform: translateZ(0); }
  .vsp-c-white-label-features__collage article:nth-of-type(1) {
    grid-area: text-1; }
  .vsp-c-white-label-features__collage article:nth-of-type(2) {
    grid-area: text-2; }
  .vsp-c-white-label-features__collage article:nth-of-type(3) {
    grid-area: text-3; }
  .vsp-c-white-label-features__collage img:nth-of-type(1) {
    grid-area: image-1; }
  .vsp-c-white-label-features__collage img:nth-of-type(2) {
    grid-area: image-2; }
  .vsp-c-white-label-features__collage img:nth-of-type(3) {
    grid-area: image-3; }

/* Features section END ---------------------------------------- */
/* FAQ section START ---------------------------------------- */
.vsp-c-white-label-faq {
  padding: 0 2rem; }
  @media (min-width: 1024px) {
    .vsp-c-white-label-faq {
      padding: 0; } }

.vsp-c-white-label-faq__heading {
  font-size: 2rem;
  color: #418b75;
  text-align: center;
  font-weight: 400;
  margin-bottom: 4.7rem; }
  @media (min-width: 1024px) {
    .vsp-c-white-label-faq__heading {
      font-size: 2.5714rem; } }

.vsp-c-white-label-faq__list {
  max-width: 70.8rem;
  margin: auto; }

.vsp-c-white-label-faq__list-item {
  margin-bottom: 2.3rem; }
  .vsp-c-white-label-faq__list-item summary {
    position: relative;
    display: block;
    font-size: 1.2857rem;
    font-weight: 700;
    color: #418b75;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 1.7rem;
    padding-bottom: 0.5rem;
    padding-right: 1.6rem;
    cursor: pointer; }
    .vsp-c-white-label-faq__list-item summary::-webkit-details-marker {
      display: none; }
    .vsp-c-white-label-faq__list-item summary:focus {
      outline: none; }
    .vsp-c-white-label-faq__list-item summary::after {
      position: absolute;
      top: 9px;
      right: 0;
      content: '';
      border-width: 7px 8px;
      border-style: solid;
      border-color: #418b75 transparent transparent transparent; }
  .vsp-c-white-label-faq__list-item[open] summary::after {
    border-color: transparent transparent #418b75 transparent;
    top: 2px; }
  .vsp-c-white-label-faq__list-item p {
    font-size: 1.1428rem;
    color: rgba(0, 0, 0, 0.9); }

/* FAQ section END ---------------------------------------- */
/* Partner section START ---------------------------------------- */
.vsp-c-white-label-partner {
  padding: 0 2rem; }

.vsp-c-white-label-partner__title {
  font-weight: 300;
  font-size: 2rem;
  color: #63c0a8;
  margin-bottom: 2.8rem;
  text-align: left; }
  @media (min-width: 1024px) {
    .vsp-c-white-label-partner__title {
      font-size: 3.4286rem; } }

.vsp-c-white-label-partner__content {
  max-width: 80rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 4.5rem; }
  @media (min-width: 1024px) {
    .vsp-c-white-label-partner__content {
      grid-template-columns: minmax(auto, 46.7rem) 1fr;
      justify-items: stretch; } }

.vsp-c-white-label-partner__animation {
  display: none;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 4rem; }
  @media (min-width: 1024px) {
    .vsp-c-white-label-partner__animation {
      display: -ms-flexbox;
      display: flex; } }
  .vsp-c-white-label-partner__animation div:first-child {
    margin-right: 2rem; }

.vsp-c-white-label-partner__form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 45.7rem;
  width: 100%;
  background-color: #ffffff;
  padding: 2.5rem 2.8rem 2rem;
  border-radius: 16px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2); }

.vsp-c-white-label-partner__form-body {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.1rem;
  margin-bottom: 2.8rem; }
  @media (min-width: 768px) {
    .vsp-c-white-label-partner__form-body {
      grid-template-columns: 1fr 1fr;
      column-gap: 3.4rem; } }

.vsp-c-white-label-partner__form-title {
  font-weight: 400;
  font-size: 1.8rem;
  color: #418b75;
  margin-bottom: 2.4rem; }
  @media (min-width: 768px) {
    .vsp-c-white-label-partner__form-title {
      font-size: 2.2857rem; } }

.vsp-c-white-label-partner__input-group label {
  display: block;
  font-weight: 400;
  font-size: 1rem;
  color: #767676;
  margin-bottom: 0.6rem; }

.vsp-c-white-label-partner__input-group input, .vsp-c-white-label-partner__input-group select, .vsp-c-white-label-partner__input-group textarea {
  background-color: #F2F2F2;
  padding: 0.6rem 0.8rem;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #418b75;
  border-radius: 4px;
  width: 100%; }
  .vsp-c-white-label-partner__input-group input:focus, .vsp-c-white-label-partner__input-group select:focus, .vsp-c-white-label-partner__input-group textarea:focus {
    outline: none; }

.vsp-c-white-label-partner__input-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/img/svg/down-arrow-simple.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%; }

@media (min-width: 768px) {
  .vsp-c-white-label-partner__input-group:last-child {
    grid-column: 1 / span 2; } }

.vsp-c-white-label-partner__button {
  padding: 0.7rem 3.1rem;
  background-color: #63c0a8;
  color: #ffffff;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 21px;
  border: none;
  outline: none;
  cursor: pointer;
  -ms-flex-item-align: center;
  align-self: center;
  transition: background-color 100ms ease-in; }
  .vsp-c-white-label-partner__button:hover {
    background-color: #418b75; }
  @media (min-width: 768px) {
    .vsp-c-white-label-partner__button {
      -ms-flex-item-align: end;
      align-self: flex-end; } }

/* Partner section END ---------------------------------------- */
/* Confirmation modal section START ---------------------------------------- */
.vsp-c-white-label__modal--disabled {
  display: none !important; }

.vsp-c-white-label__modal-text {
  font-size: 1.7143rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: #168d74;
  max-width: 31rem;
  text-align: center;
  margin-top: 2rem; }

/* Confirmation modal section END ---------------------------------------- */
@media (max-width: 1024px) {
  .vsp-c-download {
    margin: 0 2rem; } }

.vsp-c-download h1, .vsp-c-download h2, .vsp-c-download h3 {
  font-weight: 300;
  color: #5dc1a6; }

.vsp-c-download h1, .vsp-c-download h2 {
  margin-bottom: 1.5rem; }

.vsp-c-download h1 {
  font-size: 3rem; }

.vsp-c-download h2 {
  font-size: 2rem; }

.vsp-c-download h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem; }

.vsp-c-download .vsp-c-download__content--title {
  font-size: 3rem; }

.vsp-c-download .vsp-c-download__content,
.vsp-c-download .vsp-c-download__platform-card,
.vsp-c-download .vsp-c-download__platform-card--selected,
.vsp-c-download .vsp-c-download__platform-cards {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }

.vsp-c-download .vsp-c-download__platform-cards-img {
  height: 100px; }

.vsp-c-download .vsp-c-download__content,
.vsp-c-download .vsp-c-download__platform-card,
.vsp-c-download .vsp-c-download__platform-card--selected {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  text-align: center; }

.vsp-c-download .vsp-c-download__platform-card,
.vsp-c-download .vsp-c-download__platform-card--selected {
  border-radius: 2rem;
  transition: all .1s ease-out;
  margin: 4rem;
  padding: 2rem 0; }

.vsp-c-download .vsp-c-download__content-timetracker-logo {
  margin-bottom: 2rem; }

.vsp-c-download .vsp-c-download__platform-card {
  cursor: pointer;
  width: 15rem; }
  .vsp-c-download .vsp-c-download__platform-card .vsp-c-download__platform-card--chrome {
    width: 8.5rem; }
  @media (max-width: 1024px) {
    .vsp-c-download .vsp-c-download__platform-card {
      width: 100%;
      max-height: 30vh;
      margin: 3rem; } }
  .vsp-c-download .vsp-c-download__platform-card .vsp-c-btn {
    width: 10rem;
    font-size: .8rem; }
  @media (min-width: 1025px) {
    .vsp-c-download .vsp-c-download__platform-card:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4); } }

.vsp-c-download .vsp-c-download__platform-card--selected {
  width: 18rem; }
  .vsp-c-download .vsp-c-download__platform-card--selected .vsp-c-btn {
    width: 11rem;
    font-size: 1rem; }
  .vsp-c-download .vsp-c-download__platform-card--selected img {
    width: 80%;
    max-height: 20rem; }
  @media (max-width: 1024px) {
    .vsp-c-download .vsp-c-download__platform-card--selected {
      width: 100%;
      max-height: 40vh; } }

.vsp-c-download .vsp-c-download__platform-cards {
  -ms-flex-direction: row;
  flex-direction: row; }
  @media (max-width: 1023px) {
    .vsp-c-download .vsp-c-download__platform-cards {
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: center;
      align-items: center; } }

.pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 4rem 0; }
  .pagination li a, .pagination li span {
    display: block;
    padding: .5rem 1rem; }
  .pagination li:not(.pagination-arrow) {
    font-size: 1.2rem; }
    .pagination li:not(.pagination-arrow) a {
      color: #5dc1a6;
      border-bottom: 2px solid transparent; }
      .pagination li:not(.pagination-arrow) a:hover {
        background-color: #EEEEEE;
        border-bottom: 2px solid #5dc1a6; }
  .pagination li.active span {
    color: #548674;
    background-color: #EEEEEE;
    border-bottom: 2px solid #5dc1a6; }
  .pagination li.pagination-arrow a {
    color: #b1b3b5; }
    .pagination li.pagination-arrow a:hover {
      color: #5dc1a6; }
  .pagination li.pagination-arrow.disabled span {
    color: #b1b3b5;
    opacity: .3; }
  .pagination li.pagination-arrow.prev .vsp-c-icon {
    transform: rotate(180deg); }

.vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs,
.vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs {
  background-color: #FBFBFB;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 1rem;
  transition: all .2s ease-out; }
  @media (max-width: 768px) {
    .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs,
    .vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs {
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-direction: column;
      flex-direction: column; } }
  .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs nav,
  .vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs nav {
    display: -ms-flexbox;
    display: flex; }
    .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs nav a,
    .vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs nav a {
      color: #333333;
      padding: 1.5rem .35rem;
      margin: 0 1.5rem;
      border-bottom: 2px solid transparent;
      font-weight: 600;
      transition: all .1s ease-out; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs nav a:hover, .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs nav a.active,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs nav a:hover,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs nav a.active {
        border-bottom: 2px solid #5dc1a6; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs nav a.active,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs nav a.active {
        color: #548674; }
      @media (max-width: 768px) {
        .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs nav a,
        .vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs nav a {
          margin: 0 .5rem;
          font-size: .9rem; } }
  .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs.vsp-c-is-scrolled,
  .vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs.vsp-c-is-scrolled {
    top: 170px; }
    @media (max-width: 1279px) {
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__tabs.vsp-c-is-scrolled,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__tabs.vsp-c-is-scrolled {
        top: 133px; } }

.vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content,
.vsp-c-privacy-notice .vsp-c-terms-and-conditions__content {
  padding: 4rem; }
  .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content h1,
  .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content h1 {
    font-weight: 300;
    padding: 4rem 0;
    text-align: center; }
    @media (max-width: 1024px) {
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content h1,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content h1 {
        font-size: 3rem; } }
  .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content h2,
  .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content h2 {
    font-size: 2.5rem;
    font-weight: 900;
    color: #FE6227;
    margin: 4rem 0 2rem 0;
    text-transform: uppercase; }
  .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content h3,
  .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #7a7a7a;
    margin: 2rem 0 .5rem 0; }
  .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content p,
  .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content p {
    font-size: 14px; }
  .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table,
  .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table {
    position: relative; }
    .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table,
    .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table thead,
    .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table tbody,
    .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table,
    .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table thead,
    .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table tbody {
      border-collapse: collapse; }
    .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table,
    .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table {
      border: 1px solid #d0d0d0;
      width: 100%; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table thead,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table thead {
        line-height: 40px; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody tr,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody tr {
        border-top: 1px solid #d0d0d0;
        text-align: center; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td {
        padding: 10px 1rem; }
    @media (max-width: 1024px) {
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table,
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table thead,
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table tbody,
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table th,
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table td,
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table tr,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table thead,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table tbody,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table th,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table td,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table tr {
        display: block; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table,
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table td,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table td {
        border: none; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table thead tr,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody tr,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody tr {
        border: 1px solid #d0d0d0;
        margin-bottom: 2.5rem; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td {
        border-bottom: 1px solid #d0d0d0;
        position: relative;
        padding: 10px 10px 25px 50%; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:before,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:before {
        position: absolute;
        top: 10px;
        left: 1rem;
        width: 45%;
        text-transform: uppercase;
        color: #548674; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(1):before,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(1):before {
        content: "Record name"; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(2):before,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(2):before {
        content: "Storage location"; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(3):before,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(3):before {
        content: "Person responsible for storage"; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(4):before,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(4):before {
        content: "Controls for record protection"; }
      .vsp-c-terms-and-conditions .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(5):before,
      .vsp-c-privacy-notice .vsp-c-terms-and-conditions__content .vsp-c-terms-and-conditions__table table tbody td:nth-of-type(5):before {
        content: "Retention time"; } }

.vsp-c-terms-and-conditions .vsp-c-privacy-notice,
.vsp-c-privacy-notice .vsp-c-privacy-notice {
  padding: 4rem; }

.vsp-c-data-subject-form,
.vsp-c-data-subject-access-request,
.vsp-c-data-subject-withdrawal-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  min-height: 50vh;
  padding: 4rem; }
  .vsp-c-data-subject-form h1,
  .vsp-c-data-subject-access-request h1,
  .vsp-c-data-subject-withdrawal-form h1 {
    font-size: 4rem;
    font-weight: 300; }
    @media (max-width: 1024px) {
      .vsp-c-data-subject-form h1,
      .vsp-c-data-subject-access-request h1,
      .vsp-c-data-subject-withdrawal-form h1 {
        font-size: 2.5rem; } }

.vsp-c-data-subject-access-request h1,
.vsp-c-data-subject-form h1 {
  padding-top: 4rem; }

.vsp-c-data-subject-access-request .vsp-c-btn,
.vsp-c-data-subject-form .vsp-c-btn {
  width: 17rem;
  -ms-flex-item-align: center;
  align-self: center;
  margin-top: 20vh; }
  @media (max-width: 1024px) {
    .vsp-c-data-subject-access-request .vsp-c-btn,
    .vsp-c-data-subject-form .vsp-c-btn {
      margin-top: 15vh; } }

.vsp-c-data-subject-access-request {
  min-height: 70vh; }

.vsp-c-data-subject-withdrawal-form {
  -ms-flex-align: center;
  align-items: center;
  padding: 0 3rem; }
  .vsp-c-data-subject-withdrawal-form h1 {
    padding: 4rem 0; }
  .vsp-c-data-subject-withdrawal-form .vsp-c-data-subject-withdrawal-form__content {
    text-align: left;
    max-width: 60rem; }
    .vsp-c-data-subject-withdrawal-form .vsp-c-data-subject-withdrawal-form__content p {
      padding: 1.4rem 0; }
  .vsp-c-data-subject-withdrawal-form input:first-child {
    font-size: 1rem;
    border: 1px solid #EEEEEE;
    outline: none;
    padding: .7rem 1.2rem;
    border-radius: 2rem;
    color: #333333;
    background-color: #ffffff;
    width: 13rem; }
    .vsp-c-data-subject-withdrawal-form input:first-child:-ms-input-placeholder {
      color: #b1b3b5;
      font-weight: 300; }
    .vsp-c-data-subject-withdrawal-form input:first-child::placeholder {
      color: #b1b3b5;
      font-weight: 300; }
  .vsp-c-data-subject-withdrawal-form .vsp-c-btn {
    margin-bottom: 5rem;
    margin-top: 1rem;
    line-height: 1;
    font-size: 1rem;
    width: 9rem;
    border: none; }

.vsp-c-error-page {
  width: 100vw;
  height: 100vh;
  background-color: #112060;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden; }

.vsp-c-error-page__image-wrapper {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 3rem; }

.vsp-c-error-page__image {
  width: 80%; }
  @media (max-width: 480px) {
    .vsp-c-error-page__image {
      position: absolute;
      left: -100px;
      width: 120%; } }
  @media (max-width: 350px) {
    .vsp-c-error-page__image {
      position: absolute;
      left: -60px;
      width: 130%; } }

.vsp-c-error-page__text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-positive: 3;
  flex-grow: 3; }

.vsp-c-error-page__title-main {
  font-size: 17vw;
  color: #273b90; }

.vsp-c-error-page__content {
  border-left: 1vw solid #273b90;
  padding-left: 2vw;
  line-height: 1.4;
  color: #475dbc; }

.vsp-c-error-page__title-top {
  font-size: 3vw;
  white-space: nowrap; }

.vsp-c-error-page__title-bottom,
.vsp-c-error-page__title-middle {
  font-size: 1.9vw;
  white-space: nowrap; }

@media (max-width: 768px) {
  .vsp-c-error-page__text {
    -ms-flex-positive: 2;
    flex-grow: 2; } }

.vsp-c-error-page__title-bottom {
  opacity: 0.4; }

.vsp-c-error-page__text-wrapper {
  width: 50%;
  position: absolute;
  top: 20%;
  left: 43%; }

@-moz-document url-prefix() {
  .vsp-c-error-page__text-wrapper {
    top: 25%;
    left: 49%; } }

.vsp-c-error-page-bike {
  background-color: #3e9a82;
  background-image: url("/img/wood.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  color: #52c9aa; }
  .vsp-c-error-page-bike .vsp-c-error-page__image {
    width: 28%;
    margin-left: 12vw; }
    @media (max-width: 1024px) {
      .vsp-c-error-page-bike .vsp-c-error-page__image {
        width: 33%; } }
    @media (max-width: 530px) {
      .vsp-c-error-page-bike .vsp-c-error-page__image {
        width: 38%;
        position: static; } }
    @media (max-width: 400px) {
      .vsp-c-error-page-bike .vsp-c-error-page__image {
        width: 48%;
        position: static; } }
  .vsp-c-error-page-bike .vsp-c-error-page__title-main {
    color: #4eae93; }
  .vsp-c-error-page-bike .vsp-c-error-page__content {
    color: #52c9aa;
    border-color: #52c9aa; }

.vsp-c-error-page-car {
  background-color: #ffffff;
  color: #000000; }
  .vsp-c-error-page-car .vsp-c-error-page__image {
    width: 35%;
    margin-left: 12vw; }
    @media (max-width: 1024px) {
      .vsp-c-error-page-car .vsp-c-error-page__image {
        width: 40%; } }
    @media (max-width: 530px) {
      .vsp-c-error-page-car .vsp-c-error-page__image {
        width: 50%;
        position: static; } }
    @media (max-width: 410px) {
      .vsp-c-error-page-car .vsp-c-error-page__image {
        width: 60%;
        position: static; } }
  .vsp-c-error-page-car .vsp-c-error-page__title-main {
    color: #4eae93; }
  .vsp-c-error-page-car .vsp-c-error-page__content {
    color: #333333;
    border-color: #dfeae6; }

.vsp-c-sitemap .vsp-c-sitemap__container {
  padding: 4rem; }
  .vsp-c-sitemap .vsp-c-sitemap__container h1 {
    font-size: 2rem;
    color: #548674;
    margin-bottom: 3rem;
    font-weight: 600; }
  .vsp-c-sitemap .vsp-c-sitemap__container div {
    margin-bottom: 3rem; }
  .vsp-c-sitemap .vsp-c-sitemap__container .vsp-c-sitemap__section-title {
    color: #548674;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem; }
  .vsp-c-sitemap .vsp-c-sitemap__container .vsp-c-sitemap__feature-section {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .vsp-c-sitemap .vsp-c-sitemap__container .vsp-c-sitemap__section {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 20%;
    margin: 1rem 0; }
    .vsp-c-sitemap .vsp-c-sitemap__container .vsp-c-sitemap__section p {
      color: #5dc1a6;
      margin-bottom: 1rem; }
    .vsp-c-sitemap .vsp-c-sitemap__container .vsp-c-sitemap__section a {
      color: #333333;
      margin-bottom: 0.5rem; }
      .vsp-c-sitemap .vsp-c-sitemap__container .vsp-c-sitemap__section a, .vsp-c-sitemap .vsp-c-sitemap__container .vsp-c-sitemap__section a:visited {
        color: #333333; }

.vsp-c-scrum-jira__section {
  padding: 6rem 8rem; }
  @media (max-width: 1024px) {
    .vsp-c-scrum-jira__section {
      padding: 6rem 2rem; } }

.vsp-c-scrum-jira__section--dark {
  background-color: #FBFBFB; }

.vsp-c-scrum-jira__section-title {
  font-size: 3rem;
  color: #5dc1a6;
  font-weight: 300; }

.vsp-c-scrum-jira__section-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -ms-flex-align: center;
  align-items: center; }
  @media (max-width: 1024px) {
    .vsp-c-scrum-jira__section-content {
      -ms-flex-direction: column;
      flex-direction: column; } }

.vsp-c-scrum-jira__section-container--half {
  width: 40%; }
  @media (max-width: 1024px) {
    .vsp-c-scrum-jira__section-container--half {
      width: 100%; } }

.vsp-c-scrum-jira__section-box {
  border: 1px solid #5dc1a6;
  padding: 2.5rem 2rem;
  border-radius: .5rem; }

.vsp-c-scrum-jira-section__list-item {
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #5dc1a6; }
  .vsp-c-scrum-jira-section__list-item:before {
    content: '';
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    background-image: url("/img/logo.png");
    background-repeat: no-repeat;
    background-size: 1.5rem;
    margin-right: .5rem; }

.vsp-c-scrum-jira-table__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 2rem; }
  @media (max-width: 868px) {
    .vsp-c-scrum-jira-table__header {
      margin-bottom: 1rem; } }
  @media (max-width: 480px) {
    .vsp-c-scrum-jira-table__header {
      -ms-flex-pack: distribute;
      justify-content: space-around; } }

.vsp-c-scrum-jira-table__header-item {
  width: 30%;
  height: 4rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }

.vsp-c-scrum-jira-table__header-item--jira {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: 7px; }
  @media (max-width: 1174px) {
    .vsp-c-scrum-jira-table__header-item--jira {
      padding-bottom: 11px; } }
  @media (max-width: 868px) {
    .vsp-c-scrum-jira-table__header-item--jira {
      padding-bottom: 5px; } }

.vsp-c-scrum-jira-table__header-item-vs,
.vsp-c-scrum-jira-table__header-item-jira {
  background-repeat: no-repeat;
  background-size: contain;
  width: 70%; }
  @media (max-width: 968px) {
    .vsp-c-scrum-jira-table__header-item-vs,
    .vsp-c-scrum-jira-table__header-item-jira {
      width: 60%; } }

.vsp-c-scrum-jira-table__header-item-vs {
  background-image: url("/img/full-logo.png"); }

.vsp-c-scrum-jira-table__header-item-jira {
  background-image: url("/img/jira-logo.png");
  height: 2.2rem; }

@media (max-width: 868px) {
  .vsp-c-scrum-jira-table__header-item-vs {
    background-image: url("/img/logo.png");
    width: 3rem; }
  .vsp-c-scrum-jira-table__header-item-jira {
    background-image: url("/img/jira.png");
    height: 3.2rem;
    width: 2.8rem; }
  .vsp-c-scrum-jira-table__header-item-vs,
  .vsp-c-scrum-jira-table__header-item-jira {
    background-position: center; } }

.vsp-c-scrum-jira-table__img {
  width: 15rem; }

.vsp-c-scrum-jira-table__img--bottom-gap {
  margin-bottom: .65rem; }

.vsp-c-scrum-jira-table__row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem; }
  @media (max-width: 480px) {
    .vsp-c-scrum-jira-table__row {
      -ms-flex-direction: column;
      flex-direction: column;
      border-radius: 20px;
      margin-bottom: 0; }
      .vsp-c-scrum-jira-table__row .vsp-c-scrum-jira-table__row-item-main,
      .vsp-c-scrum-jira-table__row .vsp-c-scrum-jira-table__row-item-wrapper {
        width: 100%; }
      .vsp-c-scrum-jira-table__row .vsp-c-scrum-jira-table__row-item-main {
        -ms-flex-pack: center;
        justify-content: center; } }

.vsp-c-scrum-jira-table__row-item-main,
.vsp-c-scrum-jira-table__row-item-sec,
.vsp-c-scrum-jira-table__row-item-third {
  padding: 1rem 2rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 568px) {
    .vsp-c-scrum-jira-table__row-item-main > p,
    .vsp-c-scrum-jira-table__row-item-sec > p,
    .vsp-c-scrum-jira-table__row-item-third > p {
      font-size: 1rem; } }

.vsp-c-scrum-jira-table__row-item-main {
  width: 40%;
  background-color: #548674;
  color: #ffffff; }

.vsp-c-scrum-jira-table__row-item-wrapper {
  width: 60%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center; }

.vsp-c-scrum-jira-table__row-item-sec,
.vsp-c-scrum-jira-table__row-item-third {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex: 1;
  flex: 1; }
  .vsp-c-scrum-jira-table__row-item-sec > p,
  .vsp-c-scrum-jira-table__row-item-third > p {
    font-weight: bold;
    text-transform: uppercase; }

.vsp-c-scrum-jira-table__row-item-sec {
  color: #548674; }

.vsp-c-scrum-jira-table__row-item-third {
  color: #FE6227; }

@media (max-width: 480px) {
  .vsp-c-scrum-jira-table__line-wrapper {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center; }
    .vsp-c-scrum-jira-table__line-wrapper .vsp-c-scrum-jira-table__line {
      height: 2.25rem;
      width: 1px;
      background-color: #548674; } }

.vsp-c-scrum-jira-features .vsp-c-features__item:nth-of-type(odd) {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.vsp-c-scrum-jira-features .vsp-c-features__item:nth-of-type(even) {
  -ms-flex-direction: row;
  flex-direction: row; }

@media (max-width: 768px) {
  .vsp-c-scrum-jira-features .vsp-c-features__item:nth-of-type(odd),
  .vsp-c-scrum-jira-features .vsp-c-features__item:nth-of-type(even) {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; } }

.vsp-c-scrum-jira-features .vsp-c-features__item {
  -ms-flex-align: center;
  align-items: center; }

.vsp-c-scrum-jira-features .vsp-c-scrum-jira-features__heading {
  font-size: 2.5rem;
  font-weight: bold; }

.vsp-c-scrum-jira-features-container {
  padding-top: 8rem !important; }

.vsp-c-features .vsp-c-scrum-jira-features__item {
  margin-bottom: 8rem !important; }

.vsp-c-scrum-jira-features__img-calendar {
  width: 28rem; }

.vsp-c-scrum-jira-features__img-circle {
  width: 22rem;
  margin-left: 3rem; }

.vsp-c-scrum-jira-features__img-custom {
  width: 19rem; }

.vsp-c-scrum-jira-features__img-calendar,
.vsp-c-scrum-jira-features__img-custom {
  margin-right: 3rem; }

@media (max-width: 768px) {
  .vsp-c-scrum-jira-features__img-calendar,
  .vsp-c-scrum-jira-features__img-circle,
  .vsp-c-scrum-jira-features__img-custom {
    margin: 0;
    max-width: 90%; } }

.vsp-c-scrum-jira-conclusion__heading {
  font-size: 3rem;
  color: #5dc1a6; }

.vsp-c-scrum-jira-conclusion__text {
  color: #7a7a7a; }

.vsp-c-scrum-jira-table__paragraph {
  font-size: 1.5rem;
  color: #548674;
  text-align: center;
  font-weight: bold; }

.vsp-c-scrum-jira-features__paragraph-strong {
  color: #FE6227;
  font-weight: bold; }

.vsp-c-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 3000;
  box-shadow: 0 1px 10px 0 #000000; }

.vsp-c-modal-container {
  max-width: 90vw;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2rem 3rem;
  background-color: #ffffff;
  border-radius: .75rem;
  position: relative; }

.vsp-c-modal-container-appsumo {
  max-width: 90vw;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  position: relative; }
  .vsp-c-modal-container-appsumo .appsumo-poster {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100%; }

.vsp-c-modal__section {
  width: 100%;
  height: 100%; }

.vsp-c-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  margin: .75rem;
  cursor: pointer; }

.vsp-c-modal__close-img {
  width: 100%;
  height: 100%; }

.vsp-u-flex-column {
  -ms-flex-direction: column;
  flex-direction: column; }

.vsp-c-modal-btn {
  -ms-flex-item-align: center;
  align-self: center;
  border: none;
  font-size: 14px; }

/*# sourceMappingURL=home.css.map */

.vsp-c-icon {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
}

.vsp-c-icon--lg {
    width: 1.4rem;
    height: 1.4rem;
}

.vsp-c-icon--xl {
    width: 1.7rem;
    height: 1.7rem;
}


//
// Variables
// --------------------------------------------------


$gray-lighter:           lighten(#000, 93.5%); // #eee

// Fonts
// -------------------------

$vsp-font-primary: 'Open Sans', sans-serif;

// Fonts sizes
// ----------------------------------------------------

$font-size-14: 1rem;
$font-size-16: 1.1428rem;
$font-size-18: 1.2857rem;
$font-size-24: 1.7143rem;
$font-size-32: 2.2857rem;
$font-size-36: 2.5714rem;
$font-size-40: 2.8571rem;
$font-size-48: 3.4286rem;

// Brand colors
// -------------------------

$brand-primary:         #44c5e9;
$brand-success:         #5dc1a6;
$brand-warning:         #ee9353;
$brand-danger:          #ee6853;
$brand-info:            #5bc0de;

$vsp-color-white:           #ffffff;
$vsp-color-white-dark:      #FBFBFB;
$vsp-color-white-darker:    #f1f2f3;
$vsp-color-gray:            #b1b3b5;
$vsp-color-gray-light:      #d4d4d4;
$vsp-color-gray-lighter:    #EEEEEE;
$vsp-color-gray-dark:       #7a7a7a;
$vsp-color-gray-darker:     #484848;
$vsp-color-black:           #000000;
$vsp-color-primary-light:   #C6E2D9;
$vsp-color-primary:         #5dc1a6;
$vsp-color-primary-dark:    #548674;
$vsp-color-red:             #FE6227;
$vsp-color-base:            #333333;
$vsp-color-sidebar-bg:      #F6F6F6;

$vsp-fb-color:              #3b5998;
$vsp-tw-color:              #55ACEE;
$vsp-in-color:              #4875B4;


$font-size-base:          14px;

$line-height-base:        1.428571429; // 20/14
$line-height-computed:    floor($font-size-base * $line-height-base); // ~20px



// Iconography
// -------------------------

$icon-font-path:          "/fonts/bootstrap/";

$input-border-radius:            0px;


// Media queries breakpoints
// --------------------------------------------------


$screen-xs:                  480px;
$screen-sm:                  768px;
$screen-md:                  1024px;
$screen-lg:                  1280px;
$screen-xl:                  1400px;

// Grid system
// --------------------------------------------------


$grid-columns:              60;


// Pagination
// -------------------------

$pagination-color:                      $brand-success;
$pagination-active-bg:                 $brand-success;
$pagination-active-border:             $brand-success;


//new variable for vivify scrum
$brand-main-color: #5ebfa4;
$task-modal-default-background: #f2f2f3;


//dasboard cards variables
$dashboard-bg-light: #f1f1f3;
$dashboard-bg-dark: #e6e6e6;
$dashboard-header-font-color: #828284;
$dashboard-text-font-color: #1e1e1e;
$dashboard-border-color: #d0d0d0;

$create-new-board-hover: #6fc8b0;
$import-boards-hover: #56cbeb;

//variables for task counter:

$task-main-color: #d0d2d3;
$task-font-color: #b1b3b5;
$task-border-color: #d3d3d3;
$full-task-activities-bg: #f3f3f5;
$full-task-bulletin-border: #e3e3e3;
$full-task-activities-font-color: #acacae;
$full-task-properties-header-color: #808895;


//Variables for blog:

$blog-header-font-color: #1b1c20;
$blog-text-font-color: #828594;
$blog-border: 1px solid #e5e5e5;


//social icons colors:


$main-google-plus-color: #dd4b39;
$main-google-drive-green: #029D55;
$main-google-drive-green-hover: #038C4C;
$main-one-drive: #2c69d3;
$main-one-drive-hover: #2251a3;

$btn-warning-hover: #de894d;
$btn-info-hover: #31b0d5;

// Additional colors

$green-01: #168d74;
$green-02: #438873;
$green-03: #418b75;
$green-04: #63c0a8;
$red-01: #FF5622;
$red-02: #fa754c;
$red-03: #ff4300;
$grey-01: #767676;
$grey-02: #F2F2F2;

// Shadows

$shadow-01: 0 0 10px rgba(0, 0, 0, 0.3);

.vsp-c-landing {
    h1 {
        line-height: 1.3;
        font-weight: 700;
        font-size: 3rem;

        @media (orientation: landscape) and (max-width: $screen-md - 1) {
            font-size: 2.5rem;
        }
    }

    h2 {
        font-size: 2rem;
        font-weight: 300;
    }
}

.vsp-c-landing-desc__subtitle {
    font-size: 3rem !important;
}

.vsp-c-landing-intro-container {
    justify-content: space-between;
}

.vsp-c-landing-intro {
    position: relative;
    height: calc(100vh - 70px);
    background-image: url("/img/home/background/green-bg.png");
    background-size: cover;

    @media (max-width: $screen-lg - 1) {
        height: calc(100vh - 52px);
    }

    .vsp-l-container {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .vsp-c-landing-intro__desc {
        width: 40%;
        color: $vsp-color-white;
        z-index: 10;

        @media (max-width: $screen-md) {
            width: 70%
        }

        @media (max-width: $screen-xs) {
            width: 100%;
        }

        p {
            font-weight: 400;

            @media (orientation: landscape) and (max-width: $screen-md - 1) {
                font-size: 15px;
            }
        }
    }

    .vsp-c-landing-intro__animation {
        position: absolute;
        right: -10rem;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 1;

        @media (max-width: $screen-xl) {
            right: 0;
        }
    }
}

.vsp-c-landing-desc {
    padding-top: 3rem;
    background: url("/img/mac.png") 0 15rem no-repeat $vsp-color-white-dark;
    background-size: 40rem;

    @media (max-width: $screen-md) {
        background: $vsp-color-white-dark;
    }

    h2 {
        color: $vsp-color-primary;
        margin-bottom: 2.5rem;
        text-align: center;
    }

    .vsp-c-landing-desc__text {
        width: 70%;
        margin: 0 auto;
        text-align: center;

        @media (max-width: $screen-md) {
            width: 100%;
        }
    }

    .vs-c-landing-desc__content {
        display: flex;
        justify-content: flex-end;
        margin-top: 4rem;

        @media (max-width: $screen-md) {
            justify-content: flex-start;
        }

        ul {
            width: 45%;
            padding-bottom: 5rem;
            position: relative;
            z-index: 10;

            @media (max-width: $screen-md) {
                width: 100%;
            }

            &:before {
                content: "";
                border-left: 2px dashed #5dc1a6;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 2.8rem;
                z-index: 1;

                @media (max-width: $screen-md) {
                    left: 2.5rem;
                }
            }
        }

        li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 4rem;

            /* Fix for this svg not showing correctly. */
            &:nth-child(2) {

                svg {
                    width: 7rem;

                    @media (max-width: $screen-md) {
                        width: 5rem;
                    }
                }
            }

            img, svg {
                margin-right: 2rem;
                z-index: 10;
                width: 8rem;
                height: 6rem;

                @media (max-width: $screen-md) {
                    width: 5rem;
                    height: 4.5rem;
                    flex-shrink: 0;
                }
            }
        }
    }

    .vsp-c-landing-desc__features {
        h3 {
            text-transform: uppercase;
            color: $vsp-color-red;
            margin-bottom: 2rem;

            @media (max-width: $screen-md) {
                margin-bottom: 1rem;
            }
        }
    }
}

.vsp-c-landing-intro__image-container {
    width: 40%;
    padding: 3rem;

    @media (max-width: $screen-sm) {
        display: none;
    }

    @media (max-width: $screen-lg) {
        margin: 0 2rem;
    }
}

.vsp-c-landing-intro__image {
    width: 100%;
}

.vsp-c-landing-intro__desc-heading {
    @media (max-width: $screen-sm) {
        font-size: 2.5rem;
    }
}

.vsp-c-landing-desc__container {
    padding-bottom: 1rem;

    p {
        width: 60%;
        text-align: center;
        margin: 0 auto 2rem;
    }
}

.vsp-c-landing-desc--subtitle {
    background: none;
    padding: 3rem 3rem 0 3rem;
}

.vsp-c-landing-features {
    padding: 8rem;

    @media (max-width: $screen-sm) {
        padding: 3rem 1.5rem;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .vs-c-landing-features-card {
        width: 28%;
        padding: 4rem 2rem;
        border-radius: 1.5rem;
        position: relative;
        text-align: center;
        height: 500px;
        transition: all .2s ease-out;
        cursor: pointer;

        @media (max-width: $screen-md) {
            width: 45%;
            height: 450px;
            box-shadow: 0 0 10px 0 rgba($vsp-color-black, .3);
            margin-bottom: 2rem;
        }

        @media (max-width: $screen-sm - 1) {
            width: 80%;
        }

        @media (max-width: $screen-xs) {
            width: 95%;
        }

        &:hover {
            box-shadow: 0 0 20px 0 rgba($vsp-color-black, .4);

            a {
                opacity: 1;
            }
        }

        img, svg {
            width: 7rem;
            height: 7rem;
        }

        h3 {
            color: $vsp-color-primary-dark;
            font-size: 2rem;
            margin: 1.5rem 0 2.5rem;
            height: 3.5rem;
        }

        p {
            margin-bottom: 3rem;
        }

        a {
            opacity: 0;
            color: $vsp-color-red;
            text-transform: uppercase;
            position: absolute;
            bottom: 1rem;
            right: 2rem;
            transition: all .2s ease-out;

            @media (max-width: $screen-md) {
                opacity: 1;
            }
        }

        .vs-c-landing-features-card--download {
            position: absolute;
            bottom: 1rem;
            left: 2rem;
            text-align: left;

            small {
                padding: .6rem;
                color: $vsp-color-white;
                border-radius: 1rem;
            }
        }
    }
}

.vsp-c-landing-desc__section {
    display: flex;
    padding: 4rem;

    @media (max-width: $screen-md) {
        flex-direction: column;
        align-items: center;
    }
}

.vsp-c-landing-desc__section-image {
    width: 100%;

    @media (max-width: $screen-md) {
        width: 60%;
    }

    @media (max-width: $screen-sm) {
        width: 70%;
    }

    @media (max-width: $screen-xs) {
        width: 90%;
    }
}

.vsp-c-landing-desc__section-content {
    width: 60%;
    padding: 0 5% 3rem 10%;

    @media (max-width: $screen-lg) {
        min-height: 20rem;
    }

    @media (max-width: $screen-md) {
        text-align: center;
        width: 90%;
        padding: 0 0 3rem 0;
        min-height: 0;
    }

    h2 {
        font-weight: 700;
        margin-bottom: 2rem;
        color: $vsp-color-primary-dark;
        font-size: 2.5rem;
    }

    p {
        word-break: break-word;
    }

    @media (max-width: $screen-sm) {
        h2 {
            font-size: 2rem;
        }

        p {
            font-size: 1rem;
        }
    }
}

.vsp-c-landing-desc__section-image-first {
    padding-left: 15%;

    .vsp-c-landing-desc__section-content {
        padding-left: 5%;
    }

    @media (max-width: $screen-md) {
        padding-left: 4rem;
        flex-direction: column-reverse;

        .vsp-c-landing-desc__section-content {
            padding-left: 0;
        }
    }
}

.vsp-c-landing-desc__section-image-container {
    display: flex;
    align-items: center;
    width: 40%;
    padding: 0 3rem;

    @media (max-width: $screen-lg) {
        padding: 0 1rem;
    }

    @media (max-width: $screen-md) {
        width: 80%;
        justify-content: center;
    }
}

.vsp-c-landing {
    .vsp-c-landing-pricing__title {
        color: $vsp-color-primary-dark;
        font-size: 2.5rem;
        line-height: 1.3;
        font-weight: 700;
    }
}

.vsp-c-landing-pricing__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        color: $vsp-color-primary-dark;

        @media (max-width: $screen-sm) {
            font-size: 2rem;
        }
    }
}

.vsp-c-landing-pricing {
    background-image: url("/img/home/background/grey-bg.png");
    background-size: cover;
    padding: 3rem 0;

    .vsp-c-landing-pricing__intro {
        text-align: center;
    }

    .vsp-c-landing-pricing__plans {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;

        @media (max-width: $screen-md) {
            flex-direction: column;
            align-items: center;
        }
    }

    .vsp-c-landing-pricing__plans-item {
        width: 300px;
        max-width: 90%;
        border-radius: 1rem;
        box-shadow: 0 0 10px 0 rgba($vsp-color-black, .3);

        @media (max-width: $screen-md) {
            &:first-of-type,
            &:nth-of-type(2) {
                margin-bottom: 2rem;
            }
        }
    }

    .vsp-c-landing-pricing__plans-item-header,
    .vsp-c-landing-pricing__plans-item-header--enterprise {
        padding: 3rem 1rem 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        height: 17rem;
    }

    .vsp-c-landing-pricing__plans-item-header--enterprise {
        background-image: url("/img/home/pricing-enterprise.jpg");
        background-size: cover;
    }

    .vsp-c-landing-pricing__plans-item-header--customized {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .vsp-c-landing-pricing__plans-item-body {
        padding: 1rem;
        background-color: $vsp-color-white;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;

        .vsp-c-btn {
            margin-top: 1rem;
        }

        .vsp-c-btn-pricing--top-gap {
            margin-top: 2rem;
        }
    }

    .vsp-c-landing-pricing__plans-item-body--spacing {
        padding: 1rem 0;
    }

    .vsp-c-price {
        color: $vsp-color-white;
        padding-bottom: 1rem;
        text-transform: uppercase;
        font-weight: 400;

        span {
            color: darken($vsp-color-white, 15);
        }
    }

    .vsp-c-price--spacing-none {
        padding-bottom: 0;
    }

    .vsp-c-btn--white {
        &:hover {
            background-color: $vsp-color-white;
            box-shadow: 0 0 20px 0 rgba($vsp-color-black, .5);
        }
    }

    .vsp-c-landing-pricing__disclaimer {
        color: $vsp-color-white;
        text-align: center;
        margin-top: 1rem;
    }

    .vsp-c-landing-pricing__disclaimer--gap {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .vsp-c-landing-pricing__plans-item-options,
    .vsp-c-landing-pricing__plans-item-options--enterprise {
        display: flex;
        justify-content: space-between;

        p {
            font-size: .95rem;
        }
    }

    .vsp-c-landing-pricing__plans-item-options {

        &:not(:last-of-type) {
            margin-bottom: 1rem;
        }

        p {
            &:last-of-type {
                color: $vsp-color-primary;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }

    .vsp-c-landing-pricing__plans-item-options--enterprise {
        margin-bottom: 1rem;

        p {
            color: $vsp-color-primary-dark;
            font-weight: 600;
        }
    }
}

.vsp-c-landing-pricing__plans--gap-top {
    margin-top: 5rem;
}

.vsp-c-landing-pricing--gap-big {
    padding: 6rem 0;
}

.vsp-c-landing-pricing__plans-img-wrapper {
    height: 3.5rem;
    width: 3.5rem;
}

.vsp-c-landing-pricing__plans-img {
    width: 100%;
}

.vsp-c-landing-pricing__plans-item--centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    height: 22rem;
    background-color: $vsp-color-white;
    box-shadow: none !important;
    border: 3px solid transparent;

    &:hover {
        border-color: $vsp-color-primary;
        box-shadow: 0px 4px 19px rgba($vsp-color-black, 0.25);

        .vsp-c-landing-pricing__plans-heading,
        .vsp-c-landing-pricing__plans-description {
            color: $vsp-color-primary-dark;
        }

        .vsp-c-landing-pricing__plans-btn {
            background-color: $vsp-color-primary;
            box-shadow: 0px 2px 4px rgba($vsp-color-black, 0.25);
        }
    }
}

.vsp-c-landing-pricing__plans-item--premium, .vsp-c-landing-pricing__plans-item--enterprise {
    border-color: $vsp-color-primary;
    box-shadow: 0px 4px 19px rgba($vsp-color-black, 0.25);

    .vsp-c-landing-pricing__plans-heading,
    .vsp-c-landing-pricing__plans-description {
        color: $vsp-color-primary-dark;
    }

    .vsp-c-landing-pricing__plans-btn {
        background-color: $vsp-color-primary;
        box-shadow: 0px 2px 4px rgba($vsp-color-black, 0.25);
    }
}

.vsp-c-landing-pricing__plans-description-link {
    font-weight: bold;
}

.vsp-c-landing-pricing__plans-btn {
    width: 100%;
    font-size: .75rem;
    padding: .75rem;
    text-align: center;
    background-color: $vsp-color-primary-light;
}

.vsp-c-landing-pricing__plans-heading,
.vsp-c-landing-pricing__plans-description {
    color: $vsp-color-primary-light;
}

.vsp-c-landing-pricing__plans-heading {
    text-transform: uppercase;
}

.vsp-c-landing-pricing__plans-description {
    text-align: center;
    font-size: $font-size-base;
}

.vsp-c-landing-pricing__plans-description--gap-md {
    padding: .7rem;
}

.vsp-c-landing-pricing__intro--light {
    color: rgba($vsp-color-black, .87)
}

.vsp-c-landing-pricing--gap {
    padding: 5rem 0 3rem;
}

.vsp-c-btn--gap {
    margin-top: 15%;
}

.vsp-c-landing-customers {
    padding: 6rem 0;

    .vsp-c-landing-customers__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .vsp-c-landing-customers__text {
        display: flex;
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        @media (max-width: $screen-xs) {
            align-items: center;
        }

        h2 {
            margin-bottom: 2rem;
            text-align: center;
            color: $vsp-color-primary;
            margin-left: 13%;
            margin-top: 10%;

            @media (max-width: $screen-xs) {
                margin-left: 0;
                margin-top: 0;
            }
        }

        p {
            font-size: 1.25rem;
            margin-left: 13%;

            @media (max-width: $screen-xs) {
                margin-left: 0;
                text-align: center;
            }
        }
    }

    .vsp-c-landing-customers__companies {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(170px, 1fr) );


        .vsp-c-landing-customers__logo-container {
            margin: .5rem 0;
            text-align: center;
            padding: 0 17%;

            img {
                display: block;
                transition: all .2s ease-out;
                width: 100%;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.vsp-c-landing-conclusion {
    padding: 6rem 0;
    background-color: $vsp-color-white-dark;

    h2 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }

    p {
        font-size: 1.25rem;
        margin-bottom: 2rem;
    }

    .vsp-c-landing-conclusion__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .vsp-c-landing-conclusion__text {
        width: 30%;

        @media (max-width: $screen-md) {
            width: 40%;
        }

        @media (max-width: $screen-sm - 1) {
            width: 100%;
            margin-bottom: 2rem;
        }
    }

    .vsp-c-landing-conclusion__gif-container,
    .vsp-c-landing-conclusion__img-container {
        text-align: center;
        width: 30%;

        @media (max-width: $screen-sm - 1) {
            width: 50%;

            img {
                width: 50%;
            }
        }

        @media (max-width: $screen-xs) {
            img {
                width: 70%;
            }
        }
    }

    .vsp-c-landing-conclusion__gif-container {
        align-self: flex-end;
    }
}

.vsp-c-landing-conclusion__text--template {
    margin: 0 auto;
    text-align: center;
    width: 100% !important;

    h2 {
        font-weight: 700;
        color: $vsp-color-primary-dark;
        font-size: 2.5rem;
    }
}

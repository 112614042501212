:root {
  --color-brand-green: #418b75;
  --color-green-light: #59b89e;
  --color-green-lighter: #63c0a8;
  --color-green-lightest: #74d1b6;
  --color-green-lighter-than-lightest: #Def1ec;
  --color-green-light-20: rgba(116, 209, 182, 0.2);
  --color-green-dark: #20453a;
  --color-white: #ffffff;
  --color-grey: #c2c2c2;
  --color-glider-grey: #ccc;
  --color-white-dirty: #eeeeee;
  --color-black-10: rgba(0, 0, 0, 0.1);
  --color-black-30: rgba(0, 0, 0, 0.3);

  --font-size-sm: 0.85rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.2rem;

  --gap-negative: -0.36rem;
  --gap: 1rem;
  --gap-sm: 0.5rem;
  --gap-md: 0.75rem;
  --gap-ml: 1.5rem;
  --gap-lg: 2rem;
  --gap-xl: 4rem;
}

.vsp-u-container {
  width: calc(100% - var(--gap-lg));
  margin-right: var(--gap);
  margin-left: var(--gap);

  @media (min-width: 1024px) {
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 1280px) {
    width: 1180px;
  }
}

.vsp-u-color--primary-dark {
  color: var(--color-brand-green);
}

.vsp-c-icon {
  display: inline-block;
  vertical-align: middle;
}

.vsp-c-collapse {
  > summary {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: var(--gap);
    color: var(--color-brand-green);
    font-weight: 700;
    font-size: 1.7rem;
    cursor: pointer;

    .safari-fix-wrapper {
      display: flex;
      align-items: center;
    }

    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: auto;
      border-style: solid;
      border-width: 7px 7px 0 7px;
      border-color: var(--color-brand-green) transparent transparent transparent;
      content: '';
    }

    &::-webkit-details-marker {
      display: none;
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:focus {
      outline: none;
    }
  }

  &[open] {
    > summary {
      &::after {
        border-width: 0 7px 7px 7px;
        border-color: transparent transparent var(--color-brand-green) transparent;
      }
    }
  }
}

.vsp-c-collapse--faq {
  > summary {
    padding-bottom: var(--gap-sm);
    font-size: 1.3rem;
    border-bottom: 1px solid var(--color-green-light-20);
    padding-right: var(--gap-ml);

    &::after {
      position: absolute;
      top: 0.7rem;
      right: 0;
    }
  }
}

.vsp-c-tick-list {
  margin-bottom: var(--gap-lg);

  > li {
    position: relative;
    display: flex;
    padding-left: 1.64rem;
    margin-left: -1.64rem;
    font-size: var(--font-size-sm);
    text-align: left;
    color: var(--color-green-dark);

    &:before {
      display: inline-block;
      width: 1.14rem;
      height: 1.14rem;
      margin-right: var(--gap-sm);
      background-size: cover;
      background: url('/img/icons/tick.svg') center no-repeat;
      content: '';
    }

    &:not(:last-child) {
      margin-bottom: var(--gap-sm);
    }
  }
}

.vsp-c-enterpirse-list {
  margin-bottom: var(--gap-md);

  > li {
    color: var(--color-green-dark);
    font-size: var(--font-size-sm);
    text-align: center;

    &:not(:last-child) {
      margin-bottom: var(--gap-md);
    }
  }
}

.vsp-c-grid {
  display: flex;

  @supports (display: grid) {
    display: grid;
  }
}

.vsp-c-grid--gap {
  grid-gap: var(--gap-xl);
}

.vsp-c-pricing__custumers {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap-lg);
  max-width: 150rem;
  margin: auto;
  align-items: center;
  margin: 0 5%;

  @media (min-width: 1280px) {
    margin: 0;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1.5fr;
  }
}

.vsp-c-pricing__custumers-text {
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
  }  
}

.vsp-c-pricing__custumers-logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  justify-items: center;

  @media (min-width: 480px) {
    grid-template-columns: repeat(3, 1fr);
  }

  img {
    display: inline-block;
    width: 100%;
    max-width: 100%;

    @media (min-width: 480px) {
      width: 50%;
    }
  }
}

.vsp-c-grid--thirds {
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.vsp-c-grid--sidebar-content {
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 3fr;
  }
}

.vsp-c-grid--content-sidebar {
  margin-bottom: var(--gap-xl);

  @media (max-width: 1280px) {
    margin-left: 5%;
    margin-right: 5%;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
    grid-template-columns: 3fr 2fr;
  }
}

.vsp-c-section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-brand-green);
  margin-bottom: 1em;
}

.vsp-c-section-title--smaller {
  font-size: 2.25rem;
}

.vsp-c-section-title--light {
  font-weight: 300;
}

.vsp-c-section-subtitle {
  font-size: 1.7rem;
  font-weight: 300;
  color: var(--color-brand-green);
  margin-bottom: 1em;
}

.vsp-c-section-subtitle--smaller {
  font-size: 1.6rem;
}

.vsp-u-container-center-content {
  display: flex;
  justify-content: center;
}

.vsp-c-switch-pricing-plan-list {
  display: flex;
	overflow: hidden;
  justify-content: center;
  height: 44px;
  width: 200px;
  border-radius: 22px;
  box-shadow: 0 6px 15px 0 rgba(0,0,0,0.15);
  background-color: var(--color-white);
}

.vsp-c-switch-pricing-plan-option {
  display:flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100px;
  transition: all .2s ease-in-out;

  &.checked {
    background-color: var(--color-green-lighter-than-lightest);
  }
}

.vsp-c-switch-pricing-plan-option > p {
	color: var(--color-brand-green);
	font-size: 0.9rem;
  font-weight: 600;
	line-height: 1rem;
	text-align: center;
	padding: 8px 16px;
}

.vsp-c-switch-pricing-plan-list *:hover {
	cursor: pointer;
}

.vsp-c-section {
  margin-bottom: var(--gap-xl);
}

.vsp-c-landing-pricing,
.vsp-c-testimonials {
  position: relative;
  padding: var(--gap-xl) 0;
  background: none;

  &:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('/img/pricing-grey-bg.png');
    background-color: var(--color-white-dirty);
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
  }
}

.vsp-c-review-apps {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: var(--gap-lg) auto 0 auto;

  img {
    margin: 0 var(--gap-lg);

    &:first-child {
      margin-bottom: var(--gap);
    }
  }
}

.vsp-c-landing-pricing--clipped {
  padding-bottom: 0;

  &:before {
    height: 33rem;

    @media (max-width: 1280px) {
      height: 100%;
      padding-bottom: 2rem;
    }
  }
}

.vsp-c-pricing-plan-list {
  display: flex;
  margin: 0 5%;
  padding: 0;
  list-style-type: none;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-gap: var(--gap);
  }

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(6, 1fr);
    margin: 0;
  }
}

.vsp-c-pricing-plan-list--monthly, .vsp-c-pricing-plan-list--annual {
  display: none;
  opacity: 0;
  -webkit-animation: fade 1s;
  -moz-animation: fade 1s;
  -ms-animation: fade 1s;
  -o-animation: fade 1s;
  animation: fade 1s;

  &.is-active {
    display: flex;
    opacity: 1;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      grid-gap: var(--gap-lg);
    }

    @media (min-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
    }
  
    @media (min-width: 1280px) {
      grid-template-columns: repeat(4, 1fr);
      margin: 0;
    }
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@-moz-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@-ms-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@-o-keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes fade {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.vsp-c-pricing-plan {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 31rem;
  padding: var(--gap);
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 0 1rem var(--color-black-10);
  filter: drop-shadow(0 0 1rem var(--color-black-10));
  text-align: center;
  border: 2px solid transparent;
  transition: all .1s ease-out;

  &.is-active {
    box-shadow: 0 0 1rem var(--color-black-30);
    border-color: var(--color-green-lighter);
  }

  hr {
    width: 100%;
    margin: var(--gap-lg) 0;
    border: none;
    border-bottom: 1px solid var(--color-white-dirty);
  }

  &:hover {
    box-shadow: 0 0 1rem var(--color-black-30);
    border-color: var(--color-green-lighter);
    transform: scale(1.02);
  }

  a:last-child {
    margin-top: auto;
  }
}

.vsp-c-pricing-plan__title {
  position: relative;
  margin-bottom: var(--gap-lg);
  color: var(--color-green-lightest);
  text-transform: uppercase;
  font-weight: 700;

  &:after {
    position: absolute;
    bottom: var(--gap-negative);
    left: 50%;
    transform: translateX(-50%);
    width: 4.5rem;
    border-bottom: 1px solid var(--color-green-lightest);
    content: '';
  }
}

.vsp-c-pricing-plan__old-price {
  margin-bottom: var(--gap);
  text-transform: uppercase;
  color: var(--color-green-dark);

  strike {
    display: block;
    color: var(--color-grey);
    font-size: 1.7rem;
  }
}

.vsp-c-pricing-plan__new-price {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  color: var(--color-green-dark);

  strong {
    display: block;
    color: var(--color-brand-green);
    font-size: 2.5rem;

    &.is-medium {
      font-size: 1.95rem;
      text-transform: none;
    }

    &.is-small {
      font-size: 1.7rem;
    }
  }
}

.vsp-c-pricing-plan__members {
  margin-bottom: var(--gap);
  color: var(--color-green-dark);
  max-width: 60%;
  font-size: 1rem;
}

.vsp-c-pricing-plan__enterprise-description {
  color: var(--color-green-dark);
  font-size: 1.2rem;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.vsp-c-pricing-plan__trial-description {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  color: var(--color-green-dark);
  margin-top: 0.9rem;
}

.vsp-c-sticky-pricing {
  position: fixed;
  top: 3.8rem;
  left: 0;
  z-index: 20;
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  list-style-type: none;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-white-dirty);
  box-shadow: 0 0 1rem var(--color-black-10);
  
  @media (min-width: 1280px) {
    top: 5rem;
  }

  &.is-active {
    @media (min-width: 1024px) {
      display: flex;
    }
  }

  > li {
    flex: 1 0 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--gap);
    border: 1px solid transparent;

    &:not(:last-child) {
      border-right-color: var(--color-white-dirty);
    }

    > div {
      flex-direction: column;
      flex-grow: 1;
      text-align: center;

      p:nth-child(2) {
        margin-top: 5px !important;
      }
    }
    
    > p {
      flex-grow: 1;
      text-align: center;
    }

    small {
      text-transform: uppercase;
    }
  }
}

.vsp-c-pitch {
  margin-bottom: var(--gap-lg);
  padding: var(--gap-lg);
  color: var(--color-brand-green);
  border-radius: 16px;
  background-color: var(--color-green-light-20);

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;

    .vsp-c-media__object {
      margin-bottom: var(--gap-lg);
      margin-inline-end: 0;
    }
  }
}

.vsp-c-pitch__title {
  margin-bottom: var(--gap-sm);
  font-weight: normal;

  strong {
    font-weight: 700;
  }
}

.vsp-c-media {
  display: flex;
}

.vsp-c-media__body {

  p {
    margin-top: var(--gap-md);
    color: var(--color-green-dark);
    font-size: var(--font-size-md);
    line-height: 1.8rem;
  }
}

.vsp-c-media--sidebar {
  align-items: flex-start;
}

.vsp-c-media__object {
  margin-inline-end: var(--gap-lg);

  img, svg {

    @media (min-width: 1024px) {
      transform: translateY(0.1rem);
    }
  }
}

.vsp-c-pricing-text {
  color: var(--color-green-dark);
  font-size: var(--font-size-md);
  line-height: 1.8rem;
}

.vsp-c-pricing-text--regular {
  font-size: var(--font-size-lg);
  line-height: 1.5;
  font-weight: 300;
}

.vsp-c-pricing-text--lg {
  font-size: var(--font-size-lg);
  line-height: 2.2rem;
}

.vsp-c-pricing-text--sidebar {
  font-size: var(--font-size-lg);
  line-height: 2rem;
  margin-bottom: var(--gap-sm);
}

.vsp-c-pricing-text--brands {
  font-size: var(--font-size-lg);
  color: var(--color-green-dark);
  line-height: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.vsp-c-pricing-comparison {
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all .1s ease-out;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

.vsp-c-pricing-comparison__vs {
  position: absolute;
  top: 34%;
  right: -5.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  color: var(--color-white);
  font-size: 3.5rem;
  border-radius: 50%;
  background-color: var(--color-green-light);
  box-shadow: 0 0 1rem var(--color-black-10);
  content: 'vs';
  z-index: 10;
  
  @media (max-width: 1024px) {
    top: unset;
    right: 50%;
    bottom: -5.7rem;
    transform: translate(50%, 0);
  }

  span {
    color: var(--color-white);
    font-size: 3.5rem;
  }
}

.vsp-c-pricing-comparison__list {
  
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 2.5rem;
    color: var(--color-brand-green);

    img, svg {
      margin-right: var(--gap-sm);
    }

    &:not(:last-child) {
      margin-bottom: var(--gap-sm);
    }
  }
}

.vsp-c-pricing-comparison__we,
.vsp-c-pricing-comparison__rest {
  margin: var(--gap-lg) 0;
  padding: var(--gap-lg);
  border-radius: 16px;
  border: 2px solid transparent;
  transition: all .1s ease-out;

  &:hover {
    box-shadow: 0 0 1rem var(--color-black-30);
  }

  @media (min-width: 1024px) {
    width: 30rem;
    margin: 0 var(--gap-lg);
  }
}

.vsp-c-pricing-comparison__we {
  position: relative;
  background-color: var(--color-white);
  border-color: var(--color-green-light);

  > p {
    margin: var(--gap) 0 var(--gap-ml);

    strong {
      color: var(--color-green-light);
      font-size: var(--font-size-lg);
    }
  }

  > p,
  .vsp-c-tick-list {
    @media (min-width: 1024px) {
      margin-left: 2.5rem;
    }
  }
}

.vsp-c-pricing-comparison__rest {
  background-color: var(--color-white-dirty);

  img, svg {
    vertical-align: middle;
    margin-right: var(--gap);
  }

  > ul {
    > li {

      @media (max-width: 480px) {
        display: flex;
        align-items: flex-start;
      }
      
      &:not(:last-child) {
        margin-bottom: var(--gap-ml);
      }
    }
  }

  &:hover {
    box-shadow: 0 0 1rem var(--color-black-30);
  }
}

.vsp-c-link--secondary {
  color: $vsp-color-red;

  &:hover {
    color: darken($vsp-color-red, 10);
  }
}

/* Glider styles START */
.glider-contain {
  max-width: 23rem;
  margin-bottom: var(--gap-xl);

  @media (min-width: 540px) {
    max-width: 46rem;
  }

  @media (min-width: 800px) {
    max-width: 75rem;
  }
}

.glider {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.glider-navigation {
  @include flex-center;
  margin: var(--gap) 5%;

  .disabled {
    border-color: var(--color-glider-grey);
  }
}

.glider-dots {
  margin: 0 var(--gap-ml);

  @media (max-width: 540px) {
    visibility: hidden;
    width: 2rem;
    height: 3rem;
  }

  .active {
    background-color: var(--color-green-light);
  }
}

.glider-dot {
  width: 1.2rem;
  height: 1.2rem;
  margin: var(--gap-sm);
}

.glider-arrow {
  border: solid var(--color-green-light);
  border-width: 0 0.3rem 0.3rem 0;
  display: inline-block;
  padding: var(--gap-sm);
}

.glider-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.glider-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
/* Glider styles END */

.vsp-c-slider-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: var(--gap);
  padding: var(--gap-lg);
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 0 1rem var(--color-black-10);
  color: var(--color-green-dark);

  > img {
    width: 4.5rem;
    height: 4.5rem;
    margin-bottom: var(--gap-lg);
    margin-top: var(--gap-md);
    object-fit: cover;
    border-radius: 50%;
  }

  p {
    text-align: center;
  }
}

.vsp-c-slider-card__author {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: auto;
  color: var(--color-green-light);

  strong {
    text-transform: uppercase;
    color: var(--color-brand-green);
  }

  &::before {
    display: block;
    width: 2.3rem;
    margin-bottom: var(--gap);
    margin-top: var(--gap-lg);
    border-bottom: 1px solid var(--color-green-lightest);
    content: '';
  }
}


.vsp-c-pricing--dropdown-group {
  max-width: 150rem;
  margin: 0 5%;
}

.vsp-c-features {
    
  .safari-fix-wrapper {
    margin-right: var(--gap-lg);
  }

  summary::after {
    position: absolute;
    top: var(--gap-ml);
    right: 0;
  }

  .vsp-c-icon-box {
    margin-right: var(--gap);
  }

  .vsp-c-media__title {
    font-size: 1.3rem;
    font-weight: 700;
  }
}

.vsp-c-customers {
  padding: var(--gap-xl) 0;
  background-color: var(--color-white-dirty);
}

.vsp-c-icon-box {
  display: inline-flex;
  padding: var(--gap-sm);
  border-radius: 8px;
  background-color: var(--color-green-light-20);
}

.vsp-c-pricing {

  .vsp-c-pricing__image--money-guarantee {
    width: 10rem;
    height: 10rem;
  }

  .vsp-c-pricing__image--logo-black {
    width: 13rem;
    height: 3rem;
  }

  .vsp-c-pricing__feature-icon {
    width: 2rem;
    height: 2rem;
  }
}

// OLD STYLES

// .vsp-c-pricing {
//   overflow-x: hidden;
// }

// .vsp-c-pricing__header {
//   position: fixed;
//   width: 100%;
//   background: $vsp-color-gray-lighter url('/img/home/background/grey-bg.png');
//   box-shadow: 0 0 30px 0 rgba($vsp-color-black, 0.35);
//   z-index: 999;
//   top: 70px;
//   padding: 0.5rem 0;
//   transition: all 0.2s ease-out;
//   max-height: 300px;

//   @media (min-width: $screen-sm) {
//     &.vsp-c-is-scrolled {
//       top: 50px;
//       max-height: 130px;
//       overflow: hidden;
//     }
//   }

//   @media (max-width: $screen-lg) {
//     top: 50px;

//     &.vsp-c-is-scrolled {
//       top: 30px;
//     }
//   }

//   @media (max-width: $screen-md) {
//     top: 50px;

//     &.vsp-c-is-scrolled {
//       top: 18px;
//       max-height: 90px;
//     }
//   }

//   @media (max-width: $screen-sm) {
//     top: 40px;
//     max-height: 57px;

//     &.vsp-c-is-scrolled {
//       top: 40px;
//       max-height: 57px;
//     }
//   }
// }

// .vsp-c-pricing__header-intro {
//   text-align: center;
//   padding: 3rem 0;

//   h1 {
//     color: $vsp-color-primary-dark;
//     font-size: 2.5rem;
//     font-weight: 300;
//   }

//   @media (max-width: $screen-sm) {
//     padding: 1rem 0 0;

//     .vsp-c-pricing__header-heading {
//       font-size: 2rem;
//     }
//   }
// }

// .vsp-c-pricing__header-heading {
//   width: fit-content;
//   margin: 0 auto 3rem;
//   color: $vsp-color-primary-dark;
//   font-size: 3rem;
//   font-weight: normal;

//   @media (max-width: $screen-xs) {
//     margin: 0 auto 2rem;
//   }
// }

// .vsp-c-pricing-wrapper {
//   @media (max-width: $screen-xs) {
//     padding-top: 2rem;
//   }
// }

// .vsp-c-pricing__header-fixed {
//   display: grid;
//   grid-template-columns: 33.33% 33.33% 33.33%;

//   @media (max-width: $screen-sm) {
//     grid-template-columns: 35% 32.5% 32.5%;
//   }

//   .vsp-c-pricing__col {
//     &:first-of-type {
//       grid-column-start: 2;
//     }
//   }

//   .vsp-c-price {
//     p {
//       font-size: 2.5rem;
//       line-height: 1;

//       @media (max-width: $screen-sm) {
//         font-size: 2.1rem;
//       }

//       @media (max-width: $screen-xs) {
//         font-size: 1.4rem;
//       }
//     }

//     .vsp-c-price__action {
//       font-size: 1.5rem;
//       font-weight: 600;

//       @media (max-width: $screen-sm) {
//         font-size: 1rem;
//       }

//       @media (max-width: $screen-xs) {
//         font-size: 0.9rem;
//       }
//     }

//     div {
//       margin: 0 0 0 0.25rem;
//     }

//     span {
//       font-size: 0.8rem;

//       @media (max-width: $screen-sm) {
//         font-size: 0.6rem;
//         font-weight: bold;
//       }

//       @media (max-width: $screen-xs) {
//         display: none;
//       }
//     }
//   }

//   .vsp-c-btn--price {
//     width: 70%;

//     @media (max-width: $screen-md) {
//       width: 95%;
//     }

//     @media (max-width: $screen-xs) {
//       height: 2.5rem;
//     }

//     > div {
//       display: flex;
//       justify-content: flex-start;
//       align-items: center;
//     }
//   }
// }

// .vsp-c-pricing-nav-wrapper,
// .vsp-c-pricing-nav,
// .vsp-c-pricing-calculator,
// .vsp-c-pricing-price-btn-wrapper,
// .vsp-c-pricing-price-container,
// .vsp-c-pricing-main,
// .vsp-c-pricing-nav__tab,
// .vsp-c-pricing-calculator-container {
//   display: flex;
// }

// .vsp-c-pricing-calculator-container {
//   @media (max-width: $screen-xs) {
//     text-align: center;
//   }
// }

// .vsp-c-pricing-nav-wrapper,
// .vsp-c-pricing-nav__tab,
// .vsp-c-pricing-price-btn-wrapper {
//   justify-content: center;
//   align-items: center;
// }

// .vsp-c-pricing-nav-wrapper,
// .vsp-c-pricing-content-wrapper {
//   padding: 0 3.5rem;

//   @media (max-width: $screen-xs) {
//     padding: 0 1rem;
//   }
// }

// .vsp-c-pricing-nav {
//   background-color: $vsp-color-white;
//   border-radius: 0.75rem;
//   box-shadow: 0 3px 10px 0 rgba($vsp-color-black, 0.2);
//   overflow: hidden;
// }

// .vsp-c-pricing-nav__tab {
//   width: 15rem;
//   height: 3.5rem;
//   cursor: pointer;

//   &.vsp-is-active {
//     background-color: $vsp-color-primary-dark;

//     .vsp-c-pricing-nav__tab-text {
//       color: $vsp-color-white;
//       font-weight: bold;
//     }
//   }
// }

// .vsp-c-pricing-nav__tab-text {
//   font-size: 1.25rem;
//   text-transform: uppercase;
//   color: $brand-main-color;
// }

// .vsp-c-pricing-calculator {
//   background-color: $vsp-color-white;
//   border-radius: 1rem;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   padding: 3.5rem;
//   box-shadow: 0 3px 10px 0 rgba($vsp-color-black, 0.2);

//   @media (max-width: $screen-sm) {
//     flex-direction: column;
//   }
// }

// .vsp-c-pricing-calculator-container {
//   flex-direction: row;

//   @media (max-width: $screen-sm) {
//     flex-direction: column;
//   }
// }

// .vsp-c-pricing-content-section {
//   @media (max-width: $screen-sm) {
//     flex-direction: column;
//   }
// }

// .vsp-c-pricing-calculator__left {
//   margin-right: 10rem;

//   @media (max-width: $screen-md) {
//     margin-right: 5rem;
//   }

//   @media (max-width: $screen-xs) {
//     margin-right: 0;
//   }
// }

// .vsp-c-pricing-heading-secondary {
//   font-size: 1rem;
//   color: $vsp-color-primary-dark;
//   font-weight: bold;
// }

.vsp-c-pricing-text-default {
  font-size: $font-size-base;
  color: $vsp-color-gray-dark;
}

.vsp-c-pricing-text-default--font-small {
  font-size: 0.75rem;
}

.vsp-c-pricing__input {
  background-color: $vsp-color-gray-lighter;
  padding: 0 1rem;
  border: none;
  color: $vsp-color-primary-dark;
  border-radius: 5px;

  &:focus {
    outline: none;
  }
}

.vsp-c-pricing-input--small {
  width: 16rem;
  height: 2.5rem;

  @media (max-width: $screen-xs) {
    width: 90%;
  }
}

// .vsp-c-pricing-input--full {
//   @media (max-width: $screen-xs) {
//     width: 100%;
//   }
// }

.vsp-c-pricing-input--big {
  width: 34rem;
  max-width: 100%;
  height: 10rem;
  resize: none;
  padding: 1rem;
}

// .vsp-c-pricing-price-wrapper {
//   @media (max-width: $screen-sm) {
//     margin-top: 2rem;
//   }
// }

// .vsp-c-pricing-price {
//   color: $vsp-color-primary-dark;
//   font-size: 1.75rem;
//   font-weight: bold;
//   width: 15rem;
// }

// .vsp-c-pricing-price--gap {
//   margin-right: 2rem;
//   margin-top: 0.25rem;

//   @media (max-width: $screen-xs) {
//     margin-right: 0;
//   }
// }

// .vsp-c-pricing-price-container {
//   justify-content: space-between;

//   @media (max-width: $screen-xs) {
//     flex-direction: column;
//     align-items: center;
//   }
// }

// .vsp-c-pricing-price-btn-wrapper {
//   flex-direction: column;

//   @media (max-width: $screen-xs) {
//     margin-top: 1rem;
//     margin-left: 0;
//   }
// }

// .vsp-c-pricing-price-btn-wrapper--gap {
//   margin-bottom: 2rem;
// }

// .vsp-c-pricing-btn--small {
//   padding: 1rem 3rem;
// }

// .vsp-c-pricing--gap-right-5 {
//   margin-right: 5rem;

//   @media (max-width: $screen-sm) {
//     margin-right: 0;
//   }
// }

// .vsp-c-pricing-content {
//   background-color: $vsp-color-white;
//   box-shadow: 0 3px 10px 0 rgba($vsp-color-black, 0.2);
//   border-radius: 1rem;
//   max-width: 980px;
//   margin: 0 auto;
// }

// .vsp-c-pricing-content-wrapper {
//   .vsp-c-pricing-content--gap {
//     padding: 2.5rem 2rem 4rem;
//   }
// }

// .vsp-c-pricing-main {
//   border-radius: 1rem;
//   flex-direction: column;
//   align-items: center;
//   padding: 2.5rem 1rem 4rem;
// }

.vsp-c-pricing-main__heading {
  color: $vsp-color-primary-dark;
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: $screen-xs) {
    font-size: 2rem;
  }
}

// .vsp-c-pricing-heading-light {
//   font-size: 1.25rem;
//   color: $vsp-color-primary;
//   font-weight: bold;
// }

// .vsp-c-pricing-heading--max-width-37 {
//   max-width: 37rem;
// }

// .vsp-c-pricing-subheading {
//   max-width: 27rem;
//   margin: 0 auto;

//   @media (max-width: $screen-sm) {
//     text-align: left;
//     margin: 0;
//   }
// }

// .vsp-c-pricing-btn-centered {
//   margin: 2rem auto 0;
//   display: block;
//   width: fit-content;
// }

// .vsp-c-pricing-main__plans {
//   width: 33%;
//   min-width: fit-content;
// }

// .vsp-c-pricing-card__heading,
// .vsp-c-pricing-card__other-wrapper,
// .vsp-c-pricing-card__other-img-wrapper {
//   display: flex;
// }

// .vsp-c-pricing-card__heading,
// .vsp-c-pricing-card__other-img-wrapper {
//   align-items: center;
// }

// .vsp-c-pricing-card__heading {
//   color: $vsp-color-white;
//   text-transform: uppercase;
//   justify-content: center;

//   > p {
//     font-size: 2rem;
//     font-weight: bold;
//   }
// }

// .vsp-c-pricing-card__btn {
//   font-size: $font-size-base;
//   font-weight: 600;
//   box-shadow: 0px 2px 30px 0px;
// }

// .vsp-c-pricing-card__other-wrapper {
//   justify-content: space-between;
//   margin: 1rem 0 0;
//   padding: 0.5rem 1rem 0;
//   border-top: 1px solid $vsp-color-gray-lighter;
//   border-radius: 4px;
// }

// .vsp-c-pricing-card__other-text {
//   color: $vsp-color-gray-dark;
//   text-transform: uppercase;
//   font-weight: 600;
// }

// .vsp-c-pricing-card__other-img {
//   width: 1rem;
// }

// .vsp-c-pricing-card__other-img-wrapper {
//   transition: transform 0.25s ease-in;
// }

// @media (max-width: $screen-sm) {
//   .vsp-c-pricing__header-text {
//     display: none;
//   }
// }

// .vsp-c-landing-pricing__plans-item-list {
//   padding: 0 1rem;

//   .vsp-c-landing-pricing__plans-item-options {
//     > p {
//       display: flex;
//       align-items: center;
//     }

//     > p:first-child {
//       margin-right: 3rem;
//     }

//     > p:nth-child(2) {
//       text-align: right;
//     }
//   }
// }

// .vsp-c-landing-pricing__plans-item-list--second {
//   margin-top: 1rem;
//   max-height: 0;
//   transition: max-height 0.15s ease-out;
//   overflow: hidden;
// }

// .vsp-c-pricing__learn-more-btn {
//   margin: 2rem 1rem 0;
// }

// .vsp-c-pricing-card__other-wrapper,
// .vsp-c-pricing-card__other--dropdown-active {
//   box-shadow: 0 3px 10px 0 $vsp-color-gray-light;
//   padding-bottom: 0.5rem;
// }

// .vsp-c-pricing-card__other--dropdown-active {
//   .vsp-c-pricing-card__other-img-wrapper {
//     transform: rotateZ(180deg);
//     transition: transform 0.25s ease-in;
//   }
// }

// .vsp-c-landing-pricing__plans--dropdown-active {
//   max-height: 800px;
//   margin-top: 1rem;
//   transition: max-height 0.25s ease-in;
// }

// .vsp-c-pricing__body {
//   display: grid;
//   grid-template-columns: 33.33% 33.33% 33.33%;
//   position: relative;
//   padding-bottom: 3rem;
//   z-index: 20;

//   @media (min-width: $screen-lg) {
//     &.vsp-c-is-scrolled {
//       .vsp-c-pricing__col {
//         padding-top: 9.5rem;
//       }
//     }
//   }

//   @media (max-width: $screen-sm) {
//     grid-template-columns: 35% 32.5% 32.5%;
//     padding: 0 10px 1rem;
//   }

//   .vsp-c-pricing__col {
//     text-align: center;
//     font-size: 1rem;
//     padding: 23rem 0 1rem;
//     transition: all 0.2s ease-out;

//     @media (max-width: $screen-lg - 1) {
//       padding-top: 6rem;
//     }

//     @media (max-width: $screen-sm - 1) {
//       font-size: 0.8rem;
//     }

//     .not-active {
//       color: $vsp-color-gray-light;
//     }

//     li {
//       list-style-type: none;
//       padding: 0.5rem 0;
//     }

//     &.vsp-c-pricing__col-header {
//       text-align: right;
//       padding-right: 2rem;

//       @media (max-width: $screen-sm) {
//         padding-right: 0;
//       }
//     }

//     &:not(.vsp-c-pricing__col-header) {
//       text-transform: uppercase;
//       border-radius: 1.5rem;
//       margin: 0 5rem;
//       box-shadow: 0 0 20px 0 rgba($vsp-color-black, 0.25);
//       color: $vsp-color-primary;
//       transition: all 0.2s ease-out;

//       @media (max-width: $screen-sm - 1) {
//         box-shadow: none;
//       }

//       p {
//         font-weight: 600;
//       }

//       .vsp-c-separator {
//         &:after {
//           content: '';
//           position: absolute;
//           top: 50%;
//           width: 100%;
//           border-top: 2px dashed $vsp-color-primary;
//           display: block;
//           height: 25px;

//           @media (max-width: $screen-sm) {
//             display: none;
//           }
//         }
//       }

//       @media (max-width: $screen-lg) {
//         margin: 0 2.5rem;
//       }

//       @media (max-width: $screen-sm) {
//         margin: 0 0.5rem;
//       }
//     }

//     p {
//       display: block !important;
//       min-height: 15px;

//       @media (max-width: $screen-sm) {
//         font-size: 0.9rem;
//       }

//       @media (max-width: $screen-xs) {
//         font-size: 0.7rem;
//       }
//     }

//     .vsp-c-separator {
//       height: 25px;
//       position: relative;
//     }
//   }
// }

// .vsp-c-pricing-main__heading {
//   color: $vsp-color-primary-dark;
//   font-size: 2.5rem;
//   font-weight: 300;
//   margin-bottom: 1rem;
//   text-align: center;

//   @media (max-width: $screen-xs) {
//     font-size: 2rem;
//   }
// }

// .vsp-c-pricing-text-default {
//   font-size: $font-size-base;
//   color: $vsp-color-gray-dark;
// }

// .vsp-c-pricing__input {
//   background-color: $vsp-color-gray-lighter;
//   padding: 0 1rem;
//   border: none;
//   color: $vsp-color-primary-dark;
//   border-radius: 5px;

//   &:focus {
//     outline: none;
//   }
// }

// .vsp-c-pricing-input--big {
//   width: 34rem;
//   max-width: 100%;
//   height: 10rem;
//   resize: none;
//   padding: 1rem;
// }

// .vsp-c-pricing-input--small {
//   width: 16rem;
//   height: 2.5rem;

//   @media (max-width: $screen-xs) {
//     width: 90%;
//   }
// }

// .vsp-c-pricing--gap-top {
//   padding-top: 18rem;

//   @media (max-width: $screen-md) {
//     padding-top: 19rem;
//   }

//   @media (max-width: $screen-sm) {
//     padding-top: 5rem;
//   }
// }

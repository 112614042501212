.vsp-c-error-page {
    width: 100vw;
    height: 100vh;
    background-color: #112060;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
    
.vsp-c-error-page__image-wrapper {
    flex-grow: 1;
    padding: 3rem;
}

.vsp-c-error-page__image {
    width: 80%;

    @media (max-width: $screen-xs) {
        position: absolute;
        left: -100px;
        width: 120%;
    }

  @media (max-width: $screen-xs - 130) {
    position: absolute;
    left: -60px;
    width: 130%;
  }
}

.vsp-c-error-page__text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 3;
}

.vsp-c-error-page__title-main {
    font-size: 17vw;
    color: #273b90;
}

.vsp-c-error-page__content {
    border-left: 1vw solid #273b90;
    padding-left: 2vw;
    line-height: 1.4;
    color: #475dbc;
}

.vsp-c-error-page__title-top {
    font-size: 3vw;
    white-space: nowrap;
}

.vsp-c-error-page__title-bottom,
.vsp-c-error-page__title-middle {
    font-size: 1.9vw;
    white-space: nowrap;
}

@media (max-width: $screen-sm) {
    .vsp-c-error-page__text {
        flex-grow: 2;
    }
}

.vsp-c-error-page__title-bottom {
    opacity: 0.4;
}

.vsp-c-error-page__text-wrapper {
    width: 50%;
    position: absolute;
    top: 20%;
    left: 43%;
}

@-moz-document url-prefix() {
    .vsp-c-error-page__text-wrapper {
        top: 25%;
        left: 49%;
    }
}

.vsp-c-error-page-bike {
    background-color: #3e9a82;
    background-image: url("/img/wood.png");
    background-position: bottom center;
    background-repeat: no-repeat;
    color: #52c9aa;

    .vsp-c-error-page__image {
        width: 28%;
        margin-left: 12vw;

        @media (max-width: $screen-md) {
            width: 33%;
        }

        @media (max-width: $screen-xs + 50) {
            width: 38%;
            position: static;
        }

        @media (max-width: $screen-xs - 80) {
            width: 48%;
            position: static;
        }
    }

    .vsp-c-error-page__title-main {
        color: #4eae93;
    }

    .vsp-c-error-page__content {
        color: #52c9aa;
        border-color: #52c9aa;
    }
}

.vsp-c-error-page-car {
    background-color: $vsp-color-white;
    color: $vsp-color-black;

    .vsp-c-error-page__image {
        width: 35%;
        margin-left: 12vw;

        @media (max-width: $screen-md) {
            width: 40%;
        }

        @media (max-width: $screen-xs + 50) {
            width: 50%;
            position: static;
        }

        @media (max-width: $screen-xs - 70) {
            width: 60%;
            position: static;
        }
    }

    .vsp-c-error-page__title-main {
        color: #4eae93;
    }

    .vsp-c-error-page__content {
        color: #333333;
        border-color: #dfeae6;
    }
}
